import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ImageCropModal from 'components/ImageCropModal';
import {
  DevEnv,
  getFormattedDate,
  OWNER,
  STAFF,
  TEAM_ID,
  TEAM_NAME,
} from 'utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import AttachmentModal from 'pages/inbox/AttachmentModal';
import DeleteModal from 'pages/inbox/DeleteModal';
import AddTaskModal from 'pages/inbox/AddTaskModal';
import Tasks from 'pages/inbox/Tasks';
import { toast } from 'react-toastify';
import TaskFlyout from 'pages/inbox/TaskFlyout';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import InvitesTable from 'pages/manageWorkspaces/invites/InvitesTable';
import Header from '../../partials/Header';
import Tabs from './Tabs';
import FirmIcon from './FirmIcon';
import Overview from './overview';
import Clients from './clients';
import {
  acceptTeamInviteApi,
  declineTeamInviteApi,
  deleteAccountingFirmTasks,
  deleteTaskAttachment,
  getAccountantFirms,
  getAccountingFirmTasks,
  getTaskAttachments,
  getUser,
  updateTask,
  uploadTaskAttachment,
} from '../../API/backend_helper';
import EditAccountantFirmModal from './EditAccountantFirmModal';

import { setAccessType, setLocalUser } from '../../redux/User/reducer';

const OVERVIEW_TAB = 'overviewTab';
const INBOX_TAB = 'inboxTab';
const CLIENTS_TAB = 'clientsTab';
const TASKS_TAB = 'tasksTab';
const INVITES_TAB = 'invitesTab';

const statusOptions = [
  {
    name: 'Not Started',
    textColor: '#42B4F7',
    background: '#42B4F71A',
    id: 'notStarted',
  },
  {
    name: 'In Progress',
    textColor: '#EDB900',
    background: '#EDB9001A',
    id: 'inProgress',
  },
  {
    name: 'Review',
    textColor: '#E48642',
    background: '#E486421A',
    id: 'review',
  },
  {
    name: 'Completed',
    textColor: '#78BD4F',
    background: '#40AA001A',
    id: 'completed',
  },
];

let searchTimeout = null;

const AccountantFirm = () => {
  const { tab } = useParams();
  const dispatch = useDispatch();
  const { team } = {};

  const { accessType, localUser } = useSelector((state) => state.User);

  const [searchParams, setSearchParams] = useSearchParams();
  const Id = searchParams.get('id') || '';
  const TEAMId = localStorage.getItem(TEAM_ID);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(OVERVIEW_TAB);
  const [loading, setLoading] = useState(false);
  const [accountantFirm, setAccountantFirm] = useState({});
  const [updateAccountantFirmModalOpen, setUpdateAccountantFirmModalOpen] =
    useState(false);
  const [isAccountingFirmFound, setIsAccountingFirmFound] = useState(false);

  // Firm Logo
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [currentImageForCrop, setCurrentImageForCrop] = useState(null);
  // File Management
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');
  const [imgName, setImgName] = useState('');

  // Tasks
  const [statusFilter, setStatusFilter] = useState([
    'inProgress',
    'review',
    'notStarted',
  ]);
  const [assignedFilter, setAssignedFilter] = useState('');
  const [priorityFilter, setPriorityFilter] = useState('');
  const [statusLoading, setStatusLoading] = useState({});
  const [assigneeLoading, setAssigneeLoading] = useState({});
  const [priorityLoading, setPriorityLoading] = useState({});
  const [projects, setProjects] = useState([]);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(null);
  const [tasks, setTasks] = useState({});
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [localTasksLimit, setLocalTasksLimit] = useState(50);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('');
  const [taskSearchFilter, setTaskSearchFilter] = useState('');
  const [activeAttachment, setActiveAttachment] = useState(null);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedTaskAttachments, setSelectedTaskAttachments] = useState([]);
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);

  const loadAccountantFirm = async ({ loading = false } = {}) => {
    if (loading) {
      setLoading(true);
    }
    try {
      const res = await getAccountantFirms();
      if (res?.data?.result?.accountingFirms?.length > 0) {
        setIsAccountingFirmFound(true);
        setAccountantFirm(res?.data?.result?.accountingFirms?.[0]);
      } else {
        setIsAccountingFirmFound(false);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tab === 'clients') {
      setActiveTab(CLIENTS_TAB);
    } else if (tab === 'invites') {
      setActiveTab(INVITES_TAB);
    } else if (tab === 'tasks') {
      setActiveTab(TASKS_TAB);
    }
    setFlyoutOpen(false);
  }, [tab]);

  const getUserApi = async (selectTeam = false, loading = true) => {
    if (loading) {
      setLoading(true);
    }
    try {
      const response = await getUser();
      dispatch(setLocalUser(response));

      if (selectTeam && response?.teams?.length > 0) {
        if (response?.realm === STAFF) {
          dispatch(setAccessType(OWNER));
        } else {
          dispatch(setAccessType(response?.teams?.[0]?.credentials?.role));
        }
        localStorage.setItem(TEAM_ID, response?.teams?.[0]?.id);
        localStorage.setItem(TEAM_NAME, response?.teams?.[0]?.name);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAccountantFirm({ loading: true });
    getUserApi();
  }, []);

  const onAcceptInvite = async (id, name) => {
    try {
      const res = await acceptTeamInviteApi(id);
      if (!TEAMId) {
        localStorage.setItem(TEAM_ID, id);
        localStorage.setItem(TEAM_NAME, name);
      }
      getUserApi(false, false);
    } catch (err) {
      console.log('err', err);
    }
  };
  const onDeclineInvite = async (teamId) => {
    try {
      const res = await declineTeamInviteApi(teamId);
      getUserApi(false, false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const onChangeInvite = (value, team) => {
    if (value === 'accept') {
      onAcceptInvite(team?.teamId, team?.team?.name);
    }
    if (value === 'decline') {
      onDeclineInvite(team?.teamId, team?.team?.name);
    }
  };

  // <<<<<<<<<<<<>>>>>>>>>>>>>>>
  // Tasks Functions
  // <<<<<<<<<<<<>>>>>>>>>>>>>>>

  useEffect(() => {
    if (localUser?.teams?.length > 0) {
      setClients(localUser?.teams?.map((t) => ({ id: t?.id, name: t?.name })));
    }
  }, [localUser]);

  const toggleDropdown = (dropdownId) => {
    setStatusDropdownOpen(
      statusDropdownOpen === dropdownId ? null : dropdownId,
    );
  };

  const findTaskType = (type) => {
    switch (type) {
      case 'toDo':
        return 'To-Do';
      case 'reviewTransaction':
        return 'Review Transaction';
      case 'transactionVendor':
        return 'Set Transaction Vendor';
      case 'transactionCategory':
        return 'Set Transaction Category';
      case 'payBill':
        return 'Pay Bill';
      case 'transactionAttachment':
        return 'Upload Transaction Attachment';
      default:
        return 'No Type Assigned';
    }
  };

  const handleUpdateTask = async (id, values) => {
    try {
      let formattedDate;
      if (values.deadlineDate) {
        formattedDate = getFormattedDate(values.deadlineDate, 'mm/dd/yyyy');
      } else {
        formattedDate = null;
      }
      const tempTasks = JSON.parse(JSON.stringify(tasks?.tasks));
      const taskIndex = tempTasks?.findIndex((t) => t?.id === id);
      const tempTask = {
        ...tempTasks[taskIndex],
        ...values,
        deadlineDate: formattedDate,
        id,
      };
      tempTasks[taskIndex] = JSON.parse(JSON.stringify(tempTask));
      setTasks((prev) => ({
        ...prev,
        tasks: tempTasks,
      }));
      if (selectedTask?.id) {
        setSelectedTask(JSON.parse(JSON.stringify(tempTask)));
      }

      localStorage.setItem(TEAM_ID, values?.teamId);
      const data = new FormData();
      data.append('taskName', tempTask?.name);
      data.append('deadlineDate', formattedDate);
      data.append('type', tempTask?.type);
      data.append('description', tempTask?.description);
      data.append('status', tempTask?.status);
      data.append('priority', tempTask?.priority);
      data.append('assigneeId', tempTask?.assigneeId);
      data.append('projectId', tempTask?.projectId);
      data.append('accountingFirmId', accountantFirm?.id);

      const res = await updateTask(id, {
        ...values,
        deadlineDate: formattedDate,
      });
      if (selectedTask?.id) {
        setSelectedTask(res);
      }
      // eslint-disable-next-line no-use-before-define
      await loadTasks({});
      return res;
    } catch (error) {
      toast.error(
        'An error occurred while saving. Please refresh and try again',
      );
      console.error(error);
    } finally {
      localStorage.setItem(TEAM_ID, '');
    }
  };

  const loadTasks = async ({
    firmId = accountantFirm?.id,
    page = tasks?.page || 1,
    limit = localTasksLimit,
    status = statusFilter || '',
    priority = priorityFilter || '',
    loading = false,
    orderByVal = orderBy,
    orderDirectionVal = orderDirection,
    search = taskSearchFilter,
    clients = assignedFilter,
  }) => {
    try {
      if (loading) {
        setTableLoading(true);
      }
      const res = await getAccountingFirmTasks({
        firmId,
        page,
        limit,
        status,
        priority,
        orderBy: orderByVal,
        orderDirection: orderDirectionVal,
        search,
        clients,
      });
      setTasks(res);
    } catch (error) {
      console.error(error);
    } finally {
      if (loading) {
        setTableLoading(false);
      }
    }
  };

  const handleSortBy = (name) => {
    let orderByVal = '';
    let orderDirectionVal = '';
    if (orderBy !== name) {
      orderByVal = name;
      orderDirectionVal = 'asc';
    } else if (orderBy === name && orderDirection === 'asc') {
      orderByVal = name;
      orderDirectionVal = 'desc';
    } else {
      orderByVal = '';
      orderDirectionVal = '';
    }
    setOrderBy(orderByVal);
    setOrderDirection(orderDirectionVal);
    loadTasks({
      orderByVal,
      orderDirectionVal,
    });
  };

  const handleTasksSearch = (val) => {
    setTaskSearchFilter(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      loadTasks({
        search: encodeURIComponent(val || ''),
        page: 1,
        loading: true,
      });
    }, 900);
  };

  const handleDeleteTask = async () => {
    try {
      localStorage.setItem(TEAM_ID, selectedTask?.teamId);
      await deleteAccountingFirmTasks(selectedTask?.id);
      // eslint-disable-next-line no-use-before-define
      if (selectedTask?.id) {
        setSelectedTask(null);
      }
      if (Id) {
        setSearchParams({});
      }
      setFlyoutOpen(false);
      await loadTasks({});
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.setItem(TEAM_ID, '');
    }
  };

  const loadTaskAttachments = async (loading = false) => {
    if (loading) {
      setAttachmentsLoading(true);
    }
    try {
      localStorage.setItem(TEAM_ID, selectedTask?.teamId);
      const data = await getTaskAttachments(selectedTask?.id);
      setSelectedTaskAttachments(data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setAttachmentsLoading(false);
      localStorage.setItem(TEAM_ID, '');
    }
  };

  const handleDeleteAttachment = async () => {
    if (activeAttachment?.id) {
      try {
        localStorage.setItem(TEAM_ID, selectedTask?.teamId);
        await deleteTaskAttachment(
          activeAttachment?.taskId,
          activeAttachment?.id,
        );
      } catch (error) {
        console.error(error);
      } finally {
        localStorage.setItem(TEAM_ID, '');
      }
    }
    await loadTaskAttachments();
    setActiveAttachment(null);
  };

  const uploadTaskFile = async (file) => {
    try {
      localStorage.setItem(TEAM_ID, selectedTask?.teamId);
      const data = new FormData();
      data.append('attachment', file);
      await uploadTaskAttachment(selectedTask?.id, data);
      loadTaskAttachments();
    } catch (err) {
      toast.error(
        'An error occurred while saving. Please refresh and try again',
      );
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (accountantFirm?.id > 0) {
      loadTasks({
        loading: true,
        firmId: accountantFirm?.id,
      });
    }
  }, [accountantFirm?.id]);

  useEffect(() => {
    if (selectedTask?.id && flyoutOpen) {
      loadTaskAttachments(true);
    }
  }, [selectedTask?.id]);

  useEffect(() => {
    if (flyoutOpen) {
      shutdown();
    } else {
      boot();
    }
  }, [flyoutOpen]);

  useEffect(() => {
    if (activeTab !== TASKS_TAB) {
      setSearchParams({});
    }
  }, [activeTab]);

  return (
    <div className="relative flex h-[100dvh] overflow-hidden">
      {process.env.REACT_APP_ENV === DevEnv && activeTab === TASKS_TAB && (
        <TaskFlyout
          flyoutOpen={flyoutOpen}
          setFlyoutOpen={setFlyoutOpen}
          task={selectedTask}
          setTask={setSelectedTask}
          team={team}
          handleUpdateTask={handleUpdateTask}
          loadData={loadTasks}
          findTaskType={findTaskType}
          statusOptions={statusOptions}
          statusDropdownOpen={statusDropdownOpen}
          setStatusDropdownOpen={setStatusDropdownOpen}
          toggleDropdown={toggleDropdown}
          categories={[]}
          localUser={localUser}
          vendors={[]}
          setVendors={() => {}}
          allExpenseCategories={[]}
          setTransactionsModalOpen={() => {}}
          transactionsModalOpen={false}
          bill={{}}
          setBill={() => {}}
          accessType={accessType}
          statusLoading={statusLoading}
          assigneeLoading={assigneeLoading}
          priorityLoading={priorityLoading}
          projects={projects}
          setEditTaskModalOpen={() => {}}
          setDeleteModalOpen={setDeleteModalOpen}
          setActiveAttachment={setActiveAttachment}
          deleteModalOpen={deleteModalOpen}
          selectedTaskAttachments={selectedTaskAttachments}
          setSelectedTaskAttachments={setSelectedTaskAttachments}
          attachmentsLoading={attachmentsLoading}
          uploadTaskAttachment={uploadTaskFile}
          setReceiptModalOpen={setAttachmentModalOpen}
          receiptModalOpen={attachmentModalOpen}
          hideTaskType
          showAccountantAssignee
          showClient
          showAccountantProjects
          clients={clients}
        />
      )}
      <EditAccountantFirmModal
        isOpen={updateAccountantFirmModalOpen}
        setIsOpen={setUpdateAccountantFirmModalOpen}
        accountantFirm={accountantFirm}
        loadAccountantFirm={loadAccountantFirm}
        // firm logo
        setFiles={setFiles}
        setCurrentImageForCrop={setCurrentImageForCrop}
        setCropModalOpen={setCropModalOpen}
        setImgName={setImgName}
        imgPreview={imgPreview}
        setImgPreview={setImgPreview}
        files={files}
        cropModalOpen={cropModalOpen}
      />
      <ImageCropModal
        isOpen={cropModalOpen}
        setIsOpen={setCropModalOpen}
        image={currentImageForCrop}
        setPreviewImage={setImgPreview}
        setFiles={setFiles}
        imgName={imgName}
      />
      {/* Content area */}
      <div
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
        id="accountantMainPage"
      >
        {/*  Site header */}
        <Header
          showHamburger={false}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <main className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <h1 className="mb-10 text-2xl md:text-[36px] text-[#666666] dark:text-slate-100 font-medium ">
            Accountant Practice
          </h1>

          {loading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              {/* Accountant Header  */}
              {isAccountingFirmFound && (
                <div className="flex justify-between items-center mb-12 gap-4 flex-wrap">
                  <div className="flex items-center gap-10 pl-6 ">
                    <div className="min-h-20 min-w-20 h-20 w-20 rounded-full inline-flex justify-center items-center bg-[#EDEDED]">
                      {accountantFirm?.logoUrl ? (
                        <img
                          src={accountantFirm?.logoUrl}
                          alt="practice_logo"
                          className="h-full w-full rounded-full"
                        />
                      ) : (
                        <FirmIcon />
                      )}
                    </div>
                    <div>
                      <h3 className="text-[24px] font-medium text-[#101828] leading-tight mb-2">
                        {accountantFirm?.name}
                      </h3>
                      {/* <p className="text-sm text-[#667085] leading-tight m-0">
                      Accounts Consultant company
                    </p> */}
                    </div>
                  </div>
                  <button
                    className="h-10 ml-auto px-8 border border-indigo-500 text-sm text-indigo-500 rounded-[5px] shadow-button"
                    type="button"
                    onClick={() => setUpdateAccountantFirmModalOpen(true)}
                  >
                    Edit
                  </button>
                </div>
              )}
              {/* Tabs  */}
              <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                OVERVIEW_TAB={OVERVIEW_TAB}
                INBOX_TAB={INBOX_TAB}
                CLIENTS_TAB={CLIENTS_TAB}
                TASKS_TAB={TASKS_TAB}
                INVITES_TAB={INVITES_TAB}
                isAccountingFirmFound={isAccountingFirmFound}
              />
              {activeTab === OVERVIEW_TAB && (
                <Overview
                  setActiveTab={setActiveTab}
                  CLIENTS_TAB={CLIENTS_TAB}
                  isAccountingFirmFound={isAccountingFirmFound}
                />
              )}
              {activeTab === CLIENTS_TAB && <Clients />}
              {activeTab === INVITES_TAB && (
                <InvitesTable onChangeInvite={onChangeInvite} />
              )}

              {/* Accountant Tasks Tab  */}
              {process.env.REACT_APP_ENV === DevEnv &&
                isAccountingFirmFound &&
                activeTab === TASKS_TAB && (
                  <>
                    <DeleteModal
                      isOpen={deleteModalOpen}
                      setIsOpen={setDeleteModalOpen}
                      handleDone={
                        deleteModalOpen === 'task'
                          ? handleDeleteTask
                          : handleDeleteAttachment
                      }
                      type={deleteModalOpen}
                    />
                    <AddTaskModal
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                      loadData={loadTasks}
                      projects={projects}
                      task={selectedTask}
                      statusOptions={statusOptions}
                      hideProjects
                      showClients
                      clients={clients}
                      accountantTask
                      accountingFirmId={accountantFirm?.id}
                      showAccountantProjects
                    />
                    <AttachmentModal
                      isOpen={attachmentModalOpen}
                      setIsOpen={setAttachmentModalOpen}
                      receipt={
                        activeAttachment !== null
                          ? activeAttachment?.fileUrl ||
                            activeAttachment?.preview
                          : activeReceipt
                      }
                      isExpenseReporting={activeAttachment !== null}
                      type={
                        activeAttachment !== null
                          ? activeAttachment?.fileType || activeAttachment?.type
                          : ''
                      }
                      title={
                        activeAttachment !== null
                          ? activeAttachment?.fileName || activeAttachment?.name
                          : ''
                      }
                      setActiveAttachment={setActiveAttachment}
                    />
                    <div className="flex flex-col items-start gap-[1.875rem]">
                      <Tasks
                        statusOptions={statusOptions}
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        assignedFilter={assignedFilter}
                        setAssignedFilter={setAssignedFilter}
                        priorityFilter={priorityFilter}
                        setPriorityFilter={setPriorityFilter}
                        statusLoading={statusLoading}
                        assigneeLoading={assigneeLoading}
                        priorityLoading={priorityLoading}
                        statusDropdownOpen={statusDropdownOpen}
                        toggleDropdown={toggleDropdown}
                        tasks={tasks}
                        team={team}
                        handleUpdateTask={handleUpdateTask}
                        setFlyoutOpen={setFlyoutOpen}
                        setSelectedTask={setSelectedTask}
                        findTaskType={findTaskType}
                        setModalOpen={setModalOpen}
                        localUser={localUser}
                        loadTasks={loadTasks}
                        tableLoading={tableLoading}
                        accessType={accessType}
                        localTasksLimit={localTasksLimit}
                        setLocalTasksLimit={setLocalTasksLimit}
                        setDeleteModalOpen={setDeleteModalOpen}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                        handleSortBy={handleSortBy}
                        taskSearchFilter={taskSearchFilter}
                        handleTasksSearch={handleTasksSearch}
                        hideTaskType
                        showAccountantAssignee
                        showClient
                        clients={clients}
                        ids={['accountantMainPage', 'tasksTableContainer']}
                      />
                    </div>
                  </>
                )}
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default AccountantFirm;
