/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Tooltip from 'components/Tooltip';
import { getStringSegments } from 'utils/Utils';
import DropdownFull from '../../components/DropdownFull';

const Step2 = ({ mapping, setMapping, keyArray, parsedInfo, error }) => {
  const { total, customers } = parsedInfo;

  const dropdownHeaders = [
    { name: 'Customer', id: 'customer' },
    { name: 'First Name', id: 'firstName' },
    { name: 'Middle Name', id: 'middleName' },
    { name: 'Last Name', id: 'lastName' },
    { name: 'Tax Number', id: 'taxNumber' },
    { name: 'Email', id: 'email' },
    { name: 'Phone', id: 'phone' },
    { name: 'Payment Term', id: 'paymentTerm' },
    { name: 'Website', id: 'website' },
    { name: 'Shipping Street', id: 'shippingStreet' },
    { name: 'Shipping Country', id: 'shippingCountry' },
    { name: 'Shipping City', id: 'shippingCity' },
    { name: 'Shipping State', id: 'shippingState' },
    { name: 'Shipping Zip', id: 'shippingZip' },
    { name: 'Billing Street', id: 'billingStreet' },
    { name: 'Billing Country', id: 'billingCountry' },
    { name: 'Billing City', id: 'billingCity' },
    { name: 'Billing State', id: 'billingState' },
    { name: 'Billing Zip', id: 'billingZip' },
    { name: 'Notes', id: 'notes' },
    { name: 'Status', id: 'status' },
    { name: 'Social Security Number', id: 'socialSecurityNumber' },
    { name: 'Main Phone Number', id: 'mainPhone' },
  ];

  const checkIfMatched = (mapping, key) => {
    const _key = Object.keys(mapping).find((mapKey) => mapping[mapKey] === key);
    const exists = dropdownHeaders?.filter((d) => d?.id === _key);
    if (exists?.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <div className="">
      <h3 className="text-2xl font-semibold">Match Columns to COUNT Fields</h3>
      {/* <p className="text-base text-slate-600 mb-3">
        Please use the dropdown menus in the header row to map each column to
        the appropriate field. Ensure that Customer is selected. You can choose
        the "--" option to skip any unnecessary columns. Below you will see the
        first 6 customers displayed, with a summary indicating the total number
        of customers being imported.
      </p> */}
      {error && <p className="text-rose-500">{error}</p>}
      <div className="bg-transparent w-full rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] " />
      <div
        className="w-full items-start flex overflow-x-auto scrollbar bg-white"
        id="importCustomersStep2"
      >
        <table className="table-fixed dark:text-slate-300">
          {/* Table Header */}
          <thead className="w-full shadow-md rounded-[0.313rem]  h-16 text-sm  text-slate-600">
            <tr className="w-full px-[1.375rem]">
              <th className="px-4 text-slate-600 text-left text-[14px] font-medium  min-w-[300px] w-[300px]">
                File Column
              </th>
              <th className="px-4 text-slate-600 text-left text-[14px] font-medium  min-w-[250px] w-[250px]">
                COUNT's Column
              </th>
              <th className=" px-4 text-slate-600 text-left text-[14px] font-medium  min-w-[180px] w-[180px]">
                Match Status
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody className="divide-y divide-[#D0D5DD] border-b border-[#D0D5DD]">
            {/* Mapping Headers and Values */}
            {keyArray.map((key, index) => (
              <tr
                key={index}
                className="   h-[4rem] hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer"
              >
                {/* First Column - Header Name */}
                <td className="px-4 text-left text-[14px] font-medium leading-[1.125rem]">
                  <div className="flex justify-between items-center gap-4">
                    <span>{key.name}</span>
                    <Tooltip
                      content={(() => {
                        const str = `
                          Data preview for "${key.name || '-- '}" (first ${total} row)`;
                        const segments = getStringSegments(str, 70);
                        return (
                          <>
                            {segments.map((segment, index) => (
                              <p key={index}>{segment}</p>
                            ))}
                            {customers?.length > 0 && (
                              <ul className="list-disc pl-4">
                                {customers?.map(
                                  (p, i) =>
                                    p?.[key?.name] && (
                                      <li key={i}> {p?.[key?.name]}</li>
                                    ),
                                )}
                              </ul>
                            )}
                          </>
                        );
                      })()}
                      contentClassName="border-none rounded-[10px] overflow-visible text-xs text-[#667085] relative tooltipArrowUp"
                      size="lg"
                      position="bottom"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 14 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mb-px"
                      >
                        <path
                          d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                          stroke="#667085"
                          strokeWidth="0.784912"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                          stroke="#667085"
                          strokeWidth="0.784912"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                          fill="#667085"
                        />
                      </svg>
                    </Tooltip>
                  </div>
                </td>

                {/* Second Column - Dropdown for Mapping or Value */}
                <td className="px-4 text-left text-[14px] font-medium leading-[1.125rem]">
                  <DropdownFull
                    height="h-10"
                    options={[{ name: '--', id: '--' }, ...dropdownHeaders]}
                    name="name"
                    noMinW
                    selected={
                      Object.keys(mapping).find(
                        (mapKey) => mapping[mapKey] === keyArray[index]?.name,
                      ) || '--'
                    }
                    setSelected={(id) => {
                      const newMapping = { ...mapping };

                      const previousKey = Object.keys(newMapping).find(
                        (mapKey) =>
                          newMapping[mapKey] === keyArray[index]?.name,
                      );
                      if (previousKey) {
                        delete newMapping[previousKey];
                      }

                      newMapping[id] = keyArray[index]?.name;
                      setMapping(newMapping);
                    }}
                    buttonTextClass="truncate"
                    scrollIntoView={false}
                    isTableDropdown
                    bottomOfTable
                    ids={['importCustomersContainer', 'importCustomersStep2']}
                  />
                </td>
                <td className="px-4">
                  {checkIfMatched(mapping, keyArray[index]?.name) ? (
                    <div className="flex gap-2">
                      <span className="inline-block h-2 w-2 rounded-full bg-green-600 mt-[7px]" />
                      <div>
                        <p>Matched</p>
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      <span className="inline-block h-2 w-2 rounded-full bg-slate-400 mt-2" />
                      <div>
                        <p> No match found</p>
                        <p className="text-xs text-slate-400">
                          Not be imported
                        </p>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Step2;
