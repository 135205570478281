import { createGroupTag, createTag } from 'API/backend_helper';
import ModalBasic from 'components/ModalBasic';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import { TrashSquare } from 'pages/inbox/Svg';
import React, { useEffect, useRef, useState } from 'react';

const GroupTagsModal = ({
  isOpen,
  setIsOpen,
  selectedTags,
  setSelectedTags,
  loadTags,
}) => {
  const tagNameRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState('');

  useEffect(() => {
    if (selectedTags?.length > 0 && !tagNameRef?.current) {
      setGroupName(selectedTags[0]?.name);
    }
  }, [selectedTags]);

  const handleSave = async () => {
    if (!groupName) {
      setGroupNameError('Group name is required');
      return;
    }
    try {
      setIsLoading(true);
      const res = await createGroupTag({
        newName: groupName,
        tags: selectedTags?.map((tag) => tag?.id),
      });
      loadTags(false);
      setSelectedTags([]);
      setGroupName('');
      setIsLoading(false);
      setIsOpen(false);
    } catch (err) {
      setIsLoading(false);
      console.log('err', err);
    }
  };

  return (
    <ModalBasic
      title="Merge Tags"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
    >
      <div className="p-6">
        <div className="flex flex-col mb-6 items-start w-full">
          <label
            className="block text-sm mb-2.5 text-slate-600 font-medium leading-5"
            htmlFor="name"
          >
            New Tag Name<span className="text-rose-500">*</span>
          </label>
          <input
            id="name"
            type="text"
            placeholder="Tag Name"
            name="name"
            className={`form-input text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      groupNameError && '!border-rose-500'
                                    }`}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                if (groupNameError) {
                  setGroupNameError('');
                }
                if (!tagNameRef?.current) {
                  tagNameRef.current = true;
                }
              }
              setGroupName(e.target.value);
            }}
            value={groupName}
          />
          {groupNameError ? (
            <div className="text-xs mt-1 text-rose-500">{groupNameError}</div>
          ) : null}
        </div>
        <div className="flex gap-3">
          {selectedTags?.map?.((tag) => (
            <div
              key={tag.id}
              className="flex items-center  gap-5 mb-3  bg-slate-200 flex-wrap py-1  px-2 rounded border-[#D0D5DD]  dark:border-slate-700"
            >
              <div className="flex gap-3 items-center flex-wrap">
                <h3 className="text-sm font-medium text-slate-500  rounded p-1 px-2">
                  {tag?.name}
                </h3>
              </div>
              {selectedTags?.length > 2 && (
                <div className="flex gap-3">
                  <div
                    className="cursor-pointer bg-slate-500 rounded "
                    onClick={() => {
                      setSelectedTags(
                        selectedTags.filter((t) => t?.id !== tag.id),
                      );
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <Tooltip
                      content="Delete"
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                    >
                      <svg
                        width="28"
                        height="29"
                        viewBox="0 0 28 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-white fill-white"
                      >
                        <rect
                          x="6.17676"
                          y="8.6582"
                          width="2.3292"
                          height="19.7982"
                          rx="1.1646"
                          transform="rotate(-45 6.17676 8.6582)"
                          className="fill-current"
                        />
                        <rect
                          x="20.1768"
                          y="7.01172"
                          width="2.3292"
                          height="19.7982"
                          rx="1.1646"
                          transform="rotate(45 20.1768 7.01172)"
                          className="fill-current"
                        />
                      </svg>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end gap-4 px-6 py-4 border-t border-[#D0D5DD]">
        <button
          type="button"
          onClick={() => setIsOpen(false)}
          className="btn btn-sm h-fit px-5 py-1.5 bg-white dark:text-slate-300 border border-slate-600 text-slate-600 text-base leading-6"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={handleSave}
          className="btn btn-sm h-fit px-5 py-1.5 !bg-indigo-500  border border-indigo-500 text-white text-base leading-6"
        >
          {isLoading ? <Loader /> : 'Submit'}
        </button>
      </div>
    </ModalBasic>
  );
};

export default GroupTagsModal;
