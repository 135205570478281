import React, { useState } from 'react';
import moment from 'moment';
import CategoriesFilter from 'pages/transactions/Filters/CategoriesFilter';
import Tooltip from '../../components/Tooltip';
import PaginationClassic from '../../components/PaginationClassic';
import {
  getExpenseReportStatusColor,
  getStringSegments,
  trimText,
} from '../../utils/Utils';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';

const REPORT_TYPES = [
  {
    label: 'Awaiting Approval',
    value: 'Submitted',
  },
  {
    label: 'Approved',
    value: 'Approved',
  },
  {
    label: 'Denied',
    value: 'Denied',
  },
];

const ReporterTransactionsTable = ({
  isLoading,
  reporters,
  activeTransaction,
  selectAllTransactions,
  handleSelectAllTransactions,
  selectedTransactions,
  handleTransactionCheckbox,
  setTransactionFlyoutOpen,
  setActiveTransaction,
  team,
  updateReportStatus,
  statusLoader,
  handleBulkStatusUpdate,
  bulkStatusLoading,
  reportsTypesFilter,
  setReportsTypesFilter,
  getData,
  findCurrencySymbol,
  setLocalExpenseReportLimit,
  allAccounts,
  isResetAccount,
  selectedAccount,
  setSelectedAccount,
  tags,
  selectedTags,
  setSelectedTags,
  projects,
  selectedProjects,
  setSelectedProjects,
  expenseTypes,
  selectedExpenseTypes,
  setSelectedExpenseTypes,
  allReporters,
  selectedReporters,
  setSelectedReporters,
  handleResetTransactionsFilters,
  showAllFilters,
  setShowAllFilters,
}) => {
  const { expenseReport, page, limit, totalCount } = reporters;
  const [tagsLength, setTagsLength] = useState(20);

  return (
    <div className="flex-1 flex flex-col">
      {/* Filters  */}
      <div className=" mb-8">
        <div className="w-full mb-2 flex sm:flex-row flex-col justify-between gap-4 sm:gap-7">
          <div className="flex-1 flex gap-4 flex-wrap">
            <BasicCheckboxDropdown
              selectedOptions={reportsTypesFilter}
              setSelectedOptions={setReportsTypesFilter}
              options={REPORT_TYPES}
              // apiFunc={getData}
              onApply={(v) => {
                if (reportsTypesFilter?.includes(v)) {
                  getData({
                    type: reportsTypesFilter?.filter((val) => val !== v),
                    page: 1,
                    loading: true,
                  });
                } else {
                  getData({
                    loading: true,
                    type: [...reportsTypesFilter, v],
                    page: 1,
                  });
                }
              }}
              onClear={() => {
                setReportsTypesFilter([]);
                getData({});
              }}
              label="Choose Type"
              type="type"
              apiFilter={reportsTypesFilter}
            />
            <div className="flex-1">
              <CategoriesFilter
                allCategories={allAccounts}
                selectedCategoryId={selectedAccount}
                setSelectedCategoryId={setSelectedAccount}
                getTransactionsApi={getData}
                apiFilter={reporters?.filters?.accounts}
                isReset={isResetAccount}
                type="account"
              />
            </div>
            <BasicCheckboxDropdown
              selectedOptions={selectedReporters}
              setSelectedOptions={setSelectedReporters}
              type="filterReporters"
              options={
                allReporters?.map((t) => ({
                  label: `${t?.firstName} ${t?.lastName}`,
                  value: t?.id,
                })) || []
              }
              apiFunc={getData}
              onApply={(values) => {
                getData({
                  loading: true,
                  filterReporters: values,
                  page: 1,
                });
              }}
              onClear={() => {
                setSelectedReporters([]);
                getData({
                  loading: true,
                  filterReporters: [],
                  page: 1,
                });
              }}
              label="Reporter"
              apiFilter={reporters?.filters?.reporters}
              search
              transactionsPage
            />
          </div>
          <div className="hidden sm:block h-10 w-[1px] bg-[#D0D5DD80]" />
          <div className="flex gap-4 ml-auto min-w-max ">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowAllFilters((prev) => !prev);
              }}
              className={`btn h-10 py-0 px-4 font-normal text-sm leading-6 text-indigo-500 border 
                   ${showAllFilters ? 'bg-[#E486421A]' : ''}
                    border-indigo-500 hover:border-indigo-600 hover:text-indigo-600 text-indigo-500`}
              type="button"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-1"
              >
                <path
                  d="M2.5 6.55801H17.5C17.8969 6.55801 18.225 6.2299 18.225 5.83301C18.225 5.43611 17.8969 5.10801 17.5 5.10801H2.5C2.1031 5.10801 1.775 5.43611 1.775 5.83301C1.775 6.2299 2.1031 6.55801 2.5 6.55801Z"
                  fill="#E48642"
                  stroke="#E48642"
                  strokeWidth="0.2"
                />
                <path
                  d="M5 10.725H15C15.3969 10.725 15.725 10.3969 15.725 10C15.725 9.6031 15.3969 9.275 15 9.275H5C4.6031 9.275 4.275 9.6031 4.275 10C4.275 10.3969 4.6031 10.725 5 10.725Z"
                  fill="#E48642"
                  stroke="#E48642"
                  strokeWidth="0.2"
                />
                <path
                  d="M8.33301 14.892H11.6663C12.0632 14.892 12.3913 14.5639 12.3913 14.167C12.3913 13.7701 12.0632 13.442 11.6663 13.442H8.33301C7.93611 13.442 7.60801 13.7701 7.60801 14.167C7.60801 14.5639 7.93611 14.892 8.33301 14.892Z"
                  fill="#E48642"
                  stroke="#E48642"
                  strokeWidth="0.2"
                />
              </svg>
              All Filter
            </button>
            <button
              onClick={handleResetTransactionsFilters}
              className="btn h-10 py-0 px-4 font-normal text-sm leading-6 border border-red-400 text-red-400 bg-[#FF4B4B05]"
              type="button"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-1 f"
              >
                <path
                  d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                  fill="#FF4B4B"
                />
              </svg>
              Reset
            </button>
          </div>
        </div>

        <div
          className={`${showAllFilters ? 'flex' : 'hidden'}  flex-wrap gap-4 sm:pr-[271.5px]`}
        >
          <BasicCheckboxDropdown
            selectedOptions={selectedExpenseTypes}
            setSelectedOptions={setSelectedExpenseTypes}
            apiFunc={getData}
            type="expenseTypes"
            options={
              expenseTypes?.map((t) => ({
                label: t?.name,
                value: t?.id,
              })) || []
            }
            onApply={(values) => {
              getData({
                page: 1,
                expenseTypes: values,
                loading: true,
              });
            }}
            onClear={() => {
              setSelectedExpenseTypes([]);
              getData({
                page: 1,
                expenseTypes: [],
                loading: true,
              });
            }}
            label="Expense Type"
            apiFilter={reporters?.filters?.expenseTypes}
            search
            transactionsPage
          />
          <BasicCheckboxDropdown
            selectedOptions={selectedProjects}
            setSelectedOptions={setSelectedProjects}
            apiFunc={getData}
            type="projects"
            options={
              projects?.map((t) => ({
                label: t?.name,
                value: t?.id,
              })) || []
            }
            onApply={(values) => {
              getData({
                page: 1,
                projects: values,
                loading: true,
              });
            }}
            onClear={() => {
              setSelectedProjects([]);
              getData({
                page: 1,
                projects: [],
                loading: true,
              });
            }}
            label="Projects"
            apiFilter={reporters?.filters?.projects}
            search
            transactionsPage
          />
          <BasicCheckboxDropdown
            search
            selectedOptions={selectedTags}
            setSelectedOptions={setSelectedTags}
            type="tags"
            apiFunc={getData}
            options={
              tags?.map((t) => ({
                label: t?.name,
                value: t?.id,
              })) || []
            }
            onApply={(values) => {
              getData({
                page: 1,
                tags: values,
                loading: true,
              });
            }}
            onClear={() => {
              setSelectedTags([]);
              getData({
                page: 1,
                tags: [],
                loading: true,
              });
            }}
            label="Tags"
            apiFilter={reporters?.filters?.tags}
            transactionsPage
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <>
          <div className="bg-[#A0CD850F] py-4 gap-2 items-center w-full px-5 flex-wrap hidden sm:flex">
            <div className="flex items-center">
              <label className="inline-flex hover:text-slate-800">
                <input
                  className="form-checkbox h-5 w-5 disabled:cursor-not-allowed disabled:opacity-50"
                  type="checkbox"
                  checked={
                    selectAllTransactions ||
                    (selectedTransactions?.length !== 0 &&
                      selectedTransactions?.length === expenseReport?.length)
                  }
                  onChange={handleSelectAllTransactions}
                  disabled={
                    expenseReport?.filter((t) => t?.status === 'Submitted')
                      ?.length === 0
                  }
                />
                <span className="text-sm ml-5 text-slate-600 leading-[18px] font-medium">
                  Select All{' '}
                  {selectedTransactions.length > 0 &&
                    ` : ${selectedTransactions.length} Selected`}
                </span>
              </label>
            </div>
            <hr className="h-8 w-[1px] bg-slate-300 mx-4" />
            {bulkStatusLoading ? (
              <div className="flex items-center justify-center">
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              </div>
            ) : (
              <>
                <Tooltip
                  content="Approve Selected Transactions"
                  contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleBulkStatusUpdate('Approved');
                    }}
                    disabled={selectedTransactions.length === 0}
                    className=" h-[26px] px-1.5 border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex gap-1 justify-center items-center disabled:cursor-not-allowed"
                    type="button"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`h-4 w-4   ${
                        selectedTransactions.length > 0
                          ? 'stroke-[#78BD4F] text-[#78BD4F]'
                          : 'stroke-[#66708533] text-[#66708533]'
                      }`}
                    >
                      <path
                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                        stroke="#78BD4F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="stroke-current"
                      />
                    </svg>
                    <span
                      className={`${
                        selectedTransactions.length > 0
                          ? 'text-slate-600'
                          : ' text-[#66708550]'
                      }`}
                    >
                      Approve
                    </span>
                  </button>
                </Tooltip>
                <Tooltip
                  content="Deny Selected Transactions"
                  contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleBulkStatusUpdate('Denied');
                    }}
                    className=" h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center disabled:cursor-not-allowed"
                    disabled={selectedTransactions.length === 0}
                    type="button"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`${
                        selectedTransactions.length > 0
                          ? 'stroke-[#FF4B4B] text-[#FF4B4B]'
                          : 'stroke-[#66708533] text-[#66708533]'
                      }`}
                    >
                      <rect
                        x="1"
                        y="1"
                        width="14"
                        height="14"
                        rx="7"
                        strokeWidth="1.3"
                        className="stroke-current"
                      />
                      <path
                        d="M3.05026 3.05025L12.9498 12.9497"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        className="stroke-current"
                      />
                    </svg>
                  </button>
                </Tooltip>
              </>
            )}
          </div>
          <div className="overflow-auto flex-1">
            <table className="table-auto w-full dark:text-slate-300">
              {/* Table header */}
              <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-12 max-w-12 hidden sm:table-cell" />
                  <th className="pl-4">
                    <div className="text-left w-[7rem] font-medium">Date</div>
                  </th>
                  <th className="">
                    <div className="text-left  w-[10rem] font-medium">
                      Reporter
                    </div>
                  </th>
                  <th className="">
                    <div className="text-left w-[7rem] font-medium">
                      {' '}
                      Amount
                    </div>
                  </th>
                  <th className="">
                    <div className="text-left  w-[10rem] font-medium">
                      Vendor
                    </div>
                  </th>
                  <th className="">
                    <div className="text-left  w-[10rem] font-medium">
                      Expense Type
                    </div>
                  </th>
                  <th className="">
                    <div className="text-left font-medium w-[8rem]">
                      Project
                    </div>
                  </th>
                  <th className="">
                    <div className="pl-2 text-left  w-[8rem] font-medium">
                      Tags
                    </div>
                  </th>
                  <th className="">
                    <div className="text-left w-[9rem] font-medium">
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              {expenseReport?.length > 0 && (
                <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                  {expenseReport?.map((reporter) => (
                    <tr
                      key={reporter?.id}
                      className={` pl-[1.375rem] pr-[1.125rem] h-20 hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer ${
                        activeTransaction?.id === reporter?.id
                          ? 'bg-[#a0cd85] bg-opacity-5'
                          : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveTransaction(reporter);
                        setTransactionFlyoutOpen(true);
                      }}
                    >
                      <td className="pl-5 pr-4 last:pr-5 pt-2.5 pb-3 whitespace-nowrap w-12 max-w-12 hidden sm:table-cell">
                        <label className="inline-flex w-fit mt-2">
                          <span className="sr-only">Select</span>
                          <input
                            id={reporter?.id}
                            className="form-checkbox cursor-pointer h-5 w-5 disabled:cursor-not-allowed disabled:opacity-50"
                            type="checkbox"
                            onClick={(e) => e.stopPropagation()}
                            onChange={handleTransactionCheckbox}
                            checked={selectedTransactions.includes(
                              reporter?.id,
                            )}
                            disabled={reporter?.status !== 'Submitted'}
                          />
                        </label>
                      </td>
                      <td className="whitespace-nowrap pl-4">
                        <div className="text-left">
                          {moment
                            ?.utc(
                              reporter?.pendingReceiptId
                                ? reporter?.pendingReceipt?.date
                                : reporter?.transaction?.authorizedDate,
                            )
                            ?.format('MMM DD, YYYY')}
                        </div>
                      </td>
                      <td className="  ">
                        <div className="flex items-center">
                          <div className="text-sm font-medium leading-6 text-left text-slate-600">
                            {reporter?.people?.firstName}{' '}
                            {reporter?.people?.lastName}
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="text-left">
                          <p className="text-slate-600 text-sm leading-6 font-medium">
                            {findCurrencySymbol(
                              reporter?.pendingReceiptId
                                ? reporter?.pendingReceipt?.currency
                                : reporter?.transaction?.currency,
                            )}
                            {parseFloat(
                              reporter?.pendingReceiptId
                                ? reporter?.pendingReceipt?.amount
                                : reporter?.transaction?.amount
                                    ?.toString()
                                    .replace('-', ''),
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            {(reporter?.pendingReceiptId
                              ? reporter?.pendingReceipt?.currency
                              : reporter?.transaction?.currency) !==
                              team?.currency &&
                              ` (${
                                reporter?.pendingReceiptId
                                  ? reporter?.pendingReceipt?.currency
                                  : reporter?.transaction?.currency
                              })`}
                          </p>
                        </div>
                      </td>
                      <td className="  ">
                        <div className="flex items-center">
                          <div className="text-sm font-medium leading-6 text-left text-slate-600">
                            {reporter?.transaction?.vendor?.name || '--'}
                          </div>
                        </div>
                      </td>
                      <td className="  ">
                        <div className="">
                          <div className="text-left font-medium ">
                            {reporter?.transaction?.expenseReportType?.name ||
                              '--'}
                          </div>
                          <div className="text-sm leading-6 text-left text-slate-600">
                            {reporter?.pendingReceipt?.description ||
                              reporter?.transaction?.description}
                          </div>
                        </div>
                      </td>
                      <td className="">
                        <div className="text-left ">
                          {reporter?.transaction?.project?.name || '--'}
                        </div>
                      </td>
                      <td className="pl-2">
                        <div className="text-left">
                          <div className="flex items-center whitespace-nowrap ">
                            {reporter?.transaction?.tags?.length > 0 && (
                              <Tooltip
                                tooltipShow={
                                  reporter?.transaction?.tags?.[0]?.name
                                    ?.length > tagsLength ||
                                  reporter?.transaction?.tags?.length > 1
                                }
                                content={reporter?.transaction?.tags?.map(
                                  (t, i) => {
                                    if (t?.name?.length > 30) {
                                      const segments = getStringSegments(
                                        t?.name,
                                        30,
                                      );
                                      return segments.map((segment, index) => (
                                        <p
                                          key={index}
                                          className="text-sm text-center leading-tight"
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          {segment}{' '}
                                          {index === segments?.length - 1 &&
                                            reporter?.transaction?.tags
                                              ?.length -
                                              1 !==
                                              i &&
                                            ','}
                                        </p>
                                      ));
                                    }
                                    return (
                                      <p
                                        key={t?.id}
                                        className="text-sm text-center"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        {t?.name}
                                        {reporter?.transaction?.tags?.length -
                                          1 !==
                                          i && ','}
                                      </p>
                                    );
                                  },
                                )}
                                contentClassName="border-none rounded-[10px] p-3 overflow-visible text-sm text-[#667085] relative"
                                position="bottom"
                              >
                                <span className="">
                                  {trimText(
                                    reporter?.transaction?.tags?.[0]?.name,
                                    tagsLength,
                                  )}{' '}
                                  {reporter?.transaction?.tags?.length > 1 &&
                                    `+${reporter?.transaction?.tags?.length - 1}`}
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="inline-flex items-center gap-3.5">
                          {statusLoader === reporter?.id ? (
                            <div className="flex items-center justify-center ml-4">
                              <svg
                                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                              </svg>
                            </div>
                          ) : reporter?.status === 'Submitted' ? (
                            <>
                              <Tooltip
                                content="Approve Transaction"
                                contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown cursor-default"
                              >
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateReportStatus(
                                      reporter?.id,
                                      'Approved',
                                    );
                                  }}
                                  className=" h-[26px] px-1.5 border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center gap-1"
                                  type="button"
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                                      stroke="#78BD4F"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  Approve
                                </button>
                              </Tooltip>
                              <Tooltip
                                content="Deny Transaction"
                                contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowRight cursor-default"
                                position="left"
                              >
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateReportStatus(reporter?.id, 'Denied');
                                  }}
                                  className=" h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                                  type="button"
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    className="cursor-pointer"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="1"
                                      y="1"
                                      width="14"
                                      height="14"
                                      rx="7"
                                      stroke="#FF4B4B"
                                      strokeWidth="1.3"
                                    />
                                    <path
                                      d="M3.05026 3.05025L12.9498 12.9497"
                                      stroke="#FF4B4B"
                                      strokeWidth="1.3"
                                      strokeLinecap="round"
                                    />
                                  </svg>
                                </button>
                              </Tooltip>
                            </>
                          ) : (
                            <div
                              className={`capitalize cursor-default  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                          ${getExpenseReportStatusColor(reporter?.status)}
                        `}
                            >
                              {reporter?.status}
                            </div>
                          )}
                        </div>
                        {/* <ElipsisMenu
             align="right"
             className="relative inline-flex"
             dropdownOpen={dropdownOpen}
             setDropdownOpen={setDropdownOpen}
           >
             <li
               onClick={() => {
                 setActiveReporter(merchant);
                 setModalOpen(true);
                 setDropdownOpen(false);
               }}
               className="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3 cursor-pointer"
             >
               Update
             </li>
           </ElipsisMenu> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {!(expenseReport?.length > 0) && (
              <div className="flex flex-col gap-5 items-center justify-center w-full">
                <h6 className="text-center mt-6">
                  No Transactions found{' '}
                  {reportsTypesFilter?.length === 1 &&
                    reportsTypesFilter[0] === 'Submitted' && (
                      <>that need review</>
                    )}
                </h6>
              </div>
            )}
          </div>
          <div className="mt-10">
            <PaginationClassic
              setLimit={setLocalExpenseReportLimit}
              onPageChange={getData}
              pagination={{ limit, page, totalRecords: totalCount }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ReporterTransactionsTable;
