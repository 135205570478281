import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Loader } from 'components/Svg';
import ModalBasic from '../../../components/ModalBasic';
import MembersDropdown from './MembersDropdown';
import { addTeamMember, getPermissions } from '../../../API/backend_helper';
import { ADMIN, ACCOUNTANT, VIEWER, DevEnv } from '../../../utils/Utils';

const MembersModal = ({
  isOpen,
  setIsOpen,
  getMembers,
  localInvite,
  setLocalInvite,
}) => {
  const [loading, setLoading] = useState(false);
  const formikRef = useRef(null);

  const [permissions, setPermissions] = useState([]);
  const [permissionsLoading, setPermissionsLoading] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);

  const loadPermissions = async () => {
    setPermissionsLoading(true);
    try {
      const res = await getPermissions();
      setPermissions(res?.data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setPermissionsLoading(false);
    }
  };

  const handleSubmit = async (values, formikHandler) => {
    const { email, role } = values;
    setLoading(true);
    try {
      const res = await addTeamMember({
        email: email?.toLowerCase(),
        role,
      });
      await getMembers();
      setLoading(false);
      formikHandler.resetForm();
      setIsOpen(false);
    } catch (err) {
      console.log('err', err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
      setLocalInvite({});
      formikRef.current?.resetForm();
    } else if (permissions?.length === 0) {
      loadPermissions();
    }
  }, [isOpen]);

  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Send Invite"
      width="!w-[700px]"
    >
      <Formik
        enableReinitialize
        initialValues={{
          email: localInvite?.email || '',
          role: localInvite?.role || ADMIN,
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required('Please Enter Email')
            .email('Must be valid Email'),
          role: Yup.string().required('Please Select Access'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="px-5 pt-4 pb-1  grid sm:grid-cols-2 gap-4">
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="email"
                >
                  Email <span className="text-rose-500">*</span>
                </label>
                <input
                  id="email"
                  type="text"
                  placeholder="Email"
                  name="email"
                  className={`form-input w-full ${
                    validation.touched.email &&
                    validation.errors.email &&
                    'border-rose-300'
                  }`}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  invalid={
                    !!(validation.touched.email && validation.errors.email)
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.email}
                  </div>
                ) : null}
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Access <span className="text-rose-500">*</span>
                </label>
                <MembersDropdown
                  fullWidth
                  onChangeValue={(_, value) => {
                    validation.setFieldValue('role', value);
                  }}
                  value={validation.values.role}
                />
                {validation.touched.role && validation.errors.role ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.role}
                  </div>
                ) : null}
              </div>{' '}
            </div>

            <div className="mx-5 border border-[#D0D5DD] mt-6 mb-2 rounded">
              <div
                className="flex rounded-t justify-between items-center h-12 bg-gray-100 px-4 cursor-pointer"
                onClick={() => setShowPermissions((prev) => !prev)}
                role="button"
                tabIndex="0"
              >
                <h3 className="font-medium text-slate-700">View Permissions</h3>
                <svg
                  className={`shrink-0 ml-1 text-slate-700 stroke-slate-700 ${showPermissions ? 'rotate-180' : ''}`}
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                    className="stroke-current"
                    strokeOpacity="0.8"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              {process.env.REACT_APP_ENV === DevEnv &&
                showPermissions &&
                (permissionsLoading ? (
                  <div className="flex flex-col items-center justify-center my-6">
                    <Loader />
                    <p className="mt-4 text-sm">
                      Fetching data. please wait...
                    </p>
                  </div>
                ) : (
                  <div className="overflow-x-auto scrollbar max-h-[80dvh] h-[400px]">
                    <table className="table-fixed w-full dark:text-slate-300">
                      {/* Table header */}
                      <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                        <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                          <th className="px-4 w-[18rem] max-w-[18rem]">
                            <div className="text-left w-[6.25rem] font-medium">
                              Feature
                            </div>
                          </th>
                          <th className="w-[4rem] max-w-[4rem]">
                            <div className="text-center font-medium">Owner</div>
                          </th>
                          <th className="w-[4rem] max-w-[4rem]">
                            <div className="text-center font-medium">Admin</div>
                          </th>
                          <th className="w-[6rem] max-w-[6rem]">
                            <div className="text-center font-medium">
                              Accountant
                            </div>
                          </th>
                          <th className="w-[5rem] max-w-[5rem]">
                            <div className="text-center font-medium">
                              Viewer
                            </div>
                          </th>
                        </tr>
                      </thead>
                      {/* Table body */}
                      {permissions?.length > 0 && (
                        <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                          {permissions?.map((permission, index) => (
                            <tr
                              key={index}
                              className="w-full pl-[1.375rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5"
                            >
                              <td className="px-4">
                                <div className="capitalize text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                                  {permission?.name}
                                </div>
                              </td>
                              <td className="">
                                <div className=" flex justify-center ">
                                  {permission?.roles?.includes('owner') && (
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4   stroke-[#78BD4F] text-[#78BD4F]"
                                    >
                                      <path
                                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                                        stroke="#78BD4F"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="stroke-current"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </td>
                              <td className="">
                                <div className=" flex justify-center ">
                                  {permission?.roles?.includes('admin') && (
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4   stroke-[#78BD4F] text-[#78BD4F]"
                                    >
                                      <path
                                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                                        stroke="#78BD4F"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="stroke-current"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </td>
                              <td className="whitespace-nowrap">
                                <div className=" flex justify-center ">
                                  {permission?.roles?.includes(
                                    'accountant',
                                  ) && (
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4   stroke-[#78BD4F] text-[#78BD4F]"
                                    >
                                      <path
                                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                                        stroke="#78BD4F"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="stroke-current"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </td>
                              <td className="whitespace-nowrap">
                                <div className=" flex justify-center ">
                                  {permission?.roles?.includes('viewer') && (
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4   stroke-[#78BD4F] text-[#78BD4F]"
                                    >
                                      <path
                                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                                        stroke="#78BD4F"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="stroke-current"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                ))}
            </div>

            {/* Modal footer */}
            <div className="flex flex-wrap  justify-end space-x-2 px-5 py-4 mt-3">
              <button
                type="submit"
                className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white
            disabled:!bg-indigo-100  disabled:text-slate-400  disabled:cursor-not-allowed"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Send Invite
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default MembersModal;
