import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceForm from './InvoiceForm';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import InvoicesTable from './InvoicesTable';
import EstimatesTable from './EstimatesTable';
import SearchForm from '../../partials/actions/SearchForm';
import {
  assignInvoiceToBill,
  deleteInvoice,
  getAccounts,
  getAllProjects,
  getCustomers,
  getInvoiceDefaultDocs,
  getInvoices,
  getPeople,
  getPeopleTeam,
  getRecurringTemplates,
  getTags,
  getTaxes,
  getTeamById,
  updateInvoice,
} from '../../API/backend_helper';
import DateFilter from '../transactions/DateFilter';
import TobTabs from './TobTabs';
import DeleteModal from './DeleteModal';
import TransactionsModal from './TransactionsModal';
import { getFormattedDate, IS_PERSON, transformAccounts, VIEWER } from '../../utils/Utils';
import SendInvoiceModal from './SendInvoiceModal';
import NoInvoiceBox from './NoInvoiceBox';
import PaymentsModal from './PaymentsModal';
import InvoicesBanner from './InvoicesBanner';
import StripeWarning from '../../components/StripeWarning';
import AppLayout from '../../components/AppLayout';
import { setLocalUser } from '../../redux/User/reducer';
import { setTeam } from '../../redux/Team/reducer';
import RecurringTemplatesTable from './RecurringTemplatesTable';

export const CREATE_INVOICE = 'createInvoice';
export const EDIT_INVOICE = 'editInvoice';
export const INVOICES_TAB = 'invoicesTab';
export const ESTIMATES_TAB = 'estimatesTab';
export const RECURRING_TAB = 'recurringTab';

const INVOICES_STATUSES = [
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'partial',
    label: 'Partial',
  },
  {
    value: 'unpaid',
    label: 'Unpaid',
  },
  {
    value: 'overdue',
    label: 'Overdue',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
  {
    value: 'sent',
    label: 'Sent',
  },
];

const ESTIMATES_STATUSES = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'expired',
    label: 'Expired',
  },
  {
    value: 'accepted',
    label: 'Accepted',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
  {
    value: 'sent',
    label: 'Sent',
  },
];

let searchTimeoutInvoice = null;

const Invoices = () => {
  const navigate = useNavigate();

  document.title = 'COUNT | Invoices & Estimates';
  const IsPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;
  const dispatch = useDispatch();
  const { accessType, localUser } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const location = useLocation();

  const [isActive, setIsActive] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(INVOICES_TAB);
  const [searchParams, setSearchParams] = useSearchParams();
  const [_invoices, setInvoices] = useState([]);
  const [addType, setAddType] = useState('');
  const [localLimit, setLocalLimit] = useState(50);
  const [editInvoice, setEditInvoice] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [defaultAttachments, setDefaultAttachments] = useState([]);
  const [reloadInvoice, setReloadInvoice] = useState(false);

  // Recurring Templates
  const [recurringTemplates, setRecurringTemplates] = useState([]);

  // Filters
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [sendInvoiceModalOpen, setSendInvoiceModalOpen] = useState(false);

  // Transactions Modal
  const [transactionsModalOpen, setTransactionsModalOpen] = useState(false);
  const [transactionLocalLimit, setTransactionsLocalLimit] = useState(7);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [allCategories, setAllCategories] = useState([]);

  // Assigned transaction tab
  const [isAssignedTransTab, setIsAssignedTransTab] = useState(false);

  // taxes
  const [taxes, setTaxes] = useState([]);

  const [salesRep, setSalesRep] = useState([]);
  const [tags, setTags] = useState([]);

  const tab = searchParams.get('tab') || '';
  const Id = searchParams.get('id') || '';
  const From = searchParams.get('from') || '';

  // supporting API's
  const getSalesRepApi = async () => {
    try {
      const res = await getPeople({
        limit: 1000000,
        roles: ['sales rep'],
      });
      setSalesRep(res?.people);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getTagsApi = async () => {
    try {
      const res = await getTags();
      setTags(res);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getCustomersApi = async () => {
    try {
      const res = await getCustomers({ limit: 1000000 });
      setCustomers(res?.data?.records);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadDefaultAttachments = async () => {
    try {
      const res = await getInvoiceDefaultDocs();
      setDefaultAttachments(res?.data?.attachments);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadProjects = async () => {
    try {
      const res = await getAllProjects({});
      const filteredProjects = res?.filter(
        (project) =>
          project?.status === 'Not started' ||
          project?.status === 'In progress',
      );
      setProjects(filteredProjects);
    } catch (err) {
      console.log('err', err);
    }
  };

  const fetchTeam = async (updateLocalTeam = false) => {
    if (team?.id) {
      try {
        let res = {};
        if (IsPerson) {
          res = await getPeopleTeam();
        } else {
          res = await getTeamById(team?.id);
        }
        dispatch(setTeam(res));
        if (updateLocalTeam) {
          const tempUser = { ...localUser };
          const teamIndex = tempUser?.teams?.findIndex(
            (t) => t?.id === res?.id,
          );
          tempUser.teams[teamIndex] = res;
          dispatch(setLocalUser(tempUser));
        }
      } catch (e) {
        console.log('error', e);
      }
    }
  };

  const onSetActiveTab = (val) => {
    setActiveTab(val);
    setSearchParams({
      tab:
        val === INVOICES_TAB
          ? 'invoices'
          : val === ESTIMATES_TAB
            ? 'estimates'
            : 'recurring',
    });
  };

  const getData = async ({
    type = activeTab === INVOICES_TAB ? 'invoice' : 'estimate',
    search = searchVal || '',
    limit = localLimit,
    page = 1,
    loading = false,
    customers = selectedCustomers || [],
    status = selectedStatuses || [],
    startDate = startDateFilter ? getFormattedDate(startDateFilter) : '',
    endDate = endDateFilter ? getFormattedDate(endDateFilter) : '',
    updateEdit = false,
    isRecurring = activeTab === RECURRING_TAB,
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      if (updateEdit && Id) {
        setReloadInvoice(true);
        setEditInvoice({ id: Id });
      }
      const res = await getInvoices({
        type,
        limit,
        page,
        customers,
        status,
        startDate,
        endDate,
        search,
        isRecurring,
      });
      setInvoices(res?.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);

      console.log('error', e);
    }
  };

  const loadRecurringTemplates = async ({ loading = false }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getRecurringTemplates();
      setRecurringTemplates(res?.data?.result);
    } catch (err) {
      console.log('err', err);
    } finally {
      if (loading) {
        setIsLoading(false);
      }
    }
  };

  const onEditInvoice = (obj) => {
    setEditInvoice(obj);
    setIsActive(EDIT_INVOICE);
  };

  const onDateChange = async (startDate, endDate) => {
    await getData({
      startDate: startDate ? getFormattedDate(startDate) : '',
      endDate: endDate ? getFormattedDate(endDate) : '',
      page: 1,
      loading: true,
    });
  };

  const handleResetFilters = () => {
    setIsLoading(true);
    setSelectedCustomers([]);
    setSelectedStatuses([]);
    setStartDateFilter('');
    setEndDateFilter('');
    setSearchVal('');
    getData({
      page: 1,
      customers: [],
      status: [],
      startDate: '',
      endDate: '',
      search: '',
    });
  };

  const handleDeleteInvoice = async (id) => {
    try {
      await deleteInvoice(id);
      await getData({
        loading: false,
      });
      setDeleteModalOpen(null);
      toast.success(
        `${
          activeTab === INVOICES_TAB ? 'Invoice' : 'Estimate'
        } deleted successfully`,
      );
    } catch (e) {
      console.log('error', e);
    }
  };

  // eslint-disable-next-line default-param-last
  const convertEstimateToInvoice = async (id, type = 'invoice', isDraft) => {
    try {
      await updateInvoice({ invoiceType: type, isDraft }, id);
      await getData({
        loading: false,
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleTransactionAssignment = async (
    transactionId = activeTransaction?.id,
  ) => {
    try {
      const res = await assignInvoiceToBill(transactionsModalOpen?.id, {
        transactions: [transactionId],
      });
      if (res) {
        getData?.({});
        if (From) {
          navigate(-1);
        } else {
          if (
            (isActive === CREATE_INVOICE || isActive === EDIT_INVOICE) &&
            isPreview &&
            editInvoice?.id
          ) {
            setIsActive(INVOICES_TAB);
          }
          setIsPreview(false);
          setEditInvoice(false);
          setTransactionsModalOpen(null);
        }

        toast.success('Transaction assigned successfully');
        return res;
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadAllCategories = async () => {
    try {
      const accountsRes = await getAccounts();
      setAllCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const onCreateInvoice = (isRecurring = false) => {
    setIsAssignedTransTab(false);
    setEditInvoice(null);
    setIsActive(CREATE_INVOICE);
    setIsPreview(false);
    setAddType('Invoice');
    if (isRecurring) {
      setSearchParams({
        tab: 'recurring',
        isRecurring: true,
      });
    }
  };

  useEffect(() => {
    getCustomersApi();
    if (!IsPerson) {
      getSalesRepApi();
      loadAllCategories();
    }
    loadProjects();
    getTagsApi();
    loadTaxes();
    loadDefaultAttachments();
  }, []);

  useEffect(() => {
    if (team?.id) {
      fetchTeam();
    }
  }, [team?.id]);

  useEffect(() => {
    if (tab === 'estimates') {
      setActiveTab(ESTIMATES_TAB);
      getData({ loading: true, type: 'estimate', status: [] });
    }
    if (tab === 'invoices') {
      setActiveTab(INVOICES_TAB);
      getData({ loading: true, type: 'invoice', status: [] });
    }
    if (tab === 'recurring') {
      setActiveTab(RECURRING_TAB);
      loadRecurringTemplates({ loading: true });
    }
    setSelectedStatuses([]);
  }, [tab, Id]);

  useEffect(() => {
    if (Id) {
      setEditInvoice({
        id: Id,
      });
      setIsPreview(true);
      setIsActive(EDIT_INVOICE);
      setIsAssignedTransTab(false);
    } else {
      setIsPreview(false);
      setIsActive('');
      setIsAssignedTransTab(false);
    }
  }, [Id]);

  useEffect(() => {
    if (location?.state?.dashboard) {
      onCreateInvoice();
    }
  }, [location]);

  const { page, invoices, totalRecords, filters } = _invoices || {};

  const sendEmailAction = (invoice) => {
    setPaymentModalOpen(false);
    setSendInvoiceModalOpen(invoice);
  };

  const onSearch = (val) => {
    setIsLoading(true);
    setSearchVal(val);
    clearTimeout(searchTimeoutInvoice);
    searchTimeoutInvoice = null;
    searchTimeoutInvoice = setTimeout(async () => {
      getData({
        search: encodeURIComponent(val || ''),
        page: 1,
        // loading:true
      });
    }, 900);
  };

  const onCreateEstimate = (e) => {
    e.stopPropagation();
    setIsAssignedTransTab(false);
    setIsActive(CREATE_INVOICE);
    setEditInvoice(null);
    setIsPreview(false);
    setAddType('Estimate');
  };

  const onClickInvoiceEstimateSidebar = (tab) => {
    setIsAssignedTransTab(false);
    setEditInvoice(null);
    setIsActive(tab === 'invoice' ? INVOICES_TAB : ESTIMATES_TAB);
    setIsPreview(false);
  };

  return (
    <AppLayout
      pageId="invoiceEstimateContainer"
      onClickInvoiceEstimate={onClickInvoiceEstimateSidebar}
      mainClass=" relative"
      isDashboardPage
    >
      <main className="grow">
        <SendInvoiceModal
          isOpen={sendInvoiceModalOpen}
          setIsOpen={setSendInvoiceModalOpen}
          type={activeTab === INVOICES_TAB ? 'Invoice' : 'Estimate'}
          getData={getData}
          team={team}
          currencies={currencies}
        />
        <PaymentsModal
          isOpen={paymentModalOpen}
          setIsOpen={setPaymentModalOpen}
          sendEmailAction={sendEmailAction}
        />
        <TransactionsModal
          isOpen={transactionsModalOpen}
          setIsOpen={setTransactionsModalOpen}
          transactions={[]}
          transactionsSearch=""
          activeTransaction={activeTransaction}
          // activeInstance={
          //   allBills?.bills?.[activeBillIndex]?.instances?.[activeInstanceIndex]
          // }
          setActiveTransaction={setActiveTransaction}
          localLimit={transactionLocalLimit}
          setLocalLimit={setTransactionsLocalLimit}
          handleTransactionAssignment={handleTransactionAssignment}
          updateInstanceTotal={() => {}}
          type={activeTab === INVOICES_TAB ? 'Invoice' : 'Estimate'}
          allCategories={allCategories}
          currencies={currencies}
        />
        <DeleteModal
          handleDone={async () => {
            await handleDeleteInvoice(deleteModalOpen?.id);
          }}
          invoiceNumber={deleteModalOpen?.invoiceNumber}
          isOpen={deleteModalOpen}
          setIsOpen={setDeleteModalOpen}
          type={activeTab === INVOICES_TAB ? 'Invoice' : 'Estimate'}
        />
        {/* Content */}
        {isActive === CREATE_INVOICE || isActive === EDIT_INVOICE ? (
          <InvoiceForm
            setIsActive={setIsActive}
            type={addType}
            setType={setAddType}
            getData={getData}
            localInvoice={editInvoice}
            setLocalInvoice={setEditInvoice}
            isPreview={isPreview}
            setIsPreview={setIsPreview}
            customers={customers}
            getCustomersApi={getCustomersApi}
            setSendInvoiceModalOpen={setSendInvoiceModalOpen}
            setActiveTab={setActiveTab}
            team={team}
            fetchTeam={fetchTeam}
            isAssignedTransTab={isAssignedTransTab}
            setIsAssignedTransTab={setIsAssignedTransTab}
            setTransactionsModalOpen={setTransactionsModalOpen}
            paymentModalOpen={paymentModalOpen}
            setPaymentModalOpen={setPaymentModalOpen}
            onSetActiveTab={onSetActiveTab}
            currencies={currencies}
            projects={projects}
            sendEmailAction={sendEmailAction}
            taxes={taxes}
            loadTaxes={loadTaxes}
            setTaxes={setTaxes}
            accessType={accessType}
            convertEstimateToInvoice={convertEstimateToInvoice}
            defaultAttachments={defaultAttachments}
            salesRep={salesRep}
            tags={tags}
            ESTIMATES_TAB={ESTIMATES_TAB}
            INVOICES_TAB={INVOICES_TAB}
            reloadInvoice={reloadInvoice}
            setReloadInvoice={setReloadInvoice}
          />
        ) : (
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {!IsPerson &&
              team?.id &&
              team?.stripeAccountId &&
              accessType !== VIEWER && <StripeWarning teamProp={team} />}
            {!IsPerson &&
              team?.id &&
              accessType !== VIEWER &&
              (!team?.stripeAccountId ||
                (team?.stripeAccountId &&
                  parseInt(team?.onboardingCount) > 0)) &&
              (team?.country === 'US' ||
                team?.country === 'CA' ||
                team?.country === 'NZ') && <InvoicesBanner />}
            {/* Page header */}
            <div className="flex justify-between items-center mb-4 md:mb-5">
              <h1 className="text-[36px] leading-tight font-medium text-[#00000099]">
                Invoices & Estimates
              </h1>
              <div className="flex gap-4 flex-wrap justify-end gap-y-2">
                <div className="hidden md:block">
                  <SearchForm
                    transaction
                    searchVal={searchVal}
                    setSearchVal={onSearch}
                    placeholder="Search"
                  />
                </div>
                {accessType !== VIEWER && (
                  <>
                    <button
                      onClick={onCreateEstimate}
                      className="btn-sm h-10 px-3 text-base text-indigo-500 bg-[#E486420D]
                            border-[#E48642]  whitespace-nowrap    disabled:cursor-not-allowed"
                      type="button"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                      >
                        <path
                          d="M7.00033 1.16699V12.8337M1.16699 7.00033H12.8337"
                          stroke="#E48642"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{' '}
                      Create Estimate
                    </button>
                    <button
                      onClick={() => onCreateInvoice(false)}
                      className="btn-sm h-10 px-3 text-base border-indigo-500 bg-indigo-500 hover:bg-indigo-600 text-white
                    disabled:bg-indigo-400 whitespace-nowrap    disabled:cursor-not-allowed"
                      type="button"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                      >
                        <path
                          d="M7.00033 1.16699V12.8337M1.16699 7.00033H12.8337"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{' '}
                      Create Invoice
                    </button>
                  </>
                )}
              </div>
            </div>
            <TobTabs
              activeTab={activeTab}
              setActiveTab={(t) => {
                setActiveTab(t);
                onSetActiveTab(t);
              }}
              ESTIMATES_TAB={ESTIMATES_TAB}
              INVOICES_TAB={INVOICES_TAB}
              RECURRING_TAB={RECURRING_TAB}
            />
            {/* Filters  */}
            {activeTab !== RECURRING_TAB && (
              <div className="w-full  flex justify-between items-center flex-wrap gap-2 mb-7">
                <ul className="flex flex-wrap -m-1 py-2">
                  <li className="m-1 w-[300px] max-w-full inline-flex">
                    <BasicCheckboxDropdown
                      selectedOptions={selectedCustomers}
                      setSelectedOptions={setSelectedCustomers}
                      apiFunc={(obj) => getData({ ...obj, loading: true })}
                      options={
                        customers?.map((c) => ({
                          label: c?.customer
                            ? c?.customer
                            : c?.contacts?.[0]?.firstName,
                          value: `${c?.id}`,
                        })) || []
                      }
                      onApply={(values) => {
                        getData({
                          page: 1,
                          customers: values,
                          loading: true,
                        });
                      }}
                      onClear={() => {
                        setIsLoading(true);
                        getData({ customers: [], page: 1, loading: false });
                        setSelectedCustomers([]);
                      }}
                      label="All Customers"
                      type="customers"
                      apiFilter={filters?.customers}
                      search
                    />
                  </li>
                  <li className="m-1 w-[300px] max-w-full inline-flex">
                    <BasicCheckboxDropdown
                      selectedOptions={selectedStatuses}
                      setSelectedOptions={setSelectedStatuses}
                      apiFunc={(obj) => getData({ ...obj, loading: true })}
                      options={
                        activeTab === INVOICES_TAB
                          ? INVOICES_STATUSES
                          : ESTIMATES_STATUSES
                      }
                      onApply={(values) => {
                        getData({
                          page: 1,
                          status: values,
                          loading: true,
                        });
                      }}
                      onClear={() => {
                        setIsLoading(true);
                        getData({ status: [], page: 1, loading: false });
                        setSelectedStatuses([]);
                      }}
                      label="All Statuses"
                      type="status"
                      apiFilter={filters?.status}
                    />
                  </li>
                  <li className="m-1 w-[300px] max-w-full inline-flex">
                    <DateFilter
                      startDateFilter={startDateFilter}
                      endDateFilter={endDateFilter}
                      getTransactionsApi={getData}
                      setStartDateFilter={setStartDateFilter}
                      setEndDateFilter={setEndDateFilter}
                      onDateChange={onDateChange}
                      apiFilter={filters || {}}
                      align="right"
                      maxDate={null}
                    />
                  </li>
                  <li className="m-1 w-[100px] ml-5 max-w-full inline-flex">
                    <button
                      onClick={handleResetFilters}
                      className="btn h-10 py-0 px-4 font-normal text-sm leading-6 border border-red-400 text-red-400 bg-[#FF4B4B05]"
                      type="button"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-1 f"
                      >
                        <path
                          d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                          fill="#FF4B4B"
                        />
                      </svg>
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
            )}

            {isLoading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            ) : !isLoading &&
              invoices?.length === 0 &&
              selectedCustomers?.length === 0 &&
              selectedStatuses?.length === 0 &&
              recurringTemplates?.length === 0 &&
              !startDateFilter &&
              !endDateFilter &&
              !searchVal ? (
              <NoInvoiceBox
                type={
                  activeTab === INVOICES_TAB
                    ? 'Invoice'
                    : activeTab === ESTIMATES_TAB
                      ? 'Estimate'
                      : 'Recurring'
                }
                handleCreate={
                  activeTab === ESTIMATES_TAB
                    ? onCreateEstimate
                    : activeTab === INVOICES_TAB
                      ? () => onCreateInvoice(false)
                      : () => onCreateInvoice(true)
                }
                accessType={accessType}
              />
            ) : (
              <>
                {activeTab === INVOICES_TAB && (
                  <InvoicesTable
                    activeTab={activeTab}
                    setActiveTab={onSetActiveTab}
                    setLocalLimit={setLocalLimit}
                    localLimit={localLimit}
                    invoices={invoices}
                    getData={getData}
                    page={page}
                    totalRecords={totalRecords}
                    setEditInvoice={onEditInvoice}
                    setIsPreview={setIsPreview}
                    setDeleteModalOpen={setDeleteModalOpen}
                    setTransactionsModalOpen={setTransactionsModalOpen}
                    setAddType={setAddType}
                    sendEmailAction={sendEmailAction}
                    convertEstimateToInvoice={convertEstimateToInvoice}
                    setIsAssignedTransTab={setIsAssignedTransTab}
                    paymentModalOpen={paymentModalOpen}
                    setPaymentModalOpen={setPaymentModalOpen}
                    team={team}
                    accessType={accessType}
                    currencies={currencies}
                    setSearchParams={setSearchParams}
                  />
                )}
                {activeTab === ESTIMATES_TAB && (
                  <EstimatesTable
                    activeTab={activeTab}
                    setActiveTab={onSetActiveTab}
                    setLocalLimit={setLocalLimit}
                    localLimit={localLimit}
                    estimates={invoices}
                    getData={getData}
                    page={page}
                    totalRecords={totalRecords}
                    setEditInvoice={onEditInvoice}
                    setIsPreview={setIsPreview}
                    setDeleteModalOpen={setDeleteModalOpen}
                    convertEstimateToInvoice={convertEstimateToInvoice}
                    setAddType={setAddType}
                    sendEmailAction={sendEmailAction}
                    setIsAssignedTransTab={setIsAssignedTransTab}
                    accessType={accessType}
                    currencies={currencies}
                    setSearchParams={setSearchParams}
                    projects={projects}
                  />
                )}
                {activeTab === RECURRING_TAB && (
                  <RecurringTemplatesTable
                    recurringTemplates={recurringTemplates}
                    loadRecurringTemplates={loadRecurringTemplates}
                  />
                )}
              </>
            )}
          </div>
        )}
      </main>
    </AppLayout>
  );
};

export default Invoices;
