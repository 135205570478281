import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getFormattedDate, TaskStatusOptions, TEAM_ID } from 'utils/Utils';
import Tooltip from 'components/Tooltip';
import ModalBasic from '../../components/ModalBasic';
import DropdownFull from '../../components/DropdownFull';
import TextInputDash from '../../components/TextInputDash';
import DatePickerDash from '../../components/DatePickerDash';
import {
  createAccountingFirmTasks,
  createTask,
  getAllProjects,
  getTeamById,
} from '../../API/backend_helper';
import { Loader } from '../../components/Svg';
import TasksDropdown from './TasksDropdown';

const AddTaskModal = ({
  modalOpen,
  setModalOpen,
  loadData = null,
  transactions = false,
  bills = false,
  selectedBill = null,
  selectedOption = '',
  setSelectedOption = null,
  selectedTransaction = null,
  projects = [],
  statusOptions = TaskStatusOptions,
  hideAssignee = false,
  showAccountantProjects = false,
  hideProjects = false,
  showClients = false,
  clients = [],
  accountantTask = false,
  accountingFirmId = '',
}) => {
  const formikRef = useRef(null);
  const { team } = useSelector((state) => state.Team);

  const [noClick, setNoClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientUsers, setClientUsers] = useState([]);
  const [clientUsersLoading, setClientUsersLoading] = useState(false);
  const [clientProjects, setClientProjects] = useState([]);
  const [clientProjectsLoading, setClientProjectsLoading] = useState(false);

  const loadClientUsers = async (teamId) => {
    setClientUsersLoading(true);
    try {
      const res = await getTeamById(teamId);
      setClientUsers(res?.users);
    } catch (err) {
      console.log('err', err);
    } finally {
      setClientUsersLoading(false);
    }
  };

  const loadClientProjects = async (teamId) => {
    setClientProjectsLoading(true);
    localStorage.setItem(TEAM_ID, teamId);
    try {
      const res = await getAllProjects({});
      const newProjects = res?.map((project) => ({
        ...project,
        name: `${project?.name} ${project?.customId ? `(${project.customId})` : ''}`,
      }));
      setClientProjects(newProjects);
    } catch (err) {
      console.log('err', err);
    } finally {
      setClientProjectsLoading(false);
      localStorage.setItem(TEAM_ID, '');
    }
  };

  const taskTypes = transactions
    ? [
        { name: 'Review Transaction', id: 'reviewTransaction' },
        { name: 'Set Transaction Vendor', id: 'transactionVendor' },
        { name: 'Set Transaction Category', id: 'transactionCategory' },
        { name: 'Upload Transaction Attachment', id: 'transactionAttachment' },
      ]
    : bills
      ? [{ name: 'Pay Bill', id: 'payBill' }]
      : [{ name: 'To-Do', id: 'toDo' }];

  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      position: 'bottom',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen() {
        setNoClick(true);
      },
      onClose() {
        setNoClick(false);
      },
    }),
    [team],
  );

  useEffect(() => {
    if (bills) {
      formikRef.current.setFieldValue('taskType', 'payBill');
    }
  }, [bills]);

  useEffect(() => {
    if (!modalOpen) {
      formikRef.current.resetForm();
    }
  }, [modalOpen]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const formattedDate = getFormattedDate(
      values.deadlineDate,
      team?.defaults?.dateFormat || 'mm/dd/yyyy',
    );
    const taskType = values.taskType || selectedOption;
    const transactionId = selectedTransaction ? selectedTransaction.id : null;
    const billId = selectedBill ? selectedBill.id : null;
    if (!values.assigneeId) {
      values.assigneeId = null;
    }

    try {
      if (accountantTask) {
        localStorage.setItem(TEAM_ID, values.clientId);
        const data = new FormData();
        data.append('taskName', values.taskName);
        data.append('deadlineDate', formattedDate);
        data.append('taskType', values.taskType);
        data.append('description', values.description);
        data.append('taskStatus', values.taskStatus);
        data.append('priority', values.priority);
        data.append('assigneeId', values.assigneeId);
        data.append('projectId', values.projectId);
        data.append('accountingFirmId', accountingFirmId);
        await createAccountingFirmTasks(data, values.clientId);
      } else {
        await createTask({
          ...values,
          deadlineDate: formattedDate,
          taskType,
          transactionId,
          billId,
        });
      }

      toast.success('Task created successfully');
      if (loadData) {
        await loadData({});
      }
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      if (accountantTask) {
        localStorage.setItem(TEAM_ID, '');
      }
      setIsLoading(false);
    }
  };

  return (
    <ModalBasic
      title="Add Task"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      noClick={noClick}
      width="!w-[800px]"
    >
      <Formik
        enableReinitialize={false}
        initialValues={{
          taskName: '',
          taskType: transactions ? selectedOption : bills ? 'payBill' : 'toDo',
          description: '',
          assigneeId: null,
          taskStatus: 'notStarted',
          deadlineDate: '',
          priority: 'low',
          projectId: null,
          clientId: null,
        }}
        validationSchema={Yup.object({
          taskName: Yup.string()
            .required('Task Title is required')
            .max(
              255,
              'Task title must be less than or equal to 255 characters',
            ),
          taskType: Yup.string(),
          description: Yup.string(),
          taskStatus: Yup.string(),
          deadlineDate: Yup.string(),
          priority: Yup.string().required('Priority is required'),
          clientId: accountantTask
            ? Yup.string().required('Client is required')
            : '',
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="bg-white border-y border-[#D0D5DD]">
              <div className="w-full">
                <div className=" gap-6 grid grid-cols-1 md:grid-cols-2 py-6 px-10 w-full">
                  {transactions && (
                    <div className="flex flex-col gap-2.5 w-full">
                      <label className="text-slate-600 font-medium text-[14px] leading-5">
                        Task Type <span className="text-red-600">*</span>
                      </label>
                      <DropdownFull
                        options={taskTypes}
                        placeholder="Select Task Type"
                        name="name"
                        selected={validation.values.taskType || selectedOption}
                        setSelected={(value) => {
                          validation.setFieldValue('taskType', value);
                          setSelectedOption(value);
                        }}
                        height="h-10"
                        scrollIntoView={false}
                      />
                    </div>
                  )}
                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5">
                      Task Title <span className="text-red-600">*</span>
                    </label>
                    <TextInputDash
                      placeholder="Enter Task Title"
                      value={validation.values.taskName}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      paddingBottom="pb-0"
                      name="taskName"
                      inputClass="!h-10 !mb-0"
                    />
                    {validation.touched.taskName &&
                    validation.errors.taskName ? (
                      <span className="text-xs text-rose-500 h-[14px] -mt-1">
                        {validation.errors.taskName}
                      </span>
                    ) : null}
                  </div>
                  {showClients && (
                    <div className="flex flex-col gap-2.5 w-full">
                      <label className="text-slate-600 font-medium text-[14px] leading-5">
                        Client <span className="text-red-600">*</span>
                      </label>
                      <DropdownFull
                        options={clients}
                        selected={validation.values.clientId}
                        setSelected={(value) => {
                          validation.setFieldValue('clientId', value);
                          validation.setFieldValue('assigneeId', null);
                          validation.setFieldValue('projectId', null);
                          loadClientUsers(value);
                          loadClientProjects(value);
                        }}
                        name="name"
                        height="h-10"
                        scrollIntoView={false}
                      />
                      {validation.touched.clientId &&
                      validation.errors.clientId ? (
                        <span className="text-xs text-rose-500 h-[14px] -mt-1">
                          {validation.errors.clientId}
                        </span>
                      ) : null}
                    </div>
                  )}
                  {!hideAssignee && (
                    <div className="flex flex-col gap-2.5 w-full">
                      <label className="text-slate-600 font-medium text-[14px] leading-5">
                        Assignee
                      </label>
                      {!showClients ? (
                        <DropdownFull
                          options={team?.users}
                          selected={validation.values.assigneeId}
                          setSelected={(value) =>
                            validation.setFieldValue('assigneeId', value)
                          }
                          users
                          height="h-10"
                          scrollIntoView={false}
                        />
                      ) : clientUsersLoading ? (
                        <div className="w-full flex">
                          <Loader width="w-6" height="h-6" />
                        </div>
                      ) : validation.values.clientId ? (
                        <DropdownFull
                          options={clientUsers}
                          selected={validation.values.assigneeId}
                          setSelected={(value) =>
                            validation.setFieldValue('assigneeId', value)
                          }
                          users
                          height="h-10"
                          scrollIntoView={false}
                        />
                      ) : (
                        <p className="text-sm">Please first select Client.</p>
                      )}
                      {validation.touched.assigneeId &&
                      validation.errors.assigneeId ? (
                        <span className="text-xs text-rose-500 h-[14px] -mt-1">
                          {validation.errors.assigneeId}
                        </span>
                      ) : null}
                    </div>
                  )}
                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5">
                      Task Priority <span className="text-red-600">*</span>
                    </label>
                    <DropdownFull
                      options={[
                        { name: 'High Priority', id: 'high' },
                        { name: 'Medium Priority', id: 'medium' },
                        { name: 'Low Priority', id: 'low' },
                      ]}
                      placeholder="Select Priority"
                      name="name"
                      selected={validation.values.priority}
                      setSelected={(value) =>
                        validation.setFieldValue('priority', value)
                      }
                      height="h-10"
                      scrollIntoView={false}
                      priorityDot
                    />
                  </div>

                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5 flex gap-2 items-center">
                      Project
                    </label>
                    {!showAccountantProjects ? (
                      <DropdownFull
                        options={projects}
                        selected={validation.values.projectId}
                        setSelected={(value) =>
                          validation.setFieldValue('projectId', value)
                        }
                        name="name"
                        height="h-10"
                        scrollIntoView={false}
                      />
                    ) : clientProjectsLoading ? (
                      <div className="w-full flex">
                        <Loader width="w-6" height="h-6" />
                      </div>
                    ) : validation.values.clientId ? (
                      <DropdownFull
                        options={clientProjects}
                        selected={validation.values.projectId}
                        setSelected={(value) =>
                          validation.setFieldValue('projectId', value)
                        }
                        name="name"
                        height="h-10"
                        scrollIntoView={false}
                      />
                    ) : (
                      <p className="text-sm">Please first select Client.</p>
                    )}
                    {validation.touched.projectId &&
                    validation.errors.projectId ? (
                      <span className="text-xs text-rose-500 h-[14px] -mt-1">
                        {validation.errors.projectId}
                      </span>
                    ) : null}
                  </div>

                  <div className="flex flex-col gap-2.5 w-full">
                    <p className="text-slate-600 text-[14px] font-medium leading-5">
                      Task Status <span className="text-red-500">*</span>
                    </p>
                    <TasksDropdown
                      statusOptions={statusOptions}
                      task={{
                        status: validation.values.taskStatus,
                      }}
                      isFlyout
                      handleUpdateTask={(id) =>
                        validation.setFieldValue('taskStatus', id)
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5 whitespace-nowrap">
                      Task Deadline
                    </label>
                    <DatePickerDash
                      required
                      validation={validation}
                      placeholder="Select Date"
                      value={
                        validation.values.deadlineDate
                          ? validation.values.deadlineDate
                          : null
                      }
                      options={options}
                      onChange={(selectedDates) => {
                        validation.setFieldValue(
                          'deadlineDate',
                          selectedDates[0],
                        );
                      }}
                      name="deadlineDate"
                      id="deadlineDate"
                      error={
                        validation.touched.deadlineDate &&
                        validation.errors.deadlineDate
                          ? validation.errors.deadlineDate
                          : ''
                      }
                      width="w-full"
                      inputClass="!h-10"
                      onBlur={(e) => {
                        if (!e.target.classList?.value?.includes('active')) {
                          validation.setFieldValue(
                            'deadlineDate',
                            e.target.value,
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="col-span-full flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5">
                      Task Description
                    </label>
                    <TextInputDash
                      placeholder="Details about the task"
                      value={validation.values.description}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      name="description"
                      paddingBottom="pb-0"
                      type="textarea"
                    />
                    {validation.touched.description &&
                    validation.errors.description ? (
                      <span className="text-xs text-rose-500 h-[14px] -mt-3">
                        {validation.errors.description}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-8 px-10 flex w-full justify-end items-center gap-4">
              <button
                type="button"
                className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-white text-slate-600 border border-slate-600"
                onClick={() => setModalOpen(false)}
              >
                Close
              </button>
              <button
                className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-indigo-500 text-white border border-indigo-500"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader color="#FFFFFF" width="w-4" height="h-4" />
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default AddTaskModal;
