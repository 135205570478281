import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import UploadFile from 'components/UploadFile';
import ImageCropModal from 'components/ImageCropModal';
import DropdownInput from 'components/DropdownInput';
import TextArea from 'components/TextArea';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import Logo from '../../images/custom/count-logo.png';
import TextInput from '../../components/TextInput';
import AuthSlider from '../../components/AuthSlider';
import { createAccountantFirm, getUserCountry } from '../../API/backend_helper';
import Countries from './data/countries.json';

const AccountantFirmForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [currentImageForCrop, setCurrentImageForCrop] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('');

  // File Management
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');
  const [imgName, setImgName] = useState('');

  useEffect(
    () => () => files.forEach((file) => URL.revokeObjectURL(file.preview)),
    [],
  );

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const res = await getUserCountry();
        const country = res?.country;
        setSelectedCountry(country);
      } catch (err) {
        console.log('err', err);
      }
    };
    fetchCountry();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    const {
      name,
      email,
      website,
      tagline,
      phone,
      firmZipCode,
      description,
      numberOfEmployees,
      address,
    } = values;
    const logo = files?.[0];
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    if (website) {
      formData.append('website', website);
    }
    formData.append('tagline', tagline);
    formData.append('phone', phone);
    formData.append('firmZipCode', firmZipCode);
    formData.append('description', description);
    formData.append('numberOfEmployees', numberOfEmployees);
    formData.append(
      'address',
      JSON.stringify({
        city: address?.city,
        country: address?.country
          ? Countries?.find((opt) => opt.code === address?.country)?.name
          : '',
      }),
    );
    if (logo) {
      formData.append('logo', logo);
    }

    try {
      const response = await createAccountantFirm(formData);
      if (response?.data?.result?.id) {
        navigate('/accountant');
      }
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="bg-white dark:bg-slate-900">
      <ImageCropModal
        isOpen={cropModalOpen}
        setIsOpen={setCropModalOpen}
        image={currentImageForCrop}
        setPreviewImage={setImgPreview}
        setFiles={setFiles}
        imgName={imgName}
      />
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="relative flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
              </div>
            </div>

            <div className="max-w-[476px] min-w-[50%] mx-auto w-full px-4 py-8">
              <h1 className="text-[40px] leading-tight font-medium text-[#232323CC] mb-8 ">
                Setup your Practice
              </h1>
              {/* <p className="text-lg text-[#667085CC] mb-9">
                Your free account is almost there
              </p> */}
              {/* Form */}
              <Formik
                enableReinitialize
                initialValues={{
                  name: '',
                  email: '',
                  tagline: '',
                  phone: '',
                  firmZipCode: '',
                  description: '',
                  numberOfEmployees: '',
                  website: '',
                  address: {
                    city: '',
                    country: selectedCountry || '',
                  },
                }}
                validationSchema={Yup.object({
                  name: Yup.string()
                    .trim()
                    .required('Please Enter Practice Name')
                    .max(100, 'Practice Name must be at most 100 characters'),

                  email: Yup.string()
                    .trim()
                    .required('Please Enter Email')
                    .email('Email should be valid'),
                  website: Yup.string().matches(
                    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+.*)$/,
                    'Invalid URL',
                  ),

                  tagline: Yup.string()
                    .trim()
                    .max(50, 'Tag Line can be at most 50 characters'),
                  description: Yup.string(),
                  // firmZipCode: Yup.string()
                  //   .trim()
                  //   .required('Please Enter Firm Zip Code')
                  //   .max(50, 'Firm Zip Code must be at most 50 characters'),
                  numberOfEmployees: Yup.number()
                    .min(0, 'Number of Clients should be positive')
                    .integer('Number of Clients should contain digits only')
                    .max(
                      999999999,
                      'Number of Clients can be 9 characters long',
                    )
                    .required('Please Enter Number of Clients'),
                })}
                validate={(values) => {
                  const errors = {};
                  const { phone } = values;
                  if (phone && !isValidPhoneNumber(phone)) {
                    errors.phone = 'Phone number is not valid';
                  }

                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                {(validation) => (
                  <Form>
                    <div className="space-y-8">
                      <TextInput
                        label="Practice Name"
                        placeholder=" "
                        id="name"
                        name="name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ''}
                        error={
                          validation.touched.name ? validation.errors.name : ''
                        }
                        required
                      />
                      <TextInput
                        label="Email"
                        placeholder=" "
                        id="email"
                        name="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ''}
                        error={
                          validation.touched.email
                            ? validation.errors.email
                            : ''
                        }
                        required
                      />
                      {/* <TextInput
                        label="Tag Line"
                        placeholder=" "
                        id="tagline"
                        name="tagline"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.tagline || ''}
                        error={
                          validation.touched.tagline
                            ? validation.errors.tagline
                            : ''
                        }
                      /> */}
                      <div>
                        <label
                          htmlFor="phone"
                          className={`text-sm text-[667085] mb-2 block ${
                            validation.touched.phone && validation.errors.phone
                              ? 'text-rose-400'
                              : ''
                          }`}
                        >
                          Phone Number
                        </label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={validation.values.phone}
                          onChange={(value) =>
                            validation.setFieldValue('phone', value)
                          }
                          international
                          defaultCountry={selectedCountry || ''}
                          id="phone"
                          name="phone"
                          className={`phone_textInput ${
                            validation.touched.phone && validation.errors.phone
                              ? 'phone_textInputError'
                              : ''
                          } `}
                          // onBlur={() => setIsPhoneNumberFocused(true)}
                          // required
                        />
                        <span className=" text-xs text-rose-400">
                          {validation.touched.phone
                            ? validation.errors.phone
                            : ''}
                        </span>
                      </div>
                      {/* <TextInput
                        label="Firm Zip Code"
                        placeholder=" "
                        id="firmZipCode"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firmZipCode || ''}
                        error={
                          validation.touched.firmZipCode
                            ? validation.errors.firmZipCode
                            : ''
                        }
                        required
                      /> */}
                      <TextInput
                        label="Number Of Clients"
                        placeholder=" "
                        id="numberOfEmployees"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.numberOfEmployees || ''}
                        error={
                          validation.touched.numberOfEmployees
                            ? validation.errors.numberOfEmployees
                            : ''
                        }
                        type="number"
                        required
                        min={0}
                      />
                      <TextInput
                        label="Website"
                        placeholder=" "
                        id="website"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.website || ''}
                        error={
                          validation.touched.website
                            ? validation.errors.website
                            : ''
                        }
                      />

                      <TextInput
                        label="City"
                        placeholder=" "
                        id="address.city"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address.city || ''}
                        error={
                          validation.touched?.address?.city
                            ? validation.errors?.address?.city
                            : ''
                        }
                      />
                      <DropdownInput
                        options={Countries}
                        label=" Country"
                        id="address.country"
                        name="name"
                        setSelected={(id) => {
                          validation.setFieldValue('address.country', id);
                        }}
                        selected={validation.values.address.country}
                        error={
                          !validation.values?.address?.country &&
                          validation.touched?.address?.country
                            ? validation.errors?.address?.country
                            : ''
                        }
                        onBlur={validation.handleBlur}
                        countries
                        firmCountries
                        searchable
                      />
                      <TextArea
                        label="Practice Bio"
                        id="description"
                        name="description"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ''}
                        error={
                          validation.touched.description
                            ? validation.errors.description
                            : ''
                        }
                        placeholder=" "
                        rows="4"
                      />
                      <div>
                        <UploadFile
                          title="Logo"
                          onFilesAdded={(files) => {
                            setFiles((prevFiles) => [...prevFiles, ...files]);
                            if (files[0]) {
                              const filePreviewUrl = URL.createObjectURL(
                                files[0],
                              );
                              setCurrentImageForCrop(filePreviewUrl);
                              setCropModalOpen(true);
                              setImgName(files[0].name);
                            }
                          }}
                          name="logo"
                          id="logo"
                          imgPreview={imgPreview}
                          setImgPreview={setImgPreview}
                          files={files}
                          setFiles={setFiles}
                        />
                      </div>
                      <button
                        className="btn w-full py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
          disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && (
                          <svg
                            className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                          </svg>
                        )}
                        Finish
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* Auth Slider */}

        <AuthSlider />
      </div>
    </main>
  );
};

export default AccountantFirmForm;
