import React, { useEffect, useState } from 'react';
import SearchForm from 'partials/actions/SearchForm';
import Countries from '../pages/accountantFirmForm/data/countries.json';
import DropdownFull from './DropdownFull';
import { searchAddressAPI } from '../API/backend_helper';

let searchTimeout = null;

const AddressBar = ({
  validation,
  disabled = false,
  smallView = false,

  streetId = '',
  street2Id = '',
  cityId = '',
  stateId = '',
  zipCodeId = '',
  countryId = '',

  streetValue = '',
  street2Value = '',
  cityValue = '',
  stateValue = '',
  zipCodeValue = '',
  countryValue = '',

  streetError = '',
  street2Error = '',
  cityError = '',
  stateError = '',
  zipCodeError = '',
  countryError = '',
}) => {
  const [searchVal, setSearchVal] = useState('');
  const [dropdownCountries, setDropdownCountries] = useState([]);

  useEffect(() => {
    if (Countries?.length > 0) {
      setDropdownCountries([...Countries]);
    }
  }, [Countries]);

  useEffect(() => {
    if (
      countryValue?.length === 2 &&
      Countries?.length > 0 &&
      validation?.setFieldValue
    ) {
      const country = Countries?.find(
        (c) => c?.code === countryValue?.toUpperCase(),
      );
      validation?.setFieldValue(countryId, country?.name);
    }
  }, [Countries, validation]);

  const handleCountrySearch = (value, selectedCountryId) => {
    if (Countries?.length > 0) {
      const tempCountries = [...Countries];
      const filteredCountries = tempCountries?.filter((country) =>
        country?.name?.toLowerCase()?.includes(value?.toLowerCase()),
      );
      const selectedCountry = filteredCountries?.filter(
        (country) => country?.name === selectedCountryId,
      );
      if (!selectedCountryId || selectedCountry?.length > 0) {
        setDropdownCountries(filteredCountries);
      } else {
        const selectedCountry = Countries?.find(
          (country) => country?.name === selectedCountryId,
        );
        setDropdownCountries([selectedCountry, ...filteredCountries]);
      }
    }
  };

  const resetCountrySearch = () => {
    if (Countries?.length > 0) {
      setDropdownCountries([...Countries]);
    }
  };

  const getAddresses = async (value) => {
    try {
      const res = await searchAddressAPI(value);
      console.log('res', res);
    } catch (err) {
      console.log(err);
    }
  };

  const onSearch = async (value) => {
    setSearchVal(value);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getAddresses(value);
    }, 500);
  };

  return (
    <>
      {/* <div>
        <SearchForm
          placeholder="Search Address"
          searchVal={searchVal}
          setSearchVal={onSearch}
          transaction
        />
      </div> */}
      {!smallView ? (
        <div className="grid grid-cols-7 gap-2 w-full h-auto xl:h-12 border border-[#D0D5DD] py-2 xl:py-0 rounded-[0.313rem]  bg-white shadow-sm px-4 xl:px-0">
          <div className="col-span-full xl:col-span-3 gap-2 grid grid-cols-1 md:grid-cols-2 h-28 md:h-14 xl:h-auto border-b border-x-0 border-t-0 xl:border-none border-[#D0D5DD]">
            <div className="relative col-span-1 flex  items-center border-b md:border-none border-[#D0D5DD]">
              <input
                id={streetId}
                name={streetId}
                placeholder="Address"
                value={streetValue || ''}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                className={`form-input px-4  border-[#D0D5DD] w-full items-center placeholder:text-slate-600 placeholder:text-opacity-80 
                     placeholder:leading-6  border-l-[1.5px] border-r-0 border-y-0 md:border-none shadow-none
                     ${streetError ? '' : ''}`}
                disabled={disabled}
              />
              {streetError && (
                <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                  {streetError}
                </p>
              )}
            </div>
            <div className="relative col-span- flex items-center">
              <input
                id={street2Id}
                name={street2Id}
                placeholder="Address 2 (Optional)"
                value={street2Value || ''}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                className={`form-input w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-y-0 shadow-none
                      border-r-0 border-l-[1.5px] xl:border-x-[1.5px] border-[#D0D5DD]
                      ${street2Error ? '' : ''}`}
                disabled={disabled}
              />
              {street2Error && (
                <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                  {street2Error}
                </p>
              )}
            </div>
          </div>
          <div className="col-span-full xl:col-span-4 grid grid-cols-2 md:grid-cols-4 md:gap-2 h-28  md:h-14 xl:h-auto ">
            <div className="relative flex items-center border-b md:border-none border-[#D0D5DD]">
              <input
                id={cityId}
                name={cityId}
                placeholder="City"
                value={cityValue || ''}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                className={`form-input border-[#D0D5DD] w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 
                      placeholder:leading-6   border-l-[1.5px]  border-r-0 border-y-0 md:border-none shadow-none
                      ${cityError ? '' : ''}`}
                disabled={disabled}
              />
              {cityError && (
                <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                  {cityError}
                </p>
              )}
            </div>
            <div className="relative flex items-center border-b md:border-none">
              <input
                id={stateId}
                name={stateId}
                placeholder="State"
                value={stateValue || ''}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                className={`form-input border-[#D0D5DD] w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6  shadow-none
          border-l-[1.5px]  border-r-0 md:border-x-[1.5px] border-y-0 
             ${stateError ? '' : ''}`}
                disabled={disabled}
              />
              {stateError && (
                <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                  {stateError}
                </p>
              )}
            </div>
            <div className="relative flex items-center">
              <input
                id={zipCodeId}
                name={zipCodeId}
                placeholder="Postal"
                value={zipCodeValue || ''}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                className={`form-input border-[#D0D5DD] w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 
                     placeholder:leading-6   border-l-[1.5px]  border-r-0 border-y-0 md:border-none shadow-none
                       ${zipCodeError ? '' : ''}`}
                disabled={disabled}
              />
              {zipCodeError && (
                <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                  {zipCodeError}
                </p>
              )}
            </div>
            <div className="relative flex items-center">
              <div
                style={
                  countryValue
                    ? { width: 'calc(100% - 20px)' }
                    : { width: '100%' }
                }
              >
                <DropdownFull
                  options={dropdownCountries}
                  selected={countryValue}
                  setSelected={(country) => {
                    validation.setFieldValue(countryId, country?.name);
                  }}
                  name="name"
                  scrollIntoView={false}
                  search
                  height="h-[39px] !rounded-[0px] border-l-[1px] !border-[#D0D5DD] hover:border-[#D0D5DD]   border-y-0 border-r-0 pl-2 !pr-2 !py-1 w-full"
                  buttonTextClassSub={`${countryValue ? '!text-[#1e293b]' : ''} `}
                  flyout
                  placeholder="Country"
                  handleSearch={(value) =>
                    handleCountrySearch(value, countryValue)
                  }
                  resetSearch={resetCountrySearch}
                  searchPlaceholder="Type To Search"
                  noMinW
                  className="!h-fit"
                  countries
                  flagKey="emoji"
                  selectFullObj
                  accounts
                />
              </div>
              {countryValue && (
                <button
                  className="mr-1 group"
                  type="button"
                  onClick={() => validation.setFieldValue(countryId, '')}
                >
                  <svg
                    className="w-[14px] h-[14px] fill-[#667085cc] group-hover:fill-slate-800 pointer-events-none"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                  </svg>
                </button>
              )}

              {countryError && (
                <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                  {countryError}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4 w-full">
          <div className="grid grid-cols-7 gap-2 w-full md:h-12 border border-[#D0D5DD] py-0 rounded-[0.313rem]  bg-white shadow-sm px-4 xl:px-0">
            <div className="col-span-full gap-2 grid grid-cols-1 md:grid-cols-2 h-28 md:h-auto border-[#D0D5DD]">
              <div className="relative col-span-1 flex  items-center border-b md:border-none border-[#D0D5DD]">
                <input
                  id={streetId}
                  name={streetId}
                  placeholder="Address"
                  value={streetValue || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className={`form-input px-4  border-[#D0D5DD] w-full items-center placeholder:text-slate-600 placeholder:text-opacity-80 
                     placeholder:leading-6  border-l-[1.5px] border-r-0 border-y-0 md:border-none shadow-none
                     ${streetError ? '' : ''}`}
                  disabled={disabled}
                />
                {streetError && (
                  <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                    {streetError}
                  </p>
                )}
              </div>
              <div className="relative col-span- flex items-center">
                <input
                  id={street2Id}
                  name={street2Id}
                  placeholder="Address 2 (Optional)"
                  value={street2Value || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className={`form-input w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-y-0 shadow-none
                      border-r-0 border-l-[1.5px]  border-[#D0D5DD]
                      ${street2Error ? '' : ''}`}
                  disabled={disabled}
                />
                {street2Error && (
                  <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                    {street2Error}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-2 w-full md:h-12 border border-[#D0D5DD] py-0 rounded-[0.313rem]  bg-white shadow-sm px-4 xl:px-0">
            <div className="col-span-full gap-2 grid grid-cols-1 md:grid-cols-2 h-28 md:h-auto border-[#D0D5DD] ">
              <div className="relative flex items-center border-b md:border-none border-[#D0D5DD]">
                <input
                  id={cityId}
                  name={cityId}
                  placeholder="City"
                  value={cityValue || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className={`form-input border-[#D0D5DD] w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 
                      placeholder:leading-6   border-l-[1.5px]  border-r-0 border-y-0 md:border-none shadow-none
                      ${cityError ? '' : ''}`}
                  disabled={disabled}
                />
                {cityError && (
                  <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                    {cityError}
                  </p>
                )}
              </div>
              <div className="relative flex items-center">
                <input
                  id={stateId}
                  name={stateId}
                  placeholder="State"
                  value={stateValue || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className={`form-input border-[#D0D5DD] w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6  shadow-none
          border-l-[1.5px]  border-r-0 md:border-l-[1.5px] border-y-0 
             ${stateError ? '' : ''}`}
                  disabled={disabled}
                />
                {stateError && (
                  <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                    {stateError}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-2 w-full md:h-12 border border-[#D0D5DD] py-0 rounded-[0.313rem]  bg-white shadow-sm px-4 xl:px-0">
            <div className="col-span-full gap-2 grid grid-cols-1 md:grid-cols-2 h-28 md:h-auto border-[#D0D5DD]">
              <div className="relative flex items-center border-b md:border-none border-[#D0D5DD]">
                <input
                  id={zipCodeId}
                  name={zipCodeId}
                  placeholder="Postal"
                  value={zipCodeValue || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className={`form-input border-[#D0D5DD] w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 
                     placeholder:leading-6   border-l-[1.5px]  border-r-0 border-y-0 md:border-none shadow-none
                       ${zipCodeError ? '' : ''}`}
                  disabled={disabled}
                />
                {zipCodeError && (
                  <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                    {zipCodeError}
                  </p>
                )}
              </div>
              <div className="relative flex items-center">
                <div
                  style={
                    countryValue
                      ? { width: 'calc(100% - 20px)' }
                      : { width: '100%' }
                  }
                >
                  <DropdownFull
                    options={dropdownCountries}
                    selected={countryValue}
                    setSelected={(country) => {
                      validation.setFieldValue(countryId, country?.name);
                    }}
                    name="name"
                    scrollIntoView={false}
                    search
                    height="h-[39px] !rounded-[0px] border-l-[1px] !border-[#D0D5DD] hover:border-[#D0D5DD]   border-y-0 border-r-0 pl-2 !pr-2 !py-1 w-full"
                    buttonTextClassSub={`${countryValue ? '!text-[#1e293b]' : ''} `}
                    flyout
                    placeholder="Country"
                    handleSearch={(value) =>
                      handleCountrySearch(value, countryValue)
                    }
                    resetSearch={resetCountrySearch}
                    searchPlaceholder="Type To Search"
                    noMinW
                    className="!h-fit"
                    countries
                    flagKey="emoji"
                    selectFullObj
                    accounts
                  />
                </div>
                {countryValue && (
                  <button
                    className="mr-1 group"
                    type="button"
                    onClick={() => validation.setFieldValue(countryId, '')}
                  >
                    <svg
                      className="w-[14px] h-[14px] fill-[#667085cc] group-hover:fill-slate-800 pointer-events-none"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                    </svg>
                  </button>
                )}

                {countryError && (
                  <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                    {countryError}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddressBar;
