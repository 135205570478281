import React, { useMemo, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getFormattedDate } from 'utils/Utils';

function DateFilter({
  align,
  setStartDateFilter = () => {},
  setEndDateFilter = () => {},
  startDateFilter,
  endDateFilter,
  onDateChange,
  resetToday = false,
  mode = 'range',
  report = false,
  maxDate = new Date(),
}) {
  const { team } = useSelector((state) => state.Team);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const options = useMemo(
    () => ({
      mode,
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      maxDate,
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onReady: (selectedDates, dateStr, instance) => {
        const customClass = align || '';
        instance.calendarContainer.classList.add(`flatpickr-${customClass}`);
      },
      onOpen: (selectedDates, dateStr, instance) => {
        setDropdownOpen(true);
      },
      onClose: () => {
        setDropdownOpen(false);
      },
      onChange: (selectedDates, dateStr, instance) => {
        if (selectedDates?.length > 0) {
          setStartDateFilter(selectedDates?.[0]);
          setEndDateFilter(selectedDates?.[1]);
          onDateChange(selectedDates?.[0], selectedDates?.[1]);
        }
      },
    }),
    [team],
  );

  return (
    <div className="relative md:ml-auto h-10 w-full">
      <Flatpickr
        className={` form-input !border-[#D0D5DD] hover:!border-slate-400 pl-3 pr-12
         !text-sm !leading-6 py-0 h-10 cursor-default placeholder:text-[#667085CC] text-[#667085CC] -mb-[5px]
          hover:text-slate-600  w-full min-w-[200px]
          ${
            startDateFilter || endDateFilter
              ? '!border-indigo-500   placeholder:!text-indigo-500 !text-indigo-500 bg-[#E4864205]'
              : ''
          }
          ${report ? '!text-base' : 'text-sm'}

          `}
        options={options}
        placeholder="Select Date Range"
        value={[startDateFilter, endDateFilter]}
        autoComplete="off"
      />
      <div className="absolute inset-0 right-3 left-[unset] flex items-center pointer-events-none">
        <svg
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`shrink-0 ml-1 text-[#667085CC] stroke-[#667085CC] ${
            startDateFilter || endDateFilter
              ? ' text-indigo-500 stroke-indigo-500'
              : ''
          }  ${dropdownOpen ? 'rotate-180' : ''} `}
        >
          <path
            d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="stroke-current"
          />
        </svg>
      </div>
      {(startDateFilter || endDateFilter) &&
        (!resetToday ||
          (resetToday &&
            getFormattedDate(startDateFilter) !==
              moment().format('YYYY-MM-DD'))) && (
          <button
            onClick={() => {
              if (resetToday) {
                setStartDateFilter(new Date());
                setEndDateFilter('');
                onDateChange('', '');
              } else {
                setStartDateFilter('');
                setEndDateFilter('');
                onDateChange('', '');
              }
            }}
            className="absolute top-0 right-5  h-full group p-2 flex items-center"
            type="button"
          >
            <svg
              className="w-4 h-4 fill-indigo-300 group-hover:fill-indigo-500 pointer-events-none"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
            </svg>
          </button>
        )}
    </div>
  );
}

export default DateFilter;
