/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState, useMemo, useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getAccounts,
  createIncomeCustomerReport,
} from '../../../../API/backend_helper';
import {
  dateCategoryList,
  transformAccounts,
  VIEWER,
  formatValueWithSign,
  getFormattedDate,
} from '../../../../utils/Utils';
import CategoriesDropdown from '../../../transactions/CategoriesDropdown';
import DateFilter from '../../../transactions/DateFilter';
import ReportTitleModal from '../../ReportTitleModal';
import DropdownFull from '../../../../components/DropdownFull';
import { Loader } from '../../../../components/Svg';
import AppLayout from '../../../../components/AppLayout';

const tableHeaders = {
  customer: 'Customer',
  overdue: 'Overdue',
  paid: 'Paid',
  total: 'Total',
};

function CustomerIncome() {
  const formikRef = useRef();
  const navigate = useNavigate();
  const { accessType } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [loading, setLoading] = useState(false);
  const [accountConnections, setAccountConnections] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [values, setValues] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [hasGenerated, setHasGenerated] = useState(false);
  const [reportTitle, setReportTitle] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(team?.currency);
  const dateFormats = useMemo(() => dateCategoryList(), []);
  const [searchParams] = useSearchParams();
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [generatedReport, setGeneratedReport] = useState([]);
  const [generatedTotals, setGeneratedTotals] = useState({});
  const startDate = searchParams.get('startDate');
  const accountId = searchParams.get('accountId');
  const endDate = searchParams.get('endDate');
  const currency = searchParams.get('currency');
  const [currencySymbol, setCurrencySymbol] = useState('');

  useEffect(() => {
    if (selectedCurrency && currencies) {
      setCurrencySymbol(
        currencies?.find?.((c) => c.isoCode === selectedCurrency)?.symbol,
      );
    }
  }, [generatedReport]);

  useEffect(() => {
    setHasGenerated(false);
    loadData();
  }, []);

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      if (values?.save) {
        setModalOpen(true);
      } else {
        handleSubmit();
      }
    }
  }, [values, submitTrigger]);

  useEffect(() => {
    if (startDate && endDate) {
      handleSubmit({
        firstStartDate: startDate,
        firstEndDate: endDate,
        currency,
      });
      setSelectedCurrency(currency);
    }
  }, [startDate, endDate, accountId, currency]);

  const loadData = async () => {
    setLoading(true);
    try {
      const accountsRes = await getAccounts();
      setAccountConnections(transformAccounts(accountsRes, 'allAccounts'));
      if (!currency) {
        setSelectedCurrency(team?.currency);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (paramValues = {}) => {
    try {
      const incomeRes = await createIncomeCustomerReport({
        startDate: getFormattedDate(
          paramValues.firstStartDate || values.firstStartDate,
        ),
        endDate: getFormattedDate(paramValues.firstEndDate || values.firstEndDate),
        save: !!values?.save,
        reportTitle,
        currency: paramValues.currency || selectedCurrency,
      });
      setGeneratedReport([...incomeRes?.data?.customers]);
      setGeneratedTotals({
        totalIncome: incomeRes?.data?.totals?.totalIncome,
        totalOverdue: incomeRes?.data?.totals?.totalOverdue,
        totalPaid: incomeRes?.data?.totals?.totalPaid,
      });
      setHasGenerated(true);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setModalOpen(false);
      setGenerating(false);
      setIsSubmit(false);
    }
  };

  const formikSubmit = async (values) => {
    setHasGenerated(true);
    setGenerating(true);
    setSubmitTrigger(!submitTrigger);
    setValues(values);
  };

  const handleClose = () => {
    setModalOpen(false);
    setGenerating(false);
    setIsSubmit(false);
  };

  const downloadCSV = (csv, filename) => {
    const csvFile = new Blob([csv], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const exportToCSV = () => {
    const formatDate = (date) => moment(date).format('MMM DD, YYYY');

    const csvData = [];

    csvData.push(['Income by Customer']);
    csvData.push([team?.name]);
    csvData.push([
      `${formatDate(values.firstStartDate)} to ${formatDate(
        values.firstEndDate,
      )}`,
    ]);

    csvData.push(['CUSTOMER', 'OVERDUE', 'PAID', 'TOTAL']);

    generatedReport.forEach((customer) => {
      csvData.push([
        customer.Customer,
        `${currencySymbol}${parseFloat(customer?.Overdue ?? 0)?.toFixed(2)}`,
        `${currencySymbol}${parseFloat(customer.Paid ?? 0)?.toFixed(2)}`,
        `${currencySymbol}${parseFloat(customer.Total ?? 0)?.toFixed(2)}`,
      ]);
    });
    csvData.push(['', '', '', '', '', '']);
    csvData.push([
      'Totals',
      `${currencySymbol}${parseFloat(generatedTotals?.totalOverdue ?? 0)?.toFixed(2)}`,
      `${currencySymbol}${parseFloat(generatedTotals?.totalPaid ?? 0)?.toFixed(2)}`,
      `${currencySymbol}${parseFloat(generatedTotals.totalIncome ?? 0)?.toFixed(2)}`,
    ]);
    const csvString = csvData
      .map((row) =>
        row
          .map((field) =>
            typeof field === 'string' && field.includes(',')
              ? `"${field}"`
              : field,
          )
          .join(','),
      )
      .join('\r\n');
    const filename = `Income by Customer ${moment(
      values?.firstStartDate,
    ).format('MMM DD YYYY')} to ${moment(values?.firstEndDate).format(
      'MMM DD YYYY',
    )} (${selectedCurrency}).csv`;

    downloadCSV(csvString, filename);
  };

  return (
    <>
      <ReportTitleModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        reportTitle={reportTitle}
        setReportTitle={setReportTitle}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
      <AppLayout>
        <main className="relative grow">
          {loading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <div className="px-4 sm:px-6 lg:px-8 py-5 w-full max-w-9xl mx-auto flex flex-col gap-5">
              <div className="flex  gap-5 flex-wrap items-center">
                <div
                  onClick={() => {
                    navigate('/reports');
                  }}
                  className="cursor-pointer"
                >
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="32"
                      height="33"
                      transform="translate(33) rotate(90)"
                      fill="#FBF3ED"
                    />
                    <path
                      d="M19 10.5L13 16.5L19 22.5"
                      stroke="#E48642"
                      strokeWidth="3.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <h1 className="text-3xl md:text-4xl text-black opacity-60  font-medium">
                  Income by Customer
                </h1>
              </div>
              <div className="flex py-5 px-7  flex-col  gap-1 flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize
                  initialValues={{
                    firstStartDate: '',
                    firstEndDate: '',
                    dateRange: dateFormats[0].categories[0].id || '',
                    title: '',
                    save: false,
                  }}
                  validationSchema={Yup.object({
                    firstStartDate: Yup.date().required(
                      'Please Select Start Date',
                    ),
                    firstEndDate: Yup.date().required('Please Select End Date'),
                  })}
                  onSubmit={isSubmit && formikSubmit}
                >
                  {(validation) => {
                    useEffect(() => {
                      if (validation.values.dateRange !== 'custom') {
                        const flatArray = dateFormats.flatMap(
                          (date) => date.categories,
                        );
                        const date =
                          flatArray.find(
                            (category) =>
                              category.id == validation.values.dateRange,
                          ) || 'custom';
                        const selectedDate =
                          startDate !== null &&
                          flatArray.find((category) => {
                            const start = getFormattedDate(category.startDate);
                            const end = getFormattedDate(category.endDate);
                            if (start == startDate && end == endDate) {
                              return true;
                            }
                            if (start.slice(0, -3) == startDate.slice(0, -3)) {
                              return true;
                            }
                            if (start.slice(0, 4) == startDate.slice(0, 4)) {
                              return true;
                            }
                          });
                        validation.setFieldValue(
                          'dateRange',
                          selectedDate?.id || validation.values?.dateRange,
                        );

                        validation.setFieldValue(
                          'firstStartDate',
                          startDate
                            ? moment(startDate).toISOString()
                            : date.startDate,
                        );

                        validation.setFieldValue(
                          'firstEndDate',
                          endDate
                            ? moment(endDate).toISOString()
                            : date.endDate,
                        );
                        setReportTitle(
                          `Income by Customer - ${moment(
                            validation.values.firstStartDate,
                          ).format('MMM DD, YYYY')} to ${moment(
                            validation.values.firstEndDate,
                          ).format('MMM DD, YYYY')} (${selectedCurrency})`,
                        );
                      }
                    }, [
                      validation.values.dateRange,
                      validation.values.accounts,
                      validation.values.firstStartDate,
                      validation.values.firstEndDate,
                    ]);

                    useEffect(() => {
                      setReportTitle(
                        `Income by Customer - ${moment(
                          validation.values.firstStartDate,
                        ).format('MMM DD, YYYY')} to ${moment(
                          validation.values.firstEndDate,
                        ).format('MMM DD, YYYY')} (${selectedCurrency})`,
                      );
                    }, [
                      validation.values.firstEndDate,
                      validation.values.firstStartDate,
                      selectedCurrency,
                    ]);

                    return (
                      <Form>
                        <div className="grid grid-cols-2 gap-3 w-3/5">
                          <div className="col-span-2">
                            <p className="py-2">
                              Date Range
                              <span className="text-rose-500 pl-1">*</span>
                            </p>
                            <div className="grid sm:grid-cols-5 gap-3 gap-y-0">
                              <div className="flex flex-wrap">
                                <div className="flex-1 max-w-[300px] mb-3 sm:mb-0">
                                  <CategoriesDropdown
                                    allCategories={[
                                      ...dateFormats,
                                      {
                                        name: 'Custom',
                                        categories: [
                                          {
                                            name: 'Custom',
                                            id: 'custom',
                                          },
                                        ],
                                      },
                                    ]}
                                    selectedCategoryId={
                                      validation.values.dateRange
                                    }
                                    setSelectedCategoryId={(value) => {
                                      validation.setFieldValue(
                                        'dateRange',
                                        value,
                                      );
                                    }}
                                    isSetCategoryStyle
                                    height="h-10"
                                  />
                                  {validation.touched.dateRange &&
                                  validation.errors.dateRange ? (
                                    <div className="text-xs mt-1 text-rose-500">
                                      {validation.errors.dateRange}
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              <div className="flex flex-wrap gap-y-0 col-span-2">
                                <div className="flex-1 max-w-[300px]">
                                  <DateFilter
                                    startDateFilter={
                                      validation.values.firstStartDate
                                    }
                                    endDateFilter={
                                      validation.values.firstEndDate
                                    }
                                    setStartDateFilter={(value) => {
                                      validation.setFieldValue(
                                        'firstStartDate',
                                        value,
                                      );
                                    }}
                                    setEndDateFilter={(value) => {
                                      validation.setFieldValue(
                                        'firstEndDate',
                                        value,
                                      );
                                    }}
                                    onDateChange={() => {
                                      validation.setFieldValue(
                                        'dateRange',
                                        'custom',
                                      );
                                    }}
                                    align="left"
                                    report
                                  />
                                  {(validation.touched.firstStartDate ||
                                    validation.touched.firstEndDate) &&
                                  (validation.errors.firstStartDate ||
                                    validation.errors.firstEndDate) ? (
                                    <div className="text-xs mt-1 text-rose-500">
                                      {validation.errors.firstStartDate}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1 flex gap-2 items-center">
                            <div className="flex-col">
                              <p className="pb-2">Currency</p>
                              <div className="flex-1 max-w-[300px] items-center h-10">
                                <DropdownFull
                                  selected={selectedCurrency}
                                  setSelected={setSelectedCurrency}
                                  apiFunc={{}}
                                  options={currencies.map((c) => ({
                                    label: c?.name,
                                    id: c?.isoCode,
                                    value: c?.isoCode,
                                    symbol: c?.symbol,
                                  }))}
                                  name="label"
                                  onClear={() => {
                                    setSelectedCurrency(team?.currency);
                                  }}
                                  label="Select Currency"
                                  type="tags"
                                  flyout
                                  tags
                                  apiFilter=""
                                  scrollIntoView={false}
                                  currency
                                />
                              </div>
                            </div>
                          </div>
                          {accessType !== VIEWER && (
                            <div className="col-span-2 mt-3 flex flex-col sm:flex-row w-full gap-2 items-center">
                              <button
                                disabled={generating}
                                onClick={(e) => {
                                  validation.setFieldValue('save', false);
                                  setIsSubmit(true);
                                }}
                                className="flex items-center mr-auto sm:mr-0 cursor-pointer justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap  bg-indigo-500 text-white"
                              >
                                Generate Report
                              </button>
                              <button
                                disabled={generating}
                                onClick={(e) => {
                                  validation.setFieldValue('save', true);
                                  setIsSubmit(true);
                                }}
                                className="flex items-center mr-auto sm:mr-0 cursor-pointer justify-center px-2 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap border border-indigo-500  text-indigo-500"
                              >
                                Generate & Save Report
                              </button>
                            </div>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>

              {!generatedReport.length > 0 || generating ? (
                <div className="flex py-16 px-7 flex-col gap-1 text-center flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                  {generating && (
                    <div className="flex items-center pb-2 justify-center mt-8">
                      <Loader />
                    </div>
                  )}
                  {!loading && !generating && (
                    <>
                      <div className="flex justify-center">
                        <svg
                          width="177"
                          height="155"
                          viewBox="0 0 177 155"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_2823_64411)">
                            <path
                              d="M48.9475 87.1651C72.3444 87.1651 91.3114 68.1982 91.3114 44.8013C91.3114 21.4044 72.3444 2.4375 48.9475 2.4375C25.5507 2.4375 6.58374 21.4044 6.58374 44.8013C6.58374 68.1982 25.5507 87.1651 48.9475 87.1651Z"
                              fill="#F9EAE0"
                            />
                            <path
                              d="M51.6384 141.316C51.0285 141.316 50.4276 141.169 49.8865 140.887C49.3453 140.606 48.8798 140.199 48.529 139.7L43.6594 132.732L162.034 58.0156L167.658 61.7104C168.187 62.0575 168.62 62.5314 168.918 63.0888C169.216 63.6462 169.37 64.2693 169.365 64.9014C169.361 65.5335 169.198 66.1544 168.892 66.7076C168.586 67.2607 168.147 67.7285 167.614 68.0682L53.6699 140.723C53.0631 141.11 52.3582 141.316 51.6384 141.316Z"
                              fill="white"
                            />
                            <path
                              d="M162.026 58.5765L167.399 62.1059C167.864 62.4057 168.246 62.8191 168.507 63.3072C168.769 63.7954 168.901 64.3421 168.893 64.8957C168.891 65.4501 168.749 65.9949 168.481 66.4801C168.213 66.9653 167.827 67.3753 167.359 67.6724L53.4176 140.33C52.6961 140.789 51.8253 140.952 50.9864 140.785C50.1476 140.619 49.4054 140.135 48.9144 139.435L44.3243 132.87L162.026 58.5765ZM162.036 57.4606L42.9897 132.599L48.1444 139.971C48.7741 140.87 49.7263 141.491 50.8027 141.705C51.8791 141.919 52.9966 141.71 53.9224 141.12L167.864 68.4647C168.463 68.0829 168.957 67.5572 169.301 66.9355C169.645 66.3138 169.828 65.6159 169.833 64.9055C169.838 64.195 169.665 63.4946 169.33 62.8681C168.995 62.2416 168.509 61.7089 167.915 61.3187L162.037 57.457L162.036 57.4606Z"
                              fill="#081F2D"
                            />
                            <path
                              d="M94.4027 10.1219C97.252 8.28492 100.657 7.51002 104.021 7.93316C107.384 8.35631 110.491 9.95048 112.797 12.4359L116.492 16.4188L25.6196 54.4691L94.4027 10.1219Z"
                              fill="white"
                            />
                            <path
                              d="M102.215 8.29063C104.137 8.29253 106.037 8.68979 107.799 9.45771C109.561 10.2256 111.145 11.3478 112.455 12.7544L115.692 16.2447L30.3452 51.9825L94.657 10.5179C96.9092 9.06308 99.5337 8.28966 102.215 8.29063ZM102.215 7.3507C99.3537 7.34996 96.5531 8.17552 94.1498 9.72815L20.8943 56.9585L117.296 16.5916L113.143 12.1157C111.749 10.6106 110.058 9.41004 108.178 8.58951C106.297 7.76897 104.267 7.34617 102.215 7.34766V7.3507Z"
                              fill="#081F2D"
                            />
                            <path
                              d="M42.9896 132.596L38.29 125.876C37.9543 125.396 37.7213 124.852 37.6055 124.278C37.4897 123.703 37.4937 123.111 37.6171 122.539C37.7406 121.966 37.9809 121.425 38.323 120.95C38.6651 120.474 39.1016 120.075 39.6053 119.776L145.216 57.0983C147.051 56.0093 149.098 55.3271 151.219 55.0979C153.34 54.8687 155.486 55.0978 157.511 55.7698L160.918 56.8999C161.683 57.1537 162.36 57.6194 162.871 58.2429C163.381 58.8663 163.704 59.6219 163.803 60.4218C163.901 61.2217 163.77 62.033 163.425 62.7614C163.08 63.4898 162.536 64.1053 161.855 64.5365L51.6376 134.333C50.2494 135.212 48.576 135.521 46.9654 135.198C45.3548 134.874 43.9308 133.942 42.9896 132.596Z"
                              fill="#78BD4F"
                            />
                            <path
                              d="M39.6054 119.778L15.239 77.3915C14.256 75.6816 13.6223 73.7935 13.3747 71.8368C13.1271 69.8801 13.2705 67.8937 13.7966 65.9929C14.3227 64.092 15.2209 62.3145 16.4393 60.7636C17.6577 59.2126 19.172 57.919 20.8942 56.9578L98.8604 13.4404C101.831 11.7821 105.28 11.1905 108.634 11.764C111.988 12.3375 115.045 14.0416 117.296 16.5929C128.319 29.0817 135.999 44.1592 139.62 60.4187L39.6054 119.778Z"
                              fill="#78BD4F"
                            />
                            <path
                              d="M130.107 66.0703C124.726 50.5333 116.459 36.1524 105.74 23.6836"
                              stroke="#081F2D"
                              strokeWidth="0.939413"
                              strokeMiterlimit="10"
                            />
                            <path
                              d="M124.152 69.6016C118.77 54.0647 110.503 39.6839 99.7852 27.2148"
                              stroke="#081F2D"
                              strokeWidth="0.939413"
                              strokeMiterlimit="10"
                            />
                            <path
                              d="M119.188 72.5469C113.807 57.0101 105.54 42.6293 94.8218 30.1602"
                              stroke="#081F2D"
                              strokeWidth="0.939413"
                              strokeMiterlimit="10"
                            />
                            <path
                              d="M77.843 97.0865C72.4613 81.5495 64.1942 67.1685 53.4761 54.6992"
                              stroke="#081F2D"
                              strokeWidth="0.939413"
                              strokeMiterlimit="10"
                            />
                            <path
                              d="M52.7817 117.98L57.4813 124.7"
                              stroke="#081F2D"
                              strokeWidth="0.939413"
                              strokeMiterlimit="10"
                            />
                            <path
                              d="M135.003 63.1602C138.493 64.4051 141.5 66.7228 143.592 69.7812"
                              stroke="#081F2D"
                              strokeWidth="0.939413"
                              strokeMiterlimit="10"
                            />
                            <path
                              d="M39.6055 119.779L139.62 60.4219"
                              stroke="#081F2D"
                              strokeWidth="0.939413"
                              strokeMiterlimit="10"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2823_64411">
                              <rect
                                width="176.013"
                                height="154.709"
                                fill="white"
                                transform="translate(0.493408)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>

                      <div className="self-stretch flex mt-6 flex-col justify-center gap-2  text-center text-slate-600 text-[1rem] leading-6 font-normal h-16">
                        {!hasGenerated ? (
                          <>
                            <p className=" text-[#101828] ">
                              Your report will appear here
                            </p>
                            <p className="text-[#667085]">
                              Simply, select date range, account and tags above
                              to generate report!
                            </p>
                          </>
                        ) : (
                          <p className=" text-[#101828] ">
                            No data found for the selected period
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="flex p-7 flex-col gap-1 flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                  <div className="flex justify-between">
                    <h1 className="text-2xl md:text-2xl text-black opacity-60  font-medium">
                      Report
                    </h1>
                    <div className="flex gap-3">
                      {/*
                        <button className="flex items-center justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap  border border-[#E48642]  text-[#E48642]">
                          Export as PDF
                        </button> */}
                      {accessType !== VIEWER && (
                        <button
                          className="flex items-center justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap  border border-[#E48642]  text-[#E48642]"
                          onClick={() => exportToCSV()}
                        >
                          Export as CSV
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="overflow-auto w-full">
                    <table className="table-fixed w-full dark:text-slate-300">
                      <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                        <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                          <th className="text-left max-w-[15rem] w-[15rem] font-bold pl-4">
                            <div className="">{tableHeaders.customer}</div>
                          </th>
                          <th className="max-w-[5rem] w-[5rem] ">
                            <div className="text-left text-wrap font-bold">
                              {tableHeaders.overdue}
                            </div>
                          </th>
                          <th className="max-w-[5rem] w-[5rem]">
                            <div className="text-left font-bold">
                              {tableHeaders.paid}
                            </div>
                          </th>
                          <th className="max-w-[5rem] w-[5rem]">
                            <div className="text-left font-bold">
                              {tableHeaders.total}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                        {generatedReport.map((customer) => (
                          <tr
                            key={customer?.id}
                            className="w-full px-[1.25rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5"
                          >
                            <td className="px-4  break-words">
                              <div className="text-left">
                                {customer.Customer}
                              </div>
                            </td>
                            <td className=" ">
                              <div className="text-left">
                                {formatValueWithSign(
                                  customer.Overdue || 0,
                                  false,
                                  currencySymbol,
                                )}
                              </div>
                            </td>
                            <td className=" ">
                              <div className="text-left">
                                {formatValueWithSign(
                                  customer.Paid || 0,
                                  false,
                                  currencySymbol,
                                )}
                              </div>
                            </td>
                            <td className=" font-bold">
                              <div className="text-left">
                                {formatValueWithSign(
                                  customer.Total || 0,
                                  false,
                                  currencySymbol,
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                        <tr className="w-full px-[1.25rem] bg-[#EEEEEE]  bg-opacity-30   h-16  hover:bg-opacity-15 text-[#101828] font-bold">
                          <td className="whitespace-nowrap px-4">
                            <div className="text-left ">Totals</div>
                          </td>
                          <td className="whitespace-nowrap">
                            <div className="text-left text-wrap">
                              {formatValueWithSign(
                                generatedTotals.totalOverdue || 0,
                                false,
                                currencySymbol,
                              )}
                            </div>
                          </td>
                          <td className="whitespace-nowrap">
                            <div className="text-left">
                              {formatValueWithSign(
                                generatedTotals.totalPaid || 0,
                                false,
                                currencySymbol,
                              )}
                            </div>
                          </td>
                          <td className="whitespace-nowrap">
                            <div className="text-left">
                              {formatValueWithSign(
                                generatedTotals.totalIncome || 0,
                                false,
                                currencySymbol,
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}
        </main>
      </AppLayout>
    </>
  );
}
export default CustomerIncome;
