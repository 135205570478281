import React, { useEffect, useState, useRef } from 'react';

import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  getAccountTypes,
  getAccountSubTypes,
  getAccounts,
  createPlaidToken,
  updateAccountById,
  getAccountSystemBalance,
  getUserSettings,
  updateUserSettings,
} from '../../API/backend_helper';
import AccoutDetails from './AccoutDetails';
import {
  ADMIN,
  OWNER,
  VIEWER,
  getCurrencySymbolFromIso,
  getStringSegments,
} from '../../utils/Utils';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import AccountChoiceModal from './AccountChoiceModal';
import AccountsModal from './AccountsModal';
import Tooltip from '../../components/Tooltip';
import emptyAccountsImg from '../../images/custom/empty-search.svg';
import BankWarning from '../../components/BankWarning';
import InstitutionsModal from './InstitutionsModal';
import SearchForm from '../../partials/actions/SearchForm';
import SimpleAccountModal from './SimpleAccountModal';
import PlaidLink from '../settings/PlaidLink';
import AkahuLink from '../settings/AkhahuLink/AkahuLink';
import AccountsTable from './AccountsTable';
import DeActivateModal from './DeActivateModal';
import AlertBox from '../../components/AlertBox';
import AppLayout from '../../components/AppLayout';

const AkahuLinkButton = () => (
  <button
    className="h-10 w-[9.688rem] bg-white border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-indigo-500 text-base leading-6 flex items-center justify-center gap-2 font-normal whitespace-nowrap"
    type="button"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className=" w-5 h-5 shrink-0"
    >
      <path
        d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
        stroke="#E48242"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    Connect Bank
  </button>
);

const Togglers = ({ onTogglerClick, isTableView }) => (
  <div className="flex items-center">
    {/* Table View  */}
    <button
      className="pr-2"
      onClick={() => onTogglerClick(false)}
      type="button"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-8 w-8 ${
          !isTableView
            ? 'stroke-indigo-500 fill-stroke-indigo-500 text-indigo-500 w-[20px] h-[20px]'
            : 'stroke-[#00000099] fill-stroke-[#00000099] text-[#00000099] w-[20px] h-[20px]'
        }  `}
      >
        <rect
          x="1"
          y="9"
          width="6"
          height="6"
          rx="2"
          stroke="#858D9D"
          strokeWidth="1.3"
          className="stroke-current"
        />
        <rect
          x="1"
          y="1"
          width="6"
          height="6"
          rx="2"
          stroke="#858D9D"
          strokeWidth="1.3"
          className="stroke-current"
        />
        <rect
          x="9"
          y="9"
          width="6"
          height="6"
          rx="2"
          stroke="#858D9D"
          strokeWidth="1.3"
          className="stroke-current"
        />
        <rect
          x="9"
          y="1"
          width="6"
          height="6"
          rx="2"
          stroke="#858D9D"
          strokeWidth="1.3"
          className="stroke-current"
        />
      </svg>
    </button>
    <div className="w-[1px] h-[18px] bg-indigo-500" />
    {/* Grid View  */}
    <button
      onClick={() => onTogglerClick(true)}
      className="pl-2 "
      type="button"
    >
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-8 w-8 ${
          isTableView
            ? 'stroke-indigo-500 fill-stroke-indigo-500 text-indigo-500  w-[20px] h-[20px]'
            : 'stroke-[#00000099] fill-stroke-[#00000099] text-[#00000099]  w-[20px] h-[20px]'
        }  `}
      >
        <path
          d="M1.75 3H1.7501"
          stroke="#858D9D"
          strokeWidth="2"
          strokeLinecap="round"
          className="stroke-current"
        />
        <path
          d="M4.25009 3H16.2501"
          stroke="#858D9D"
          strokeWidth="1.3"
          strokeLinecap="round"
          className="stroke-current"
        />
        <path
          d="M1.75 8H1.7501"
          stroke="#858D9D"
          strokeWidth="2"
          strokeLinecap="round"
          className="stroke-current"
        />
        <path
          d="M4.25009 8H16.2501"
          stroke="#858D9D"
          strokeWidth="1.3"
          strokeLinecap="round"
          className="stroke-current"
        />
        <path
          d="M1.75 13H1.7501"
          stroke="#858D9D"
          strokeWidth="2"
          strokeLinecap="round"
          className="stroke-current"
        />
        <path
          d="M4.25009 13H16.2501"
          stroke="#858D9D"
          strokeWidth="1.3"
          strokeLinecap="round"
          className="stroke-current"
        />
      </svg>
    </button>
  </div>
);

function Accounts() {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const createdAccounts = decodeURI(params.get('createdAccounts') || '');
  const skippedAccounts = decodeURI(params.get('skippedAccounts') || '');
  const totalAccounts = decodeURI(params.get('totalAccounts') || '');

  const PlaidButtonRef = useRef();
  const { type } = useParams();
  const { accessType } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [showImportSuccess, setShowImortSuccess] = useState(false);
  const [token, setToken] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountDetailsOpen, setAccountDetailsOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [editAccountModalOpen, setEditAccountModalOpen] = useState(false);
  const [choiceModalOpen, setChoiceModalOpen] = useState(false);
  const [accountsModalOpen, setAccountsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSubType, setSelectedSubType] = useState(null);
  const [institutionModalOpen, setInstitutionModalOpen] = useState(false);
  const [simpleModalOpen, setSimpleModalOpen] = useState(false);
  const [isTableView, setIsTableView] = useState(false);
  const [deActivateModalOpen, setDeActivateModalOpen] = useState(false);
  const [deActivateAccount, setDeActivateAccount] = useState({});
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [buttonToggler, setButtonToggler] = useState(false);
  const [accountsCountBalances, setAccountsCountBalances] = useState([]);
  const [countBalanceLoader, setCountBalanceLoader] = useState(false);

  const [deactivateAccLoader, setDeactivateAccLoader] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  // Reconcile Account

  const navigate = useNavigate();
  const location = useLocation();

  document.title = 'COUNT | Accounts';
  const filteredTypes = [];

  const onAccountTypeSelection = (
    acctType = filteredTypes,
    refreshData = false,
    accountsData = allAccounts,
  ) => {
    if (!refreshData) {
      setSelectedAccount(null);
      setAccountDetailsOpen(false);
    }
    accountsData?.reduce?.((result, item) => {
      if (acctType?.length === 0) {
        (result[item.type.subtype] = result[item.type.subtype] || []).push(
          item,
        );
      } else if (acctType?.includes?.(item.type.type)) {
        (result[item.type.subtype] = result[item.type.subtype] || []).push(
          item,
        );
      }
      return result;
    }, {});
  };

  const getAccountTypesApi = async () => {
    try {
      const typeResponse = await getAccountTypes();
      const subTypeResponse = await getAccountSubTypes();
      setTypes(typeResponse);
      setSubTypes(subTypeResponse);
    } catch (e) {
      console.log('error', e);
    }
  };

  const accountsToTransactions = (account) => {
    if (account?.subType?.anchorTier === 'PHYSICAL_ACCOUNT') {
      navigate(`/transactions?page=1&accountId=${account.id}`);
    } else {
      navigate(`/transactions?page=1&accountCategoryId=${account.id}`);
    }
  };

  const getAccountCountBalance = async (accountIds) => {
    try {
      const response = await getAccountSystemBalance(accountIds);
      setAccountsCountBalances(response);
      setCountBalanceLoader(false);
    } catch (error) {
      console.error(`Error fetching account system balance from :`, error);
      setCountBalanceLoader(false);
    }
  };

  const loadData = async (refreshData = false, inactive = showInactive) => {
    if (!refreshData) {
      setIsLoading(true);
    }
    if (showInactive !== inactive) {
      setShowInactive(inactive);
    }
    try {
      const res = await getAccounts({
        typeId: filteredTypes || [],
        search: searchVal,
        includeBalances: true,
        inactive,
      });
      if (refreshData) {
        onAccountTypeSelection([], refreshData, res);
      } else {
        onAccountTypeSelection([], false, res);
      }
      setAllAccounts(res);
      setIsLoading(false);
      if (accountsCountBalances?.length === 0 && res?.length > 0) {
        setCountBalanceLoader(true);
        const Ids = [];
        res?.forEach((acc) => acc?.status !== 'inactive' && Ids?.push(acc?.id));
        getAccountCountBalance(Ids);
      }
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const getAccountSystemBal = (id) => {
    if (accountsCountBalances?.length > 0) {
      const tempAcc = accountsCountBalances?.find(
        (acc) => acc?.accountId === id,
      );
      return tempAcc?.systemBalance;
    }
    return null;
  };

  const onBankClick = async () => {
    try {
      const plaidRes = await createPlaidToken(null, {}, '');
      const { linkToken } = plaidRes;
      setToken(linkToken);
      setTimeout(() => {
        PlaidButtonRef.current.click();
      }, 1000);
    } catch (e) {
      console.log('error', e);
    }
  };

  const deactivateAccount = async () => {
    if (!deActivateAccount?.id) {
      return;
    }
    setDeactivateAccLoader(true);
    try {
      await updateAccountById(deActivateAccount?.id, {
        status: deActivateAccount?.status === 'active' ? 'inactive' : 'active',
      });
      setDeactivateAccLoader(false);
      setDeActivateModalOpen(false);
      toast.success(
        `Account ${deActivateAccount?.status === 'active' ? 'deactivated' : 'activated'} successfully`,
      );
      setDeActivateAccount({});
      await loadData(false);
    } catch (e) {
      setDeactivateAccLoader(false);
      console.log('error', e);
    }
  };

  const updateAccountViewApi = async (viewType) => {
    try {
      await updateUserSettings({
        accountsView: viewType,
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  const onTogglerClick = (bool) => {
    if (bool !== isTableView) {
      setIsTableView(bool);
      updateAccountViewApi(bool ? 'list' : 'block');
    }
  };

  useEffect(() => {
    if (location?.state?.choiceModalOpen && !isLoading) {
      onBankClick();
    }
  }, [location, isLoading]);

  useEffect(() => {
    if (
      (createdAccounts || skippedAccounts || totalAccounts) &&
      !showImportSuccess
    ) {
      setShowImortSuccess(true);
      setTimeout(() => {
        setShowImortSuccess(false);
      }, 8000);
    }
  }, [createdAccounts, skippedAccounts, totalAccounts]);

  useEffect(() => {
    if (filteredTypes.length > 0) {
      onAccountTypeSelection(filteredTypes, false, allAccounts);
    } else {
      onAccountTypeSelection([], false, allAccounts);
    }
  }, []);

  useEffect(() => {
    if (!accountsModalOpen && !simpleModalOpen) {
      setIsEditing(false);
      setSelectedAccount(null);
      setSelectedSubType(null);
      setSelectedType(null);
    }
  }, [accountsModalOpen]);

  const getUserSettingsApi = async () => {
    try {
      const settingsRes = await getUserSettings();
      setIsTableView(settingsRes?.accountsView !== 'block');
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    getUserSettingsApi();
    getAccountTypesApi();
    setSearchVal('');
    loadData();
    setIsEditing(false);
    setSelectedAccount(null);
  }, []);

  useEffect(() => {
    if (type === 'add') {
      setChoiceModalOpen(true);
    }
  }, [type]);

  return (
    <>
      <InstitutionsModal
        isOpen={institutionModalOpen}
        setIsOpen={setInstitutionModalOpen}
        loadData={loadData}
        setIsLoading={setIsLoading}
        token={token}
        team={team}
      />
      <SimpleAccountModal
        modalOpen={simpleModalOpen}
        setModalOpen={setSimpleModalOpen}
        account={selectedAccount}
        loadData={loadData}
      />
      <AccountChoiceModal
        isOpen={choiceModalOpen}
        setIsOpen={setChoiceModalOpen}
        setAccountsModalOpen={setAccountsModalOpen}
        setInstitutionModalOpen={setInstitutionModalOpen}
      />
      <AccountsModal
        isOpen={accountsModalOpen}
        setIsOpen={setAccountsModalOpen}
        accountTypes={types}
        accountSubTypes={subTypes}
        loadData={loadData}
        account={selectedAccount}
        edit={isEditing}
        currencies={currencies}
        mainType={selectedType}
        subType={selectedSubType}
      />
      <DeActivateModal
        isOpen={deActivateModalOpen}
        setIsOpen={setDeActivateModalOpen}
        deactivateAccount={deactivateAccount}
        account={deActivateAccount}
        loading={deactivateAccLoader}
        setLoading={setDeactivateAccLoader}
        team={team}
      />
      <AppLayout>
        {accessType && (
          <main className="relative grow">
            {/* Content */}
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
              <BankWarning teamProp={team} />
              {showImportSuccess && (
                <AlertBox
                  type="success"
                  heading="Accounts Imported Successfully"
                  text={`Created Accounts : ${createdAccounts} |
              Skipped Accounts : ${skippedAccounts} |
              Total Accounts : ${totalAccounts}
              `}
                  hideActionButton
                />
              )}
              {/* Page header */}
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-5">
                <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem] mb-4 sm:mb-0">
                  Chart of Accounts
                </h1>
                <div className="flex flex-col sm:flex-row sm:justify-end sm:items-start gap-4">
                  <SearchForm
                    placeholder="Search"
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                    transaction
                  />
                  {/* Filters */}
                  {(accessType === OWNER || accessType === ADMIN) && (
                    <>
                      {team?.country === 'NZ' ? (
                        <AkahuLink ButtonUI={AkahuLinkButton} />
                      ) : (
                        <Tooltip
                          content={`Live bank feeds for ${team?.country || ''} is currently not supported. Manually import transactions instead.`}
                          contentClassName="border-none w-[250px] text-wrap overflow-visible text-sm text-[#667085] relative"
                          position="left"
                          size="sm"
                          tooltipShow={
                            team?.currency !== 'USD' && team?.currency !== 'CAD'
                          }
                        >
                          <button
                            className="h-10 w-[9.688rem] bg-white border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-indigo-500 text-base leading-6 flex items-center justify-center gap-2 font-normal whitespace-nowrap"
                            onClick={() => {
                              // setInstitutionModalOpen(true);
                              onBankClick();
                            }}
                            disabled={
                              team?.currency !== 'USD' &&
                              team?.currency !== 'CAD'
                            }
                            type="button"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className=" w-5 h-5 shrink-0"
                            >
                              <path
                                d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                                stroke="#E48242"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Connect Bank
                          </button>
                        </Tooltip>
                      )}
                      <div>
                        <div className="h-10 flex relative">
                          <button
                            className={`flex gap-2 items-center justify-center rounded-tl-[5px]
                                ${
                                  !buttonToggler ? 'rounded-bl-[5px]' : ''
                                }  h-full px-2 border border-indigo-500  bg-indigo-500 text-white disabled:bg-indigo-400`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setAccountsModalOpen(true);
                            }}
                            type="button"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              className=""
                            >
                              <path
                                d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                                stroke="white"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Add Account
                          </button>
                          <button
                            onClick={() => setButtonToggler(!buttonToggler)}
                            className={`rounded-tr-[5px] ${
                              !buttonToggler ? 'rounded-br-[5px]' : ''
                            }  h-full px-2 border border-l-[#FFFFFFCC] border-indigo-500  bg-indigo-500 text-white`}
                            type="button"
                          >
                            <svg
                              className={`shrink-0 ml-1 text-white  ${
                                buttonToggler ? 'rotate-180' : ''
                              }`}
                              width="10"
                              height="6"
                              viewBox="0 0 10 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                                stroke="#ffffff"
                                strokeOpacity="0.8"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                        {buttonToggler && (
                          <button
                            onClick={() => navigate('/import-accounts')}
                            className="absolute  w-[168px] rounded-bl-[5px] rounded-br-[5px] h-10 border border-t-[#FFFFFFCC] border-indigo-500  bg-indigo-500 text-white"
                            type="button"
                          >
                            Import Accounts
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="flex justify-end mb-5">
                <button
                  className="h-8 px-3 border border-indigo-500 text-indigo-500 rounded-[5px] inline-flex gap-2 items-center  whitespace-nowrap text-[14px]"
                  onClick={() => {
                    loadData(false, !showInactive);
                  }}
                  type="button"
                >
                  <input
                    type="checkbox"
                    checked={showInactive}
                    className="form-checkbox h-5 w-5"
                  />
                  Show Inactive Accounts
                </button>
              </div>
              {isLoading ? (
                <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                  <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin w-10 h-10 fill-current shrink-0"
                  >
                    <path
                      d="M14.5 2.41699V7.25033"
                      stroke="#E48642"
                      strokeWidth="2.55882"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.5 21.749V26.5824"
                      stroke="#E48642"
                      strokeWidth="2.55882"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.95703 5.95703L9.37662 9.37662"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.623 19.6211L23.0426 23.0407"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.41699 14.5H7.25033"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.749 14.5H26.5824"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.95703 23.0407L9.37662 19.6211"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.623 9.37662L23.0426 5.95703"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                    Fetching data, please wait...
                  </p>
                </div>
              ) : isTableView ? (
                <>
                  <div className="max-w-full flex items-center justify-between">
                    <div className="w-[400px]">
                      <BasicCheckboxDropdown
                        selectedOptions={selectedTypes}
                        setSelectedOptions={setSelectedTypes}
                        options={types.map((t) => ({
                          label: t?.name,
                          value: t?.name,
                        }))}
                        onClear={() => {
                          setSelectedTypes([]);
                        }}
                        label="Choose Types"
                        apiFilter={selectedTypes}
                        inbox
                      />
                    </div>
                    <Togglers
                      onTogglerClick={onTogglerClick}
                      isTableView={isTableView}
                    />
                  </div>
                  <AccountsTable
                    accountsToTransactions={accountsToTransactions}
                    allAccounts={allAccounts}
                    setIsEditing={setIsEditing}
                    setSelectedAccount={setSelectedAccount}
                    setAccountsModalOpen={setAccountsModalOpen}
                    setSimpleModalOpen={setSimpleModalOpen}
                    setDeActivateModalOpen={setDeActivateModalOpen}
                    setDeActivateAccount={setDeActivateAccount}
                    searchVal={searchVal}
                    selectedTypes={selectedTypes}
                    currencies={currencies}
                    getAccountSystemBal={getAccountSystemBal}
                    countBalanceLoader={countBalanceLoader}
                    team={team}
                  />
                </>
              ) : (
                <>
                  {/* Tabs */}
                  <div className="flex items-start justify-between mt-2.5">
                    <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-1">
                      <div
                        onClick={() => setActiveTab('all')}
                        className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === 'all' &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          } `}
                        tabIndex="0"
                        role="button"
                        onKeyDown={null}
                      >
                        All Accounts
                      </div>
                      {types?.map((type, index) => (
                        <div
                          key={index}
                          onClick={() => setActiveTab(type?.name)}
                          className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === type?.name &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          } `}
                          tabIndex="0"
                          role="button"
                          onKeyDown={null}
                        >
                          {type?.name}
                        </div>
                      ))}
                    </div>
                    <div className="pt-0">
                      <Togglers
                        onTogglerClick={onTogglerClick}
                        isTableView={isTableView}
                      />
                    </div>
                  </div>
                  {/* Table */}
                  <div className="flex flex-col items-start h-full">
                    {(activeTab === 'all'
                      ? types
                          .map((type) => ({
                            ...type,
                            subTypes: [...type.subTypes].reverse(),
                          }))
                          .flatMap((type) => type.subTypes)
                      : [
                          ...types.find((type) => type.name === activeTab)
                            ?.subTypes,
                        ].reverse()
                    )
                      .filter(
                        (subType) =>
                          !searchVal ||
                          subType.name
                            .toLowerCase()
                            .includes(searchVal.toLowerCase()) ||
                          allAccounts.some(
                            (account) =>
                              account.subTypeId === subType.id &&
                              account.name
                                .toLowerCase()
                                .includes(searchVal.toLowerCase()),
                          ),
                      )
                      .map((subType, index) => (
                        <div
                          key={index}
                          className="flex w-full px-[1.375rem] py-[1.563rem] flex-col justify-center items-start gap-[1.375rem]"
                        >
                          <div
                            key={subType?.id}
                            className="flex flex-col justify-center items-start self-stretch"
                          >
                            <div className="flex pl-3 py-3.5 items-center gap-2.5 self-stretch rounded bg-[#F4F4F5] ">
                              <p className="text-[#535C6E] text-[1.125rem] font-semibold leading-6">
                                {subType?.name}
                              </p>
                              <Tooltip
                                content={
                                  subType?.description?.length > 50
                                    ? (() => {
                                        const segmentLength = 60;
                                        const segments = [];
                                        let lastIndex = 0;

                                        while (
                                          lastIndex <
                                          subType?.description.length
                                        ) {
                                          let endIndex = Math.min(
                                            lastIndex + segmentLength,
                                            subType?.description.length,
                                          );
                                          if (
                                            endIndex <
                                              subType?.description.length &&
                                            subType?.description[endIndex] !==
                                              ' '
                                          ) {
                                            const lastSpaceIndex =
                                              subType?.description.lastIndexOf(
                                                ' ',
                                                endIndex,
                                              );
                                            endIndex =
                                              lastSpaceIndex > lastIndex
                                                ? lastSpaceIndex
                                                : endIndex;
                                          }

                                          segments.push(
                                            subType?.description.substring(
                                              lastIndex,
                                              endIndex,
                                            ),
                                          );
                                          lastIndex = endIndex + 1;
                                        }

                                        return segments.map(
                                          (segment, index) => (
                                            <p key={index}>{segment}</p>
                                          ),
                                        );
                                      })()
                                    : subType?.description
                                }
                                contentClassName="border-none overflow-visible text-sm text-[#667085] relative left-[4rem]"
                                tooltipShow={subType?.description}
                              >
                                <svg
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="1"
                                    width="19"
                                    height="19"
                                    rx="5.5"
                                    fill="white"
                                  />
                                  <rect
                                    x="0.5"
                                    y="1"
                                    width="19"
                                    height="19"
                                    rx="5.5"
                                    stroke="#D0D5DD"
                                  />
                                  <path
                                    d="M10.0005 15.21C12.6015 15.21 14.71 13.1015 14.71 10.5005C14.71 7.89952 12.6015 5.79102 10.0005 5.79102C7.39952 5.79102 5.29102 7.89952 5.29102 10.5005C5.29102 13.1015 7.39952 15.21 10.0005 15.21Z"
                                    stroke="#667085"
                                    strokeWidth="0.784912"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M9.60938 10.1094C9.71346 10.1094 9.81328 10.1507 9.88688 10.2243C9.96048 10.2979 10.0018 10.3977 10.0018 10.5018V12.4641C10.0018 12.5682 10.0432 12.668 10.1168 12.7416C10.1904 12.8152 10.2902 12.8566 10.3943 12.8566"
                                    stroke="#667085"
                                    strokeWidth="0.784912"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M9.80353 8.93127C10.1286 8.93127 10.3922 8.66771 10.3922 8.34259C10.3922 8.01747 10.1286 7.75391 9.80353 7.75391C9.47841 7.75391 9.21484 8.01747 9.21484 8.34259C9.21484 8.66771 9.47841 8.93127 9.80353 8.93127Z"
                                    fill="#667085"
                                  />
                                </svg>
                              </Tooltip>
                            </div>
                            {allAccounts?.filter(
                              (account) =>
                                account?.subTypeId === subType?.id &&
                                account?.name
                                  .toLowerCase()
                                  .includes(searchVal.toLowerCase()),
                            ).length > 0 ? (
                              allAccounts
                                .filter(
                                  (account) =>
                                    account?.subTypeId === subType?.id &&
                                    account?.name
                                      .toLowerCase()
                                      .includes(searchVal.toLowerCase()),
                                )
                                .map((account, key) => (
                                  <div
                                    key={key}
                                    className="grid grid-cols-12 px-[1.688rem] items-center gap-[1.375rem] py-[1.375rem]  self-stretch hover:bg-[#a0cd85] hover:bg-opacity-5 border-b border-[#D0D5DD]"
                                  >
                                    <div
                                      className={`col-span-3 flex flex-col justify-center items-start min-w-fit ${
                                        account?.officialName !== account?.name
                                          ? 'gap-3'
                                          : ''
                                      }`}
                                    >
                                      <div className="flex items-center gap-2">
                                        {account?.institution?.logoUrl && (
                                          <img
                                            src={account?.institution?.logoUrl}
                                            className="w-6 h-6 rounded-full"
                                            alt="institute-logo"
                                          />
                                        )}
                                        <Tooltip
                                          tooltipShow={
                                            account?.name?.length > 30
                                          }
                                          content={
                                            account?.name?.length > 30
                                              ? (() => {
                                                  const segments =
                                                    getStringSegments(
                                                      account?.name,
                                                      40,
                                                    );
                                                  return segments.map(
                                                    (segment, index) => (
                                                      <p key={index}>
                                                        {segment}
                                                      </p>
                                                    ),
                                                  );
                                                })()
                                              : account?.name
                                          }
                                          contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                        >
                                          <p className="text-slate-600 font-semibold leading-6 text-[1rem] flex">
                                            {account?.name?.length > 30
                                              ? `${account?.name.substring(
                                                  0,
                                                  30,
                                                )}...`
                                              : account?.name}{' '}
                                            {account?.mask &&
                                              `(...${account?.mask})`}
                                          </p>
                                        </Tooltip>

                                        {account?.providerAccountId && (
                                          <Tooltip
                                            content={
                                              <>
                                                <p className="text-center">
                                                  This account is setup for live
                                                  bank feeds and
                                                </p>
                                                <p className="text-center">
                                                  {' '}
                                                  will automatically import
                                                  transactions.
                                                </p>
                                              </>
                                            }
                                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                          >
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 27 28"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                x="0.675"
                                                y="1.175"
                                                width="25.65"
                                                height="25.65"
                                                rx="7.425"
                                                fill="white"
                                              />
                                              <rect
                                                x="0.675"
                                                y="1.175"
                                                width="25.65"
                                                height="25.65"
                                                rx="7.425"
                                                stroke="#D0D5DD"
                                                strokeWidth="1.35"
                                              />
                                              <path
                                                d="M16.5898 7.62305L19.0815 10.1147L16.5898 12.6064"
                                                stroke="#667085"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M7.86914 13.8527V12.6069C7.86914 11.9461 8.13165 11.3123 8.59893 10.845C9.06621 10.3777 9.69998 10.1152 10.3608 10.1152H19.0816"
                                                stroke="#667085"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M10.3608 21.3271L7.86914 18.8354L10.3608 16.3438"
                                                stroke="#667085"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M19.0816 15.0977V16.3435C19.0816 17.0043 18.8191 17.6381 18.3518 18.1054C17.8846 18.5726 17.2508 18.8352 16.59 18.8352H7.86914"
                                                stroke="#667085"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </Tooltip>
                                        )}

                                        {/* {account?.status === "active" ? (
                                                <span
                                                  className={`capitalize  px-1.5 py-0 w-fit rounded-md  text-sm font-medium
                  text-[#40AA00] bg-[#40AA001A] flex align-self-end
                  `}
                                                >
                                                  {account?.status}
                                                </span>
                                              ) : (
                                                <span
                                                  className={`capitalize  px-1.5 py-0 w-fit rounded-md  text-sm font-medium
            text-[#FF4B4B] bg-[#FF4B4B1A] flex align-self-end
              `}
                                                >
                                                  {account?.status}
                                                </span>
                                              )} */}
                                      </div>
                                      <p className="text-[#A0A7B4] text-[0.875rem] leading-6">
                                        {account?.officialName !==
                                          account?.name &&
                                          (account?.officialName?.length > 33
                                            ? `${account?.officialName.substring(
                                                0,
                                                33,
                                              )}...`
                                            : account?.officialName)}
                                      </p>
                                      {account?.status === 'inactive' && (
                                        <div className="capitalize  px-1.5 py-0.5 w-fit rounded-md  text-[10px] font-medium text-[#FF4B4B] bg-[#FF4B4B1A]">
                                          Inactive
                                        </div>
                                      )}
                                    </div>
                                    <p className="col-span-5 text-slate-600 leading-6 text-[0.875rem] ">
                                      {account?.description &&
                                        account.description}
                                    </p>
                                    <div className="col-span-2 flex flex-col justify-end text-nowrap">
                                      {((getAccountSystemBal(account?.id) !==
                                        null &&
                                        getAccountSystemBal(account?.id) !==
                                          undefined) ||
                                        countBalanceLoader) && (
                                        <div className="flex items-center">
                                          <span className="text-nowrap pr-1">
                                            COUNT Balance :
                                          </span>
                                          {countBalanceLoader ? (
                                            <svg
                                              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                            </svg>
                                          ) : getAccountSystemBal(
                                              account?.id,
                                            ) !== null &&
                                            getAccountSystemBal(account?.id) !==
                                              undefined ? (
                                            <span>
                                              {getAccountSystemBal(account?.id)
                                                ?.toString()
                                                .charAt(0) === '-'
                                                ? '-'
                                                : ''}
                                              {getCurrencySymbolFromIso(
                                                account?.currency ||
                                                  team?.currency,
                                                currencies,
                                              )}
                                              {parseFloat(
                                                getAccountSystemBal(account?.id)
                                                  ?.toString()
                                                  .replace('-', '') || 0,
                                              )?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </span>
                                          ) : (
                                            '--'
                                          )}
                                        </div>
                                      )}
                                      {account?.providerBalances?.[
                                        account?.providerBalances?.length - 1
                                      ]?.balanceCurrent !== null &&
                                        account?.providerBalances?.[
                                          account?.providerBalances?.length - 1
                                        ]?.balanceCurrent !== undefined && (
                                          <div className=" flex items-center pr-1">
                                            <span className=" text-nowrap pr-2">
                                              Bank Balance :
                                            </span>

                                            {account?.providerBalances?.[
                                              account?.providerBalances
                                                ?.length - 1
                                            ]?.balanceCurrent
                                              ?.toString()
                                              .charAt(0) === '-'
                                              ? '-'
                                              : ''}
                                            {getCurrencySymbolFromIso(
                                              account?.currency ||
                                                team?.currency,
                                              currencies,
                                            )}
                                            {parseFloat(
                                              account?.providerBalances?.[
                                                account?.providerBalances
                                                  ?.length - 1
                                              ]?.balanceCurrent
                                                ?.toString()
                                                .replace('-', '') || 0,
                                            )?.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                          </div>
                                        )}
                                    </div>

                                    {account?.status === 'inactive' ? (
                                      <div className="col-span-2 gap-4 items-center flex justify-end">
                                        <Tooltip
                                          tooltipShow
                                          content={
                                            account?.editable
                                              ? 'Activate'
                                              : "Can't Activate non editable account"
                                          }
                                          contentClassName={`border-none overflow-visible text-sm text-[#667085] relative ${
                                            account?.editable
                                              ? 'tooltipArrowDown'
                                              : 'min-w-[270px] text-wrap'
                                          }`}
                                          position={
                                            account?.editable ? '' : 'left'
                                          }
                                        >
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              if (account?.editable) {
                                                setDeActivateModalOpen(true);
                                                setDeActivateAccount(account);
                                              }
                                            }}
                                            className="h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                                            type="button"
                                          >
                                            <svg
                                              width="16"
                                              height="10"
                                              viewBox="0 0 16 10"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              // className={''}
                                            >
                                              <path
                                                d="M7.85524 6.6217C7.99464 6.6217 8.13334 6.56385 8.23219 6.4503L12.5356 1.51815C12.7173 1.31015 12.6958 0.994197 12.4875 0.812847C12.2802 0.631447 11.9643 0.652197 11.7822 0.860947L7.47879 5.7931C7.29714 6.0011 7.31864 6.31705 7.52689 6.4984C7.62159 6.58115 7.73854 6.6217 7.85524 6.6217Z"
                                                fill="#78BD4F"
                                              />
                                              <path
                                                d="M15.8126 0.378983C15.6051 0.197833 15.2891 0.218833 15.1073 0.427083L8.07111 8.49078L4.67021 4.71513C4.48491 4.50983 4.16901 4.49323 3.96391 4.67828C3.75881 4.86308 3.74221 5.17928 3.92706 5.38458L5.91151 7.58768L5.12531 8.49008L1.72516 4.71568C1.53986 4.51038 1.22396 4.49378 1.01886 4.67883C0.813758 4.86388 0.797158 5.17983 0.982008 5.38513L4.76011 9.57873C4.85506 9.68393 4.99011 9.74403 5.13171 9.74403H5.13586C5.27891 9.74283 5.41466 9.68033 5.50866 9.57238L6.58571 8.33613L7.70546 9.57923C7.80041 9.68443 7.93546 9.74453 8.07706 9.74453H8.08096C8.22401 9.74333 8.35976 9.68103 8.45376 9.57313L15.8608 1.08438C16.0423 0.876283 16.0208 0.560633 15.8126 0.378983Z"
                                                fill="#78BD4F"
                                              />
                                            </svg>
                                          </button>
                                        </Tooltip>
                                      </div>
                                    ) : (
                                      <div className="col-span-2 gap-4 items-center flex justify-end">
                                        <div className="gap-4 items-center flex">
                                          {/*
                                      {account?.subType?.anchorTier ===
                                      "PHYSICAL_ACCOUNT" ? (
                                        <button
                                          onClick={() => {
                                            if (
                                              !account?.reconcileBalances?.[0]
                                                ?.draft &&
                                              account?.reconcileBalances?.[0]
                                                ?.endingBalanceDate
                                            ) {
                                              setReconcileAccount(account);
                                            }
                                            if (
                                              account?.reconcileBalances?.[0]
                                                ?.draft
                                            ) {
                                              navigate(
                                                `/account/reconcile/${account?.id}`
                                              );
                                            } else {
                                              setReconcileAccount(account);
                                            }
                                          }}
                                          className="flex bg-white w-[5.188rem] py-2.5 pl-3.5 pr-[1.125rem] justify-center items-center gap-2 rounded-[0.313rem] border border-[#FF4B4B] bg-[#ff4b4b05] text-[#FF4B4B] text-[0.875rem] leading-6"
                                        >
                                          Reconcile
                                        </button>
                                      ) : (
                                        <div className="invisible w-[5.188rem]"></div>
                                      )} */}
                                          <Tooltip
                                            content="View Transactions"
                                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                          >
                                            <svg
                                              width="28"
                                              height="28"
                                              viewBox="0 0 28 28"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              onClick={() =>
                                                accountsToTransactions(account)
                                              }
                                              className="cursor-pointer"
                                            >
                                              <path
                                                d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                                fill="white"
                                                stroke="#D0D5DD"
                                                strokeOpacity="0.6"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                                fill="white"
                                                stroke="#D0D5DD"
                                                strokeOpacity="0.6"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M12.2861 19.9902H17.8008C19.7445 19.9902 21.0404 18.6944 21.0404 16.7507V13.5111C21.0404 11.7617 19.9389 10.4659 18.3191 10.2715C18.1895 10.2715 17.9952 10.2715 17.8008 10.2715H11.3216C11.1272 10.2715 10.9977 10.2715 10.8033 10.3363C9.1187 10.5306 8.08203 11.7617 8.08203 13.5111C8.08203 13.7054 8.08203 13.9646 8.08203 14.159"
                                                stroke="#858D9D"
                                                strokeWidth="1.3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M18.3191 10.272C18.1895 10.272 17.9951 10.272 17.8007 10.272H11.3216C11.1272 10.272 10.9976 10.272 10.8032 10.3368C10.868 10.1424 10.9976 10.0128 11.192 9.81843L13.2653 7.68031C14.1724 6.77323 15.5978 6.77323 16.5049 7.68031L17.6711 8.84655C18.0599 9.2353 18.2543 9.75365 18.3191 10.272Z"
                                                stroke="#858D9D"
                                                strokeWidth="1.3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M20.7861 13.7363H19.5861C19.1461 13.7363 18.7861 14.4113 18.7861 15.2363C18.7861 16.0613 19.1461 16.7363 19.5861 16.7363H20.7861"
                                                stroke="#858D9D"
                                                strokeWidth="1.3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M10.4367 20.6379C12.0373 20.6379 13.529 19.7705 14.5674 18.2693C14.9755 17.6813 14.9755 16.6929 14.5674 16.105C13.529 14.6037 12.0373 13.7363 10.4367 13.7363C8.83614 13.7363 7.34439 14.6037 6.30606 16.105C5.89798 16.6929 5.89798 17.6813 6.30606 18.2693C7.34439 19.7705 8.83614 20.6379 10.4367 20.6379Z"
                                                fill="white"
                                                stroke="#858D9D"
                                                strokeWidth="1.18355"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M11.2196 17.2041C11.2196 17.2436 11.1732 17.3975 10.9973 17.5735C10.8213 17.7494 10.6674 17.7958 10.6279 17.7958C10.5883 17.7958 10.4344 17.7494 10.2584 17.5735C10.0825 17.3975 10.0361 17.2436 10.0361 17.204C10.0361 17.1645 10.0825 17.0106 10.2584 16.8346C10.4344 16.6587 10.5883 16.6123 10.6279 16.6123C10.6674 16.6123 10.8213 16.6587 10.9973 16.8346C11.1732 17.0106 11.2196 17.1645 11.2196 17.2041Z"
                                                fill="#858D9D"
                                                stroke="#858D9D"
                                                strokeWidth="1.18355"
                                              />
                                            </svg>
                                          </Tooltip>
                                          {/* Deactivate Account  */}
                                        </div>
                                        {
                                          (account?.editable ||
                                            account?.subType?.anchorTier ===
                                              'PHYSICAL_ACCOUNT') &&
                                            accessType !== VIEWER && (
                                              <Tooltip
                                                content="Edit Account"
                                                contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                              >
                                                <svg
                                                  width="28"
                                                  height="27"
                                                  viewBox="0 0 28 27"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="cursor-pointer"
                                                  onClick={() => {
                                                    if (account?.editable) {
                                                      setIsEditing(true);
                                                      setSelectedAccount(
                                                        account,
                                                      );
                                                      setAccountsModalOpen(
                                                        true,
                                                      );
                                                    } else {
                                                      setSelectedAccount(
                                                        account,
                                                      );
                                                      setSimpleModalOpen(true);
                                                    }
                                                  }}
                                                >
                                                  <path
                                                    d="M10.1 26.5H17.9C24.4 26.5 27 23.9 27 17.4V9.6C27 3.1 24.4 0.5 17.9 0.5H10.1C3.6 0.5 1 3.1 1 9.6V17.4C1 23.9 3.6 26.5 10.1 26.5Z"
                                                    fill="white"
                                                    stroke="#D0D5DD"
                                                    strokeOpacity="0.6"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                  <path
                                                    d="M13.416 7.66797H12.2493C9.33268 7.66797 8.16602 8.83464 8.16602 11.7513V15.2513C8.16602 18.168 9.33268 19.3346 12.2493 19.3346H15.7493C18.666 19.3346 19.8327 18.168 19.8327 15.2513V14.0846"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    strokeWidth="1.3"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                  <path
                                                    d="M16.3561 8.26108L11.7594 12.8577C11.5844 13.0327 11.4094 13.3769 11.3744 13.6277L11.1236 15.3836C11.0303 16.0194 11.4794 16.4627 12.1153 16.3752L13.8711 16.1244C14.1161 16.0894 14.4603 15.9144 14.6411 15.7394L19.2378 11.1427C20.0311 10.3494 20.4044 9.42775 19.2378 8.26108C18.0711 7.09442 17.1494 7.46775 16.3561 8.26108Z"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    strokeWidth="1.3"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                  <path
                                                    d="M15.6973 8.91992C16.0881 10.3141 17.1789 11.4049 18.5789 11.8016"
                                                    stroke="#858D9D"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                </svg>
                                              </Tooltip>
                                            )
                                          //  : (
                                          //   <div className="invisible w-7 h-[1.688rem]"></div>
                                          // )
                                        }
                                        <Tooltip
                                          tooltipShow
                                          content={
                                            account?.editable
                                              ? 'Deactivate'
                                              : "Can't Deactivate non editable account"
                                          }
                                          contentClassName={`border-none overflow-visible text-sm text-[#667085] relative ${
                                            account?.editable
                                              ? 'tooltipArrowDown'
                                              : 'min-w-[270px] text-wrap'
                                          }`}
                                          position={
                                            account?.editable ? '' : 'left'
                                          }
                                        >
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              if (account?.editable) {
                                                setDeActivateModalOpen(true);
                                                setDeActivateAccount(account);
                                              }
                                            }}
                                            className="h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                                            type="button"
                                          >
                                            <svg
                                              width="16"
                                              height="16"
                                              viewBox="0 0 16 16"
                                              className="cursor-pointer"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                x="1"
                                                y="1"
                                                width="14"
                                                height="14"
                                                rx="7"
                                                stroke={
                                                  account?.editable
                                                    ? '#FF4B4B'
                                                    : '#cbd5e1'
                                                }
                                                strokeWidth="1.3"
                                              />
                                              <path
                                                d="M3.05026 3.05025L12.9498 12.9497"
                                                stroke={
                                                  account?.editable
                                                    ? '#FF4B4B'
                                                    : '#cbd5e1'
                                                }
                                                strokeWidth="1.3"
                                                strokeLinecap="round"
                                              />
                                            </svg>
                                          </button>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                ))
                            ) : (
                              <p className="text-slate-600 w-full py-[1.375rem] border-b border-[#D0D5DD] leading-6 text-[1rem] italic pl-[1.688rem]">
                                You haven't added any {subType?.name} accounts
                                yet.
                              </p>
                            )}
                            {(accessType === OWNER || accessType === ADMIN) && (
                              <div
                                className="mt-2 w-full text-center text-indigo-500 cursor-pointer"
                                onClick={() => {
                                  setIsEditing(false);
                                  setSelectedType(
                                    types.find((t) => t.name === subType?.type),
                                  );
                                  setSelectedSubType(subType?.name);
                                  setAccountsModalOpen(true);
                                }}
                                tabIndex="0"
                                onKeyDown={null}
                                role="button"
                              >
                                + Add a New Account
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              )}
              {!isLoading && allAccounts?.length === 0 && (
                <div className="flex flex-col gap-5 items-center justify-center w-full h-full">
                  <h6 className="text-center mt-4">No Accounts Found</h6>
                  <img
                    src={emptyAccountsImg}
                    alt="No Accounts found"
                    className="h-[12.5rem]"
                  />
                </div>
              )}
            </div>
            {selectedAccount?.name && (
              <AccoutDetails
                accountPanelOpen={accountDetailsOpen}
                setAccountPanelOpen={setAccountDetailsOpen}
                accountDetails={selectedAccount}
                resetAccountDetails={setSelectedAccount}
                setEditAccountModalOpen={setEditAccountModalOpen}
                modalOpen={editAccountModalOpen}
              />
            )}
            <PlaidLink
              loadData={loadData}
              setIsLoading={() => {}}
              token={token}
              team={team}
              accountPage
              buttonRef={PlaidButtonRef}
              cb={() => {}}
              shouldNavigate
            />
          </main>
        )}
      </AppLayout>
    </>
  );
}

export default Accounts;
