import ModalBasic from 'components/ModalBasic';
import { Loader } from 'components/Svg';
import React from 'react';

const SubscriptionWarningModal = ({
  isOpen,
  setIsOpen,
  handleDone,
  price,
  loading,
  onCancel,
}) => (
  <ModalBasic
    title="Warning: Subscription Required"
    modalOpen={isOpen}
    setModalOpen={setIsOpen}
    width="!w-[722px]"
  >
    <div className="w-full px-[30px] py-[1.875rem] bg-white">
      <p className="text-[18px]  text-[#667085] font-medium">
        To proceed, you will be charged {price} as a subscription fee
      </p>
      <p className="text-[18px] mt-4  text-[#667085] font-medium">
        Do you want to continue?
      </p>
    </div>
    <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6"
        onClick={onCancel}
        type="button"
      >
        Cancel
      </button>
      <button
        className="w-[5.625rem] h-11
       disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed
       flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
        onClick={() => handleDone()}
        type="button"
        disabled={loading}
      >
        {loading ? (
          <Loader height="h-5" width="w-5" color="#ffffff" />
        ) : (
          'Proceed'
        )}
      </button>
    </div>
  </ModalBasic>
);

export default SubscriptionWarningModal;
