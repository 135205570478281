import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProductForm from './ProductForm';
import {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  getTaxes,
  getAccounts,
  getProductHistory,
} from '../../API/backend_helper';
import PaginationClassic from '../../components/PaginationClassic';
import emptyProductsImg from '../../images/no-products.svg';
import SearchForm from '../../partials/actions/SearchForm';
import DeleteProductModal from './DeleteProductModal';
import {
  getCurrencySymbolFromIso,
  transformAccounts,
  VIEWER,
  trimText,
  getStringSegments,
} from '../../utils/Utils';
import { Loader } from '../../components/Svg';
import AppLayout from '../../components/AppLayout';
import Tooltip from '../../components/Tooltip';
import ProductsPanel from './ProductsPanel';

let searchTimeout = null;
const Products = () => {
  const navigate = useNavigate();

  const { accessType } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [isLoading, setIsLoading] = useState(false);
  const [buttonToggler, setButtonToggler] = useState(false);

  const [taxes, setTaxes] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeProduct, setActiveProduct] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [localLimit, setLocalLimit] = useState(50);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [title, setTitle] = useState('Products & Services');
  const [errMessage, setErrMessage] = useState('');
  const [categories, setCategories] = useState([]);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [timeEntryLoading, setTimeEntryLoading] = useState({});

  const [productsPanelOpen, setProductsPanelOpen] = useState(false);
  const [productHistory, setProductHistory] = useState(null);
  const [productHistoryLoading, setProductHistoryLoading] = useState(false);
  const [productHistoryLimit, setProductHistoryLimit] = useState(50);
  const [activeProductHistoryId, setActiveProductHistoryId] = useState(null);

  document.title = 'COUNT | Products & Services';

  const loadAccounts = async () => {
    try {
      setIsLoading(true);
      const res = await getAccounts();
      const incomeCategories = transformAccounts(
        res,
        'CATEGORY_PHYSICAL_ACCOUNT',
      );
      const expenseCategories = transformAccounts(
        res,
        'CATEGORY_PHYSICAL_ACCOUNT',
        'expense',
      );
      setExpenseCategories(expenseCategories);
      setCategories(incomeCategories);
    } catch (err) {
      console.log('err', err);
      setIsLoading(false);
    }
  };

  const getData = async ({
    loading = false,
    limit = localLimit,
    page = products?.page,
    search = searchVal || '',
  }) => {
    try {
      const res = await getProducts({
        page,
        search,
        limit,
      });
      if (res?.message?.products?.length > 0) {
        const sortedProducts = [...res?.message?.products];
        sortedProducts.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setProducts({
          localProducts: sortedProducts,
          page: res?.message?.page,
          totalRecords: res?.message?.totalRecords,
        });
      } else {
        setProducts({
          localProducts: [],
          page: 1,
          totalRecords: 0,
        });
      }
      if (loading) {
        setIsLoading(false);
      }
    } catch (e) {
      if (loading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const { localProducts, page, totalRecords } = products || {};

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleAddProduct = async (data) => {
    try {
      const response = await createProduct(data);
      getData({});
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdateProduct = async (data, id) => {
    try {
      await updateProduct(data, id);
      await getData({});
      setActiveProduct(null);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDeleteProduct = async (product) => {
    setDeleteModalLoading(true);
    try {
      const res = await deleteProduct(product.id);
      getData({});
      setActiveProduct(null);
      setModalOpen(false);
      if (res?.data?.status === 'success') {
        toast.success('Product deleted successfully');
      }
    } catch (err) {
      if (err.message) {
        setErrMessage(JSON.parse(err.message));
      } else {
        setModalOpen(false);
      }
    } finally {
      setDeleteModalLoading(false);
    }
  };

  const onSearch = (value) => {
    setSearchVal(value);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData({
        search: value ? encodeURIComponent(value) : '',
        page: 1,
      });
    }, 500);
  };

  const handleTimeEntryTaskCheck = async (e, product) => {
    e.stopPropagation();
    const { checked } = e.target;
    setTimeEntryLoading({ ...timeEntryLoading, [product.id]: true });
    const updatedProduct = {
      ...product,
      timeEntryTask: checked,
      taxes: product.taxes.map((tax) => tax.id),
    };
    await handleUpdateProduct(updatedProduct, product.id);
    setTimeEntryLoading({ ...timeEntryLoading, [product.id]: false });
  };

  const loadProductHistory = async ({
    id = activeProductHistoryId,
    page = 1,
    limit = productHistoryLimit,
    loading = true,
  }) => {
    if (loading) {
      setProductHistoryLoading(true);
    }
    setActiveProductHistoryId(id);
    if (!id) {
      return;
    }
    try {
      const res = await getProductHistory({ id, page, limit });
      setProductHistory(res?.data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setProductHistoryLoading(false);
    }
  };

  useEffect(() => {
    loadTaxes();
    getData({ loading: true });
    loadAccounts();
  }, []);

  return (
    <>
      <DeleteProductModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        errMessage={errMessage}
        activeProduct={activeProduct}
        handleDeleteProduct={handleDeleteProduct}
        loading={deleteModalLoading}
      />
      <AppLayout pageId="productsPage" mainClass="relative" isDashboardPage>
        <main className="relative grow ">
          <ProductsPanel
            panelOpen={productsPanelOpen}
            setPanelOpen={setProductsPanelOpen}
            productHistory={productHistory}
            productHistoryLoading={productHistoryLoading}
            setProductHistoryLimit={setProductHistoryLimit}
            productHistoryLimit={productHistoryLimit}
            loadProductHistory={loadProductHistory}
          />
          {/* Content */}
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {/* Page header */}
            <div className="flex gap-4 flex-wrap justify-between mb-10">
              <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                {title}
              </h1>
              {!formOpen ? (
                <div className="ml-auto flex flex-row flex-wrap gap-x-4 gap-y-2 items-center">
                  <SearchForm
                    placeholder="Search"
                    searchVal={searchVal}
                    setSearchVal={onSearch}
                    transaction
                  />
                  {accessType !== VIEWER && (
                    <div>
                      <div className="h-10 flex relative min-w-[193px]">
                        <button
                          className={`flex gap-2 items-center justify-center rounded-tl-[5px]
                               ${
                                 !buttonToggler ? 'rounded-bl-[5px]' : ''
                               }  h-full px-2 border border-indigo-500  bg-indigo-500 text-white disabled:bg-indigo-400`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveProduct(null);
                            setTitle('Add a Product or Service');
                            setFormOpen(true);
                          }}
                          type="button"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            className=""
                          >
                            <path
                              d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                              stroke="white"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Add Product or Service
                        </button>
                        <button
                          onClick={() => setButtonToggler(!buttonToggler)}
                          className={`rounded-tr-[5px] ${
                            !buttonToggler ? 'rounded-br-[5px]' : ''
                          }  h-full px-2 border border-l-[#FFFFFFCC] border-indigo-500  bg-indigo-500 text-white`}
                          type="button"
                        >
                          <svg
                            className={`shrink-0 ml-1 text-white  ${
                              buttonToggler ? 'rotate-180' : ''
                            }`}
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                              stroke="#ffffff"
                              strokeOpacity="0.8"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                      {buttonToggler && (
                        <button
                          onClick={() => navigate('/import-product-services')}
                          className="absolute  w-[240px] rounded-bl-[5px] rounded-br-[5px] h-10 border border-t-[#FFFFFFCC] border-indigo-500  bg-indigo-500 text-white"
                          type="button"
                        >
                          Import Products or Services
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => {
                    setActiveProduct(null);
                    setTitle('Products & Services');
                    setFormOpen(false);
                  }}
                  className=""
                  type="button"
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="6.17688"
                      y="7.82422"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(-45 6.17688 7.82422)"
                      fill="#667085"
                    />
                    <rect
                      x="20.1771"
                      y="6.17676"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(45 20.1771 6.17676)"
                      fill="#667085"
                    />
                  </svg>
                </button>
              )}
            </div>
            {formOpen && (
              <ProductForm
                setActiveProduct={setActiveProduct}
                activeProduct={activeProduct}
                handleUpdateProduct={handleUpdateProduct}
                handleAddProduct={handleAddProduct}
                setFormOpen={setFormOpen}
                setTitle={setTitle}
                currencies={currencies}
                team={team}
                taxes={taxes}
                setTaxes={setTaxes}
                incomeCategories={categories}
                expenseCategories={expenseCategories}
                setProductsPanelOpen={setProductsPanelOpen}
                loadProductHistory={loadProductHistory}
              />
            )}
            {isLoading && (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <Loader />
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            )}
            {localProducts?.length > 0 && !isLoading && !formOpen && (
              <div className="bg-white flex-1">
                {/* Table */}
                <div className="overflow-auto h-full">
                  <table className="table-fixed w-full dark:text-slate-300">
                    {/* Table header */}
                    <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                      <tr className="w-full py-[1.438rem] h-16">
                        <th className="px-4 max-w-[220px] w-[220px]">
                          <div className="text-left font-medium">Name</div>
                        </th>
                        <th className="max-w-[150px] w-[150px]">
                          <div className="text-left font-medium">
                            Purchase Price
                          </div>
                        </th>
                        <th className="max-w-[150px] w-[150px]">
                          <div className="text-left font-medium">
                            Sell Price
                          </div>
                        </th>
                        <th className="max-w-[5rem] w-[5rem]">
                          <div className="text-left font-medium">Stock</div>
                        </th>
                        <th className="max-w-[120px] w-[120px]">
                          <div className="text-left font-medium">Tax</div>
                        </th>
                        {accessType !== VIEWER && (
                          <th className="max-w-[8rem] w-[8rem]">
                            <Tooltip
                              content={
                                <>
                                  <p>
                                    Check this box to make this service
                                    available
                                  </p>
                                  <p>
                                    as an option when logging time entries for
                                  </p>
                                  <p>
                                    your projects. This ensures accurate
                                    tracking
                                  </p>
                                  <p>and billing for the services provided.</p>
                                </>
                              }
                              contentClassName={` font-normal border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowUp`}
                              tooltipShow
                              position="bottom"
                            >
                              <div className="cursor-default text-center font-medium whitespace-nowrap underline">
                                Time Entry Service
                              </div>
                            </Tooltip>
                          </th>
                        )}
                        {accessType !== VIEWER && (
                          <th className="max-w-[5rem] w-[5rem]">
                            <div className="text-left font-medium">Actions</div>
                          </th>
                        )}
                      </tr>
                    </thead>
                    {/* Table body */}
                    {localProducts?.length > 0 && (
                      <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                        {localProducts?.map((product, index) => (
                          <tr
                            key={index}
                            className="w-full h-16  hover:bg-[#a0cd85] hover:bg-opacity-5"
                          >
                            <td className="px-4">
                              <div className="flex items-center">
                                <div className="flex flex-col justify-center items-start capitalize">
                                  <Tooltip
                                    tooltipShow={product?.name?.length > 30}
                                    content={
                                      product?.name?.length > 30
                                        ? (() => {
                                            const segments = getStringSegments(
                                              product?.name,
                                              50,
                                            );
                                            return segments.map(
                                              (segment, index) => (
                                                <p key={index}>{segment}</p>
                                              ),
                                            );
                                          })()
                                        : product?.name
                                    }
                                    position="right"
                                    contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowLeft"
                                  >
                                    <p className="text-slate-600 text-[15px] font-medium cursor-default">
                                      {trimText(
                                        product?.name,
                                        30,
                                      )?.toLowerCase()}
                                    </p>
                                  </Tooltip>
                                </div>
                              </div>
                            </td>
                            <td className="">
                              <div className="text-left">
                                <p className="text-slate-600">
                                  {product?.purchasePrice
                                    ? `${getCurrencySymbolFromIso(
                                        product.currency,
                                        currencies,
                                      )}
                                      ${parseFloat(
                                        product?.purchasePrice,
                                      ).toFixed(2)} per
                                      ${product?.pricingMethod === 'item' ? 'unit' : product?.pricingMethod}`
                                    : '--'}
                                </p>
                                <Tooltip
                                  tooltipShow={
                                    product?.purchaseDescription?.length > 30
                                  }
                                  content={
                                    product?.purchaseDescription?.length > 30
                                      ? (() => {
                                          const segments = getStringSegments(
                                            product?.purchaseDescription,
                                            50,
                                          );
                                          return segments.map(
                                            (segment, index) => (
                                              <p key={index}>{segment}</p>
                                            ),
                                          );
                                        })()
                                      : product?.purchaseDescription
                                  }
                                  position="top"
                                  contentClassName="border-none rounded-[10px] overflow-visible shadow-md text-sm text-[#667085] relative tooltipArrowDown"
                                >
                                  <p className="text-slate-600 cursor-default text-opacity-90 text-[13px] font-normal">
                                    {product?.purchaseDescription &&
                                      trimText(
                                        product?.purchaseDescription,
                                        30,
                                      )?.toLowerCase()}
                                  </p>
                                </Tooltip>
                              </div>
                            </td>
                            <td className="">
                              <div className="text-left">
                                <p className="text-slate-600">
                                  {product?.unitPrice
                                    ? `${getCurrencySymbolFromIso(
                                        product.currency,
                                        currencies,
                                      )}
                                      ${parseFloat(product?.unitPrice).toFixed(
                                        2,
                                      )} per
                                      ${product?.pricingMethod === 'item' ? 'unit' : product?.pricingMethod}`
                                    : '--'}
                                </p>
                                <Tooltip
                                  tooltipShow={
                                    product?.description?.length > 35
                                  }
                                  content={
                                    product?.description?.length > 30
                                      ? (() => {
                                          const segments = getStringSegments(
                                            product?.description,
                                            50,
                                          );
                                          return segments.map(
                                            (segment, index) => (
                                              <p key={index}>{segment}</p>
                                            ),
                                          );
                                        })()
                                      : product?.description
                                  }
                                  position="top"
                                  contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                >
                                  <p className="text-slate-600 cursor-default text-opacity-90 text-[13px] font-normal">
                                    {product?.description &&
                                      trimText(
                                        product?.description,
                                        35,
                                      )?.toLowerCase()}
                                  </p>
                                </Tooltip>
                              </div>
                            </td>
                            <td className="">
                              <div className="text-left">
                                {product?.stockQuantity !== null ? (
                                  <div className="flex gap-1">
                                    {product?.stockQuantity}
                                    <div
                                      className="mt-[2px] text-xs font-medium text-indigo-600 underline"
                                      onClick={() => {
                                        loadProductHistory({ id: product?.id });
                                        setProductsPanelOpen(true);
                                      }}
                                      role="button"
                                      tabIndex="0"
                                    >
                                      History
                                    </div>
                                  </div>
                                ) : (
                                  '--'
                                )}
                              </div>
                            </td>
                            <td className="">
                              <div className="inline-flex items-start text-left">
                                {product?.taxes && product.taxes.length > 0 ? (
                                  <div>
                                    {product.taxes.map((tax, index) => (
                                      <div key={index}>
                                        {tax.name} (
                                        {Number(tax.percentage).toFixed(2)}
                                        %)
                                        {index !== product.taxes?.length - 1 &&
                                          ','}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  'No taxes'
                                )}
                              </div>
                            </td>
                            {accessType !== VIEWER && (
                              <td className="text-center">
                                <div className="">
                                  {timeEntryLoading[product.id] ? (
                                    <div className="flex justify-center">
                                      <Loader width="w-4" height="h-4" />
                                    </div>
                                  ) : (
                                    <input
                                      id="timeEntryTask"
                                      name="timeEntryTask"
                                      type="checkbox"
                                      className="h-4 w-4 form-checkbox cursor-pointer"
                                      onChange={(e) => {
                                        handleTimeEntryTaskCheck(e, product);
                                      }}
                                      onClick={(e) => e.stopPropagation()}
                                      checked={product?.timeEntryTask}
                                    />
                                  )}
                                </div>
                              </td>
                            )}
                            {accessType !== VIEWER && (
                              <td className="">
                                <div className="w-[6rem] inline-flex items-center gap-3.5">
                                  <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                    <svg
                                      width="28"
                                      height="28"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setTitle('Edit a Product or Service');
                                        setActiveProduct(product);
                                        setFormOpen(true);
                                      }}
                                    >
                                      <path
                                        d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                        fill="white"
                                        stroke="#D0D5DD"
                                        strokeOpacity="0.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                        stroke="#667085"
                                        strokeOpacity="0.8"
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                        stroke="#667085"
                                        strokeOpacity="0.8"
                                        strokeWidth="1.3"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                        stroke="#858D9D"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                  <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                    <svg
                                      width="28"
                                      height="28"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setActiveProduct(product);
                                        setModalOpen(true);
                                      }}
                                    >
                                      <path
                                        d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                        fill="white"
                                        stroke="#D0D5DD"
                                        strokeOpacity="0.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                                        stroke="#667085"
                                        strokeOpacity="0.8"
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                                        stroke="#858D9D"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                                        stroke="#667085"
                                        strokeOpacity="0.8"
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M12.693 16.3574H14.8505"
                                        stroke="#667085"
                                        strokeOpacity="0.8"
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M12.1552 13.7656H15.3948"
                                        stroke="#667085"
                                        strokeOpacity="0.8"
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            )}
            {localProducts?.length > 0 && !isLoading && !formOpen && (
              <div className="mt-10">
                <PaginationClassic
                  setLimit={setLocalLimit}
                  onPageChange={getData}
                  pagination={{ limit: localLimit, page, totalRecords }}
                />
              </div>
            )}

            {!formOpen && localProducts?.length <= 0 && !isLoading && (
              <div className="flex flex-col gap-5 items-center justify-center w-full h-full">
                <h6 className="text-center mt-4">
                  No Products or Services Found
                </h6>
                <img
                  src={emptyProductsImg}
                  alt="No products or servies found"
                  className="h-[12.5rem]"
                />
              </div>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default Products;
