import PaginationClassic from 'components/PaginationClassic';
import Tooltip from 'components/Tooltip';
import moment from 'moment';
import ReceiptModal from 'pages/transactions/ReceiptModal';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  formatNumber,
  getCurrencySymbolFromIso,
  getStringSegments,
  trimText,
} from 'utils/Utils';

const UnmatchedReceiptsTab = ({
  unmatchedReceipts,
  unmatchedReceiptsLimit,
  setUnmatchedReceiptsLimit,
  loadUnmatchedReceipts,
}) => {
  const unmatchedReceiptBoxRef = useRef();
  const { currencies } = useSelector((state) => state.Currencies);

  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [activeReceiptTitle, setActiveReceiptTitle] = useState(null);
  const [descriptionLength, setDescriptionLength] = useState(30);

  const handleResize = () => {
    setDescriptionLength(
      parseInt(unmatchedReceiptBoxRef?.current?.clientWidth / 9),
    );
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [unmatchedReceiptBoxRef?.current]);

  return (
    <>
      <ReceiptModal
        isExpenseReporting
        title={activeReceiptTitle}
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
      />
      <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-6">
        {unmatchedReceipts?.pendingReceipts?.length > 0 ? (
          unmatchedReceipts?.pendingReceipts?.map((receipt) => (
            <div
              key={receipt?.id}
              className="bg-gray-100 rounded-[10px] p-4 flex gap-4 border border-gray-300 shadow-button"
            >
              {receipt?.receiptUrl?.toLowerCase()?.endsWith('.pdf') ? (
                <div
                  className="w-20 max-h-40 flex pt-4 justify-center"
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    setActiveReceipt(receipt?.receiptUrl);
                    setActiveReceiptTitle(receipt?.vendorName);
                    setReceiptModalOpen(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30"
                    width="30"
                    viewBox="0 0 512 512"
                    className="w-16 h-16"
                  >
                    <path
                      fill="#e48642"
                      d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
                    />
                  </svg>
                </div>
              ) : (
                <img
                  src={receipt?.receiptUrl}
                  alt={receipt?.vendorName}
                  className="w-20 max-h-40 object-contain cursor-pointer"
                  onClick={() => {
                    setActiveReceipt(receipt?.receiptUrl);
                    setActiveReceiptTitle(receipt?.vendorName);
                    setReceiptModalOpen(true);
                  }}
                />
              )}
              <div
                className="flex flex-col gap-1 flex-1"
                ref={unmatchedReceiptBoxRef}
              >
                <h3 className="text-[18px] font-medium text-black leading-tight">
                  {receipt?.vendorName}
                </h3>

                <p className="text-black">
                  {moment(receipt?.date).format('DD MMM, yyyy')}
                  <span className="mx-1"> |</span>
                  {getCurrencySymbolFromIso(receipt?.currency, currencies)}
                  {formatNumber(receipt?.amount)}
                </p>
                <p className="text-black">
                  {`${receipt?.people?.firstName} ${receipt?.people?.lastName}`}{' '}
                  {receipt?.peopleId && receipt?.accountId && (
                    <span className="mx-1"> |</span>
                  )}
                  {receipt?.account?.name}
                </p>
                <p className="text-black">{receipt?.expenseReportType?.name}</p>
                <Tooltip
                  className="cursor-default"
                  tooltipShow={receipt?.description?.length > descriptionLength}
                  content={
                    receipt?.description?.length > 30
                      ? (() => {
                          const segments = getStringSegments(
                            receipt?.description,
                            40,
                          );
                          return segments.map((segment, index) => (
                            <p key={index}>{segment}</p>
                          ));
                        })()
                      : receipt?.description
                  }
                  contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                >
                  <p className="text-black ">
                    {trimText(receipt?.description, descriptionLength)}
                  </p>
                </Tooltip>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center col-span-full">
            {' '}
            No Unmatched Receipts Found
          </p>
        )}
      </div>
      {unmatchedReceipts?.pendingReceipts?.length > 0 && (
        <div className="mt-10">
          <PaginationClassic
            setLimit={setUnmatchedReceiptsLimit}
            onPageChange={loadUnmatchedReceipts}
            pagination={{
              limit: unmatchedReceiptsLimit,
              page: unmatchedReceipts?.page,
              totalRecords: unmatchedReceipts?.totalRecords,
            }}
            pageLoad
          />
        </div>
      )}
    </>
  );
};

export default UnmatchedReceiptsTab;
