/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState, useMemo } from 'react';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  createBalanceSheet,
  generateExpenseReporterReport,
  generateInvoicesBySalesRep,
  getAccountsByExpenseReporter,
  getExpenseReporters,
} from '../../../../API/backend_helper';
import {
  dateCategoryList,
  formatValueWithSign,
  getExpenseReportStatusColor,
  getFormattedDate,
  getStringSegments,
  transformAccounts,
  trimText,
  VIEWER,
} from '../../../../utils/Utils';
import { CalendarIcon } from '../CalendarIcon';
import DateFilter from '../../../transactions/DateFilter';
import AppLayout from '../../../../components/AppLayout';
import CategoriesDropdown from '../../../transactions/CategoriesDropdown';
import DropdownFull from '../../../../components/DropdownFull';
import SalesRepReportTitleModal from './ExpenseClaimTitleModal';
import PeopleDropdown from '../../../expenseReporting/PeopleDropdown';
import AccountsDropdown from '../../../expenseReporting/AccountsDropdown';
import Tooltip from '../../../../components/Tooltip';

function ExpenseClaimReport() {
  const formikRef = useRef();
  const navigate = useNavigate();
  const { accessType } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [generatedReport, setGeneratedReport] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [selectedCurrency] = useState(team?.currency);
  const [modalOpen, setModalOpen] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('');

  const [people, setPeople] = useState([]);

  const [localPeople, setLocalPeople] = useState(null);
  const [expenseReporterAccounts, setExpenseReporterAccounts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const reportTimeType = searchParams.get('reportTimeType');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const accountId = searchParams.get('accountId');
  const expenseReporterId = searchParams.get('expenseReporterId');

  const dateFormats = useMemo(() => dateCategoryList(), []);
  const loadExpenseReporters = async () => {
    try {
      const res = await getExpenseReporters({
        page: 1,
        limit: 100000,
      });
      setPeople(res?.expenseReporters);
      if (res?.expenseReporters?.length > 0) {
        const person = res?.expenseReporters?.find(
          (p) => p?.id === parseInt(expenseReporterId),
        );
        if (person) {
          setLocalPeople(person);
        }
      }
    } catch (e) {
      console.log('error', e);
    }
  };
  useEffect(() => {
    loadExpenseReporters();
  }, []);

  useEffect(() => {
    const currency = currencies?.find(
      (curr) => curr?.isoCode === team?.currency,
    );
    setCurrencySymbol(
      currencies?.find?.((c) => c.isoCode === currency?.isoCode)?.symbol,
    );
  }, [currencies]);

  const onAPICall = async (data = {}) => {
    setGenerating(true);
    try {
      const res = await generateExpenseReporterReport(data);
      setGeneratedReport(res?.data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setGenerating(false);
      setModalOpen(false);
    }
  };

  const onSubmit = async (values) => {
    onAPICall({
      startDate: getFormattedDate(values.startDate),
      endDate: getFormattedDate(values.endDate),
      accountIds: [values?.accounts],
      expenseReporterId: values?.people,
      reportTitle: values.reportTitle,
      save: modalOpen,
    });
  };

  const loadAccountsByExpenseReporter = async (personId) => {
    try {
      setAccountsLoading(true);
      const accountsRes = await getAccountsByExpenseReporter(personId);
      const accounts = [...accountsRes?.data?.myAccounts];
      setAccounts(accounts);
      if (accountsRes) {
        setExpenseReporterAccounts(
          transformAccounts(accounts, 'PHYSICAL_ACCOUNT', 'expense'),
        );
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setAccountsLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate && expenseReporterId && accountId) {
      loadAccountsByExpenseReporter(expenseReporterId);
      onAPICall({
        startDate: getFormattedDate(startDate),
        endDate: getFormattedDate(endDate),
        accountIds: [accountId],
        expenseReporterId: parseInt(expenseReporterId),
        reportTitle: '',
        save: false,
      });
    }
  }, [startDate, endDate, expenseReporterId, accountId]);

  const downloadCSV = (csv, filename) => {
    const csvFile = new Blob([csv], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const exportToCSV = () => {
    const csvRows = [];
    const account = accounts?.find(
      (acc) => acc?.id === formikRef?.current?.values?.accounts,
    );
    const accountName = account?.mask
      ? `${account?.name} (${account?.mask})`
      : account?.name;
    const fullName = `${localPeople?.firstName} ${localPeople?.lastName}`;
    const _startDate = getFormattedDate(formikRef?.current?.values?.startDate);
    const _endDate = getFormattedDate(formikRef?.current?.values?.endDate);

    const headers = [
      'Date',
      'Vendor',
      'Expense Type',
      'Description',
      'Amount',
      'Status',
    ];
    let rows = [];
    if (generatedReport?.report?.length > 0) {
      rows = generatedReport?.report?.map((report) => [
        `${moment(report?.Date).format('MMM Do YYYY')}`,
        `${report?.Vendor || ''}`,
        `${report?.ExpenseType || ''}`,
        `${report?.Description || ''}`,
        `${currencySymbol}${report?.Amount}`,
        `${report?.Status || ''}`,
      ]);
    }
    // Adding headers
    csvRows.push(`${team?.name} - Expense Reporter Report`);
    csvRows.push(
      `${_startDate} - ${formikRef?.current?.values?.endDate ? _endDate : ''} | ${accountName}`,
    );
    csvRows.push(`Reporter: ${fullName}`);
    csvRows.push(headers.join(','));

    // Adding invoice rows
    if (rows?.length > 0) {
      rows.forEach((row) => csvRows.push(row.join(',')));
    }

    // Adding totals row
    const totalRow = [
      'Total',
      `Approved : ${currencySymbol}${generatedReport?.totals?.totalApprovedAmount}`,
      `Denied :${currencySymbol}${generatedReport?.totals?.totalDeniedAmount}`,
    ];
    csvRows.push(totalRow.join(','));

    const filename = ` ${moment(formikRef?.current?.values?.startDate).format('MMM DD, YYYY')} - ${formikRef?.current?.values?.endDate ? moment(formikRef?.current?.values?.endDate).format('MMM DD, YYYY') : '--'} | ${localPeople?.firstName} - Expense Report.csv`;
    downloadCSV(csvRows.join('\n'), filename);
  };

  return (
    <AppLayout>
      <main className="relative grow">
        <div className="px-4 sm:px-6 lg:px-8 py-5 w-full max-w-9xl mx-auto flex flex-col gap-5">
          <div className="flex  gap-5 flex-wrap items-center">
            <div
              onClick={() => {
                navigate('/reports');
              }}
              className="cursor-pointer"
              role="button"
              tabIndex="0"
            >
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="32"
                  height="33"
                  transform="translate(33) rotate(90)"
                  fill="#FBF3ED"
                />
                <path
                  d="M19 10.5L13 16.5L19 22.5"
                  stroke="#E48642"
                  strokeWidth="3.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h1 className="text-3xl md:text-4xl text-black opacity-60  font-medium">
              Expense Reporter Report
            </h1>
          </div>
          <div className="flex py-5 px-7  flex-col  gap-1 flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
            <Formik
              innerRef={formikRef}
              enableReinitialize={false}
              initialValues={{
                startDate: moment(startDate).toISOString() || '',
                endDate: moment(endDate).toISOString() || '',
                people: expenseReporterId ? parseInt(expenseReporterId) : '',
                accounts: accountId ? parseInt(accountId) : null,
                dateRange:
                  reportTimeType || dateFormats?.[0]?.categories?.[0]?.id || '',
                reportTitle: '',
              }}
              onSubmit={onSubmit}
            >
              {(validation) => {
                useEffect(() => {
                  if (validation.values.dateRange !== 'custom') {
                    const flatArray = dateFormats.flatMap(
                      (date) => date.categories,
                    );
                    const date =
                      flatArray.find(
                        (category) =>
                          category.id == validation.values.dateRange,
                      ) || 'custom';
                    validation.setFieldValue('startDate', date.startDate);
                    validation.setFieldValue('endDate', date.endDate);
                    validation.setFieldValue(
                      'reportTitle',
                      `Expense Reporter - ${localPeople?.firstName} ${localPeople?.lastName} (${selectedCurrency}) | ${moment(date.startDate).format('MMM DD, YYYY')}-${moment(date.endDate).format('MMM DD, YYYY')}`,
                    );
                  } else {
                    validation.setFieldValue(
                      'reportTitle',
                      `Expense Reporter - ${localPeople?.firstName} ${localPeople?.lastName} (${selectedCurrency}) | ${moment(validation.values.startDate).format('MMM DD, YYYY')}-${moment(validation.values.endDate).format('MMM DD, YYYY')}`,
                    );
                  }
                }, [
                  validation.values.dateRange,
                  selectedCurrency,
                  validation.values.startDate,
                  validation.values.endDate,
                  localPeople,
                ]);
                return (
                  <Form>
                    <SalesRepReportTitleModal
                      isOpen={modalOpen}
                      setIsOpen={setModalOpen}
                      reportTitle={validation.values.reportTitle}
                      setReportTitle={(value) =>
                        validation.setFieldValue('reportTitle', value)
                      }
                      isLoading={generating}
                    />
                    <div className="grid gap-1 xl:w-3/5 lg:w-10/12 sm:w-11/12">
                      <div>
                        <div className="">
                          <p className="py-2">
                            Date Range
                            <span className="text-rose-500 pl-1">*</span>
                          </p>
                          <div className="sm:grid sm:grid-cols-5 gap-3 gap-y-0">
                            <div className="flex flex-wrap gap-y-0 gap-4">
                              <div className="flex-1 max-w-[200px] sm:max-w-[300px] mb-3 sm:mb-0">
                                <CategoriesDropdown
                                  allCategories={[
                                    ...dateFormats,
                                    {
                                      name: 'Custom',
                                      categories: [
                                        {
                                          name: 'Custom',
                                          id: 'custom',
                                        },
                                      ],
                                    },
                                  ]}
                                  selectedCategoryId={
                                    validation.values.dateRange
                                  }
                                  setSelectedCategoryId={(value) => {
                                    validation.setFieldValue(
                                      'dateRange',
                                      value,
                                    );
                                  }}
                                  isSetCategoryStyle
                                  height="h-10"
                                />
                                {validation.touched.dateRange &&
                                validation.errors.dateRange ? (
                                  <div className="text-xs mt-1 text-rose-500">
                                    {validation.errors.dateRange}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="flex flex-wrap gap-y-0 col-span-2">
                              <div className="flex-1 max-w-[200px] sm:max-w-[300px] mb-3 sm:mb-0">
                                <DateFilter
                                  startDateFilter={validation.values.startDate}
                                  endDateFilter={validation.values.endDate}
                                  setStartDateFilter={(value) => {
                                    validation.setFieldValue(
                                      'startDate',
                                      value,
                                    );
                                  }}
                                  setEndDateFilter={(value) => {
                                    validation.setFieldValue('endDate', value);
                                  }}
                                  onDateChange={() => {
                                    validation.setFieldValue(
                                      'dateRange',
                                      'custom',
                                    );
                                  }}
                                  align="left"
                                  report
                                />
                                {validation.touched.startDate &&
                                validation.touched.endDate &&
                                validation.errors.startDate &&
                                validation.errors.endDate ? (
                                  <div className="text-xs mt-1 text-rose-500">
                                    {validation.errors.startDate ||
                                      validation.errors.endDate}{' '}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid  sm:grid-cols-2 mt-4 gap-4">
                          <div>
                            <label
                              className={`block text-sm mb-1 text-slate-600 font-medium leading-5 ${
                                validation.touched.people &&
                                validation.errors.people
                                  ? '!text-rose-400'
                                  : ''
                              }`}
                              htmlFor="people"
                            >
                              Person<span className="text-rose-500">*</span>
                            </label>
                            <PeopleDropdown
                              people={people}
                              setSelectedPeople={(name) => {
                                validation.setFieldValue('people', name);
                                loadAccountsByExpenseReporter(name);
                              }}
                              selectedPeople={validation.values.people}
                              isSetCategoryStyle
                              height="h-12"
                              error={
                                validation.touched.people &&
                                validation.errors.people
                              }
                              localPeople={localPeople}
                              setLocalPeople={setLocalPeople}
                              // disabled={activeReporter?.id}
                              // addNewOptionButton
                              // handleNewOptionButton={() => setPeopleModalOpen(true)}
                            />
                          </div>
                          {validation?.values?.people && (
                            <div className="flex flex-col items-start w-full h-full">
                              {accountsLoading ? (
                                <div className="h-full flex items-center w-full justify-center">
                                  <svg
                                    className="animate-spin w-6 h-6 mt-6 fill-indigo-500 shrink-0 mr-2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                  </svg>
                                </div>
                              ) : (
                                <>
                                  <label
                                    className={`block text-sm mb-1 text-slate-600 font-medium leading-5 ${
                                      validation.touched.accounts &&
                                      validation.errors.accounts
                                        ? '!text-rose-400'
                                        : ''
                                    }`}
                                    htmlFor="accounts"
                                  >
                                    Account{' '}
                                    <span className="text-rose-500">*</span>
                                  </label>
                                  <CategoriesDropdown
                                    allCategories={
                                      expenseReporterAccounts || []
                                    }
                                    placeholder="Select Account"
                                    selectedCategoryId={
                                      validation.values.accounts
                                    }
                                    setSelectedCategoryId={(value) => {
                                      validation.setFieldValue(
                                        'accounts',
                                        value,
                                      );
                                    }}
                                    isSetCategoryStyle
                                    type="account"
                                    id="accounts"
                                    name="accounts"
                                    height="h-12"
                                  />

                                  {validation.touched.accounts &&
                                  validation.errors.accounts ? (
                                    <div className="text-xs mt-1 text-rose-400">
                                      {validation.errors.accounts}
                                    </div>
                                  ) : null}

                                  {/* <label
                                    className={`block text-sm mb-1 text-slate-600 font-medium leading-5 ${
                                      validation.touched.accounts &&
                                      validation.errors.accounts
                                        ? '!text-rose-400'
                                        : ''
                                    }`}
                                    htmlFor="accounts"
                                  >
                                    Account{' '}
                                    <span className="text-rose-500">*</span>
                                  </label>
                                  <AccountsDropdown
                                    allCategories={expenseReporterAccounts}
                                    selectedCategoryId={
                                      validation.values.accounts
                                    }
                                    setSelectedCategoryId={(value) => {
                                      validation.setFieldValue(
                                        'accounts',
                                        value,
                                      );
                                    }}
                                    apiFilter={validation.values.accounts}
                                    type="account"
                                    localSelected={localSelectedAccounts}
                                    setLocalSelected={setLocalSelectedAccounts}
                                    isSetCategoryStyle
                                  />

                                  {validation.touched.accounts &&
                                  validation.errors.accounts ? (
                                    <div className="text-xs mt-1 text-rose-400">
                                      {validation.errors.accounts}
                                    </div>
                                  ) : null} */}
                                </>
                              )}
                            </div>
                          )}
                        </div>

                        {/* <div className="flex mt-4 flex-col items-start gap-2.5 w-[18.438rem]">
                          <label
                            className="block text-sm text-slate-600 font-medium leading-5"
                            htmlFor="type"
                          >
                            Currency
                          </label>
                          <DropdownFull
                            options={currencies}
                            selected={currency}
                            name="name"
                            setSelected={(name) => {
                              setCurrency(name);
                              const isoCode = getCurrencyIso(name);
                              setCurrencyIso(getCurrencyIso(name));
                              setGeneratedReport(null);
                              setCurrencySymbol(
                                currencies?.find?.((c) => c.isoCode === isoCode)
                                  ?.symbol,
                              );
                            }}
                            placeholder="Select Currency"
                            scrollIntoView={false}
                            height="h-10"
                          />
                        </div> */}
                      </div>
                      <div className="sm:grid sm:grid-cols-4 gap-3 py-3 flex-wrap items-center">
                        {accessType !== VIEWER && (
                          <div className="col-span-2 flex flex-col sm:flex-row gap-2 pt-3">
                            <button
                              type="submit"
                              className="flex items-center cursor-pointer justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap  bg-indigo-500 disabled:bg-indigo-300 disabled:cursor-not-allowed text-white"
                              disabled={
                                !validation.values.startDate ||
                                !validation.values.endDate ||
                                !validation.values.people ||
                                !validation.values.accounts ||
                                generating
                              }
                            >
                              Generate Report
                            </button>
                            <button
                              disabled={
                                !validation.values.startDate ||
                                !validation.values.endDate ||
                                !validation.values.people ||
                                !validation.values.accounts ||
                                generating
                              }
                              onClick={() => {
                                setModalOpen(true);
                              }}
                              className="flex items-center cursor-pointer justify-center px-2 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap border border-indigo-500  text-indigo-500  disabled:cursor-not-allowed"
                              type="button"
                            >
                              Generate & Save Report
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>

          {!(Object?.keys(generatedReport || {})?.length > 0) || generating ? (
            <div className="flex py-16 px-7 flex-col gap-1 text-center flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
              {generating && !modalOpen && (
                <div className="flex items-center pb-2 justify-center mt-8">
                  <svg
                    className="animate-spin w-10 h-10 fill-current shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                </div>
              )}
              <div className="flex justify-center">
                <svg
                  width="177"
                  height="155"
                  viewBox="0 0 177 155"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2823_64411)">
                    <path
                      d="M48.9475 87.1651C72.3444 87.1651 91.3114 68.1982 91.3114 44.8013C91.3114 21.4044 72.3444 2.4375 48.9475 2.4375C25.5507 2.4375 6.58374 21.4044 6.58374 44.8013C6.58374 68.1982 25.5507 87.1651 48.9475 87.1651Z"
                      fill="#F9EAE0"
                    />
                    <path
                      d="M51.6384 141.316C51.0285 141.316 50.4276 141.169 49.8865 140.887C49.3453 140.606 48.8798 140.199 48.529 139.7L43.6594 132.732L162.034 58.0156L167.658 61.7104C168.187 62.0575 168.62 62.5314 168.918 63.0888C169.216 63.6462 169.37 64.2693 169.365 64.9014C169.361 65.5335 169.198 66.1544 168.892 66.7076C168.586 67.2607 168.147 67.7285 167.614 68.0682L53.6699 140.723C53.0631 141.11 52.3582 141.316 51.6384 141.316Z"
                      fill="white"
                    />
                    <path
                      d="M162.026 58.5765L167.399 62.1059C167.864 62.4057 168.246 62.8191 168.507 63.3072C168.769 63.7954 168.901 64.3421 168.893 64.8957C168.891 65.4501 168.749 65.9949 168.481 66.4801C168.213 66.9653 167.827 67.3753 167.359 67.6724L53.4176 140.33C52.6961 140.789 51.8253 140.952 50.9864 140.785C50.1476 140.619 49.4054 140.135 48.9144 139.435L44.3243 132.87L162.026 58.5765ZM162.036 57.4606L42.9897 132.599L48.1444 139.971C48.7741 140.87 49.7263 141.491 50.8027 141.705C51.8791 141.919 52.9966 141.71 53.9224 141.12L167.864 68.4647C168.463 68.0829 168.957 67.5572 169.301 66.9355C169.645 66.3138 169.828 65.6159 169.833 64.9055C169.838 64.195 169.665 63.4946 169.33 62.8681C168.995 62.2416 168.509 61.7089 167.915 61.3187L162.037 57.457L162.036 57.4606Z"
                      fill="#081F2D"
                    />
                    <path
                      d="M94.4027 10.1219C97.252 8.28492 100.657 7.51002 104.021 7.93316C107.384 8.35631 110.491 9.95048 112.797 12.4359L116.492 16.4188L25.6196 54.4691L94.4027 10.1219Z"
                      fill="white"
                    />
                    <path
                      d="M102.215 8.29063C104.137 8.29253 106.037 8.68979 107.799 9.45771C109.561 10.2256 111.145 11.3478 112.455 12.7544L115.692 16.2447L30.3452 51.9825L94.657 10.5179C96.9092 9.06308 99.5337 8.28966 102.215 8.29063ZM102.215 7.3507C99.3537 7.34996 96.5531 8.17552 94.1498 9.72815L20.8943 56.9585L117.296 16.5916L113.143 12.1157C111.749 10.6106 110.058 9.41004 108.178 8.58951C106.297 7.76897 104.267 7.34617 102.215 7.34766V7.3507Z"
                      fill="#081F2D"
                    />
                    <path
                      d="M42.9896 132.596L38.29 125.876C37.9543 125.396 37.7213 124.852 37.6055 124.278C37.4897 123.703 37.4937 123.111 37.6171 122.539C37.7406 121.966 37.9809 121.425 38.323 120.95C38.6651 120.474 39.1016 120.075 39.6053 119.776L145.216 57.0983C147.051 56.0093 149.098 55.3271 151.219 55.0979C153.34 54.8687 155.486 55.0978 157.511 55.7698L160.918 56.8999C161.683 57.1537 162.36 57.6194 162.871 58.2429C163.381 58.8663 163.704 59.6219 163.803 60.4218C163.901 61.2217 163.77 62.033 163.425 62.7614C163.08 63.4898 162.536 64.1053 161.855 64.5365L51.6376 134.333C50.2494 135.212 48.576 135.521 46.9654 135.198C45.3548 134.874 43.9308 133.942 42.9896 132.596Z"
                      fill="#78BD4F"
                    />
                    <path
                      d="M39.6054 119.778L15.239 77.3915C14.256 75.6816 13.6223 73.7935 13.3747 71.8368C13.1271 69.8801 13.2705 67.8937 13.7966 65.9929C14.3227 64.092 15.2209 62.3145 16.4393 60.7636C17.6577 59.2126 19.172 57.919 20.8942 56.9578L98.8604 13.4404C101.831 11.7821 105.28 11.1905 108.634 11.764C111.988 12.3375 115.045 14.0416 117.296 16.5929C128.319 29.0817 135.999 44.1592 139.62 60.4187L39.6054 119.778Z"
                      fill="#78BD4F"
                    />
                    <path
                      d="M130.107 66.0703C124.726 50.5333 116.459 36.1524 105.74 23.6836"
                      stroke="#081F2D"
                      strokeWidth="0.939413"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M124.152 69.6016C118.77 54.0647 110.503 39.6839 99.7852 27.2148"
                      stroke="#081F2D"
                      strokeWidth="0.939413"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M119.188 72.5469C113.807 57.0101 105.54 42.6293 94.8218 30.1602"
                      stroke="#081F2D"
                      strokeWidth="0.939413"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M77.843 97.0865C72.4613 81.5495 64.1942 67.1685 53.4761 54.6992"
                      stroke="#081F2D"
                      strokeWidth="0.939413"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M52.7817 117.98L57.4813 124.7"
                      stroke="#081F2D"
                      strokeWidth="0.939413"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M135.003 63.1602C138.493 64.4051 141.5 66.7228 143.592 69.7812"
                      stroke="#081F2D"
                      strokeWidth="0.939413"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M39.6055 119.779L139.62 60.4219"
                      stroke="#081F2D"
                      strokeWidth="0.939413"
                      strokeMiterlimit="10"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2823_64411">
                      <rect
                        width="176.013"
                        height="154.709"
                        fill="white"
                        transform="translate(0.493408)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <div className="self-stretch flex mt-6 flex-col justify-center gap-2  text-center text-slate-600 text-[1rem] leading-6 font-normal h-16">
                <p className=" text-[#101828] ">Your report will appear here</p>
                <p className="text-[#667085]">
                  Simply, select date range, person and account above to
                  generate report!
                </p>
              </div>
            </div>
          ) : (
            <div className="flex p-7 flex-col gap-1 flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
              <div className="flex justify-between mb-4">
                <h1 className="text-2xl md:text-2xl text-black opacity-60  font-medium">
                  Report
                </h1>
                <button
                  className="flex items-center justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap  border border-[#E48642]  text-[#E48642]"
                  onClick={() => exportToCSV()}
                  type="button"
                >
                  Export as CSV
                </button>
              </div>
              <table className="table-fixed w-full dark:text-slate-300">
                <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                  <tr className="w-full px-[2.5rem] flex items-center justify-between h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 font-semibold false text-[#101828] bg-[#EEEEEE]">
                    <th className="whitespace-nowrap">
                      <div className="w-[8rem] text-left">Date</div>
                    </th>
                    <th className="whitespace-nowrap">
                      <div className="w-[11rem] text-left">Vendor</div>
                    </th>
                    <th className="whitespace-nowrap">
                      <div className="w-[11rem] text-left"> Expense Type</div>
                    </th>
                    <th className="whitespace-nowrap">
                      <div className="w-[19rem] text-left">Description</div>
                    </th>
                    <th className="whitespace-nowrap">
                      <div className="w-[8rem] text-left">Amount</div>
                    </th>
                    <th className="whitespace-nowrap">
                      <div className="w-[5rem] text-left">Status</div>
                    </th>
                  </tr>
                  {generatedReport?.report?.map((report) => (
                    <tr
                      key={report?.id}
                      className="w-full px-[2.5rem] flex items-center justify-between h-12 hover:bg-[#a0cd85] hover:bg-opacity-5"
                    >
                      <td className="whitespace-nowrap">
                        <div className="w-[8rem] text-left ">
                          {moment(report?.Date).format('MMM Do, YYYY')}
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="w-[11rem] text-left">
                          {report?.Vendor || '--'}
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="w-[11rem] text-left">
                          {report?.ExpenseType}
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="w-[19rem] text-left">
                          <Tooltip
                            tooltipShow={report?.Description?.length > 40}
                            content={
                              report?.Description?.length > 40
                                ? (() => {
                                    const segments = getStringSegments(
                                      report?.Description,
                                      40,
                                    );
                                    return segments.map((segment, index) => (
                                      <p key={index}>{segment}</p>
                                    ));
                                  })()
                                : report?.Description
                            }
                            contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                          >
                            <span className="">
                              {trimText(report?.Description || '', 40)}
                            </span>
                          </Tooltip>
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="w-[5rem] text-left">
                          {formatValueWithSign(
                            report?.Amount || 0,
                            false,
                            currencySymbol,
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="w-[8rem] flex justify-center">
                          <div
                            className={`w-fit  text-center capitalize cursor-default px-3 py-1 rounded-sm  text-sm font-medium
                          ${getExpenseReportStatusColor(report?.Status)}
                        `}
                          >
                            {report?.Status?.replace(
                              'Need Review',
                              'Needs Review',
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr className="w-full  px-[2.5rem] flex items-center justify-between h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 text-[#101828] font-semibold">
                    <td className="whitespace-nowrap">
                      <div className="w-[8rem] text-left ">Total</div>
                    </td>
                    <td className="whitespace-nowrap flex">
                      <div className="w-[11rem] text-left">
                        Approved :{' '}
                        {formatValueWithSign(
                          generatedReport?.totals?.totalApprovedAmount || 0,
                          false,
                          currencySymbol,
                        )}
                      </div>
                      <div className="w-[11rem] text-left">
                        Denied:{' '}
                        {formatValueWithSign(
                          generatedReport?.totals?.totalDeniedAmount || 0,
                          false,
                          currencySymbol,
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </main>
    </AppLayout>
  );
}
export default ExpenseClaimReport;
