import React, { useEffect, useState } from 'react';
import SearchForm from '../../../partials/actions/SearchForm';
import { Loader } from '../../../components/Svg';
import {
  getAuditLog,
  getCurrenciesData,
  getAccounts,
  getMerchants,
  getCustomers,
} from '../../../API/backend_helper';
import moment from 'moment';
import {
  getCurrencySymbolFromIso,
  transformAccounts,
  trimText,
} from '../../../utils/Utils';
import PaginationClassic from '../../../components/PaginationClassic';
import TransactionPanel from '../../transactions/TransactionPanel';
import { useSelector } from 'react-redux';
import { boot, shutdown } from '@intercom/messenger-js-sdk';

const AuditLog = ({
  allCategories,
  setAllCategories,
  setAllExpenseCategories,
  setAccounts,
  transaction,
  setTransaction,
  setTransactionPanelOpen,
  vendors,
  setVendors,
  customers,
  setCustomers,
  auditLogs,
  setAuditLogs,
  transactionPanelOpen,
}) => {
  const { currencies } = useSelector((state) => state.Currencies);

  const [searchValue, setSearchValue] = useState('');
  const [descriptionLength, setDescriptionLength] = useState(45);
  const [localLimit, setLocalLimit] = useState(50);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (transactionPanelOpen) {
      shutdown();
    } else {
      boot();
    }
  }, [transactionPanelOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setDescriptionLength(45);
      }
      if (window.innerWidth > 1700) {
        setDescriptionLength(35);
      } else if (window.innerWidth > 992) {
        setDescriptionLength(25);
      } else {
        setDescriptionLength(18);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    try {
      await getAuditLogApi({ loading: false });
      await getAccountsApi();
      const vendorRes = await getMerchants({});
      setVendors(vendorRes?.vendors);
      const customerRes = await getCustomers({});
      setCustomers(customerRes?.data?.records);
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  };

  const getAccountsApi = async () => {
    try {
      const res = await getAccounts();
      setAllCategories(transformAccounts(res, 'CATEGORY_PHYSICAL_ACCOUNT'));
      setAccounts(transformAccounts(res, 'PHYSICAL_ACCOUNT'));
      setAllExpenseCategories(
        transformAccounts(res, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  const getAuditLogApi = async ({
    page = 1,
    limit = localLimit,
    loading = false,
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getAuditLog({
        page,
        limit,
      });
      setAuditLogs(res?.data);
    } catch (error) {
      console.log('error', error);
    }
    if (loading) {
      setIsLoading(false);
    }
  };

  const formatAmount = (value, currencySymbol) => {
    if (isNaN(parseFloat(value))) {
      return '-';
    }

    return `${value < 0 ? '' : '-'}${currencySymbol}${Math.abs(
      parseFloat(value),
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const createActionText = (log) => {
    switch (log?.activity) {
      case 'CATEGORY_CHANGED':
        return `Category: ${log?.categoryAccount?.name || '[Category Deleted]'}`;
      case 'VENDOR_CHANGED':
        return `Vendor: ${log?.vendor?.name || '[Vendor Deleted]'}`;
      case 'CUSTOMER_CHANGED':
        return `Customer: ${log?.customer?.customer || '[Customer Deleted]'}`;
    }
  };

  const { page, totalRecords } = auditLogs;

  return (
    <>
      {/* Page header */}
      <div className="flex justify-between items-center mb-10">
        <h2 className="text-2xl   text-[#101828B2] font-medium">Audit Log</h2>
        {/*
        <SearchForm
          placeholder="Search"
          searchVal={searchValue}
          setSearchVal={setSearchValue}
          x
          transaction
        /> */}
      </div>
      <>
        {/* Table */}
        {isLoading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <Loader />
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <div className="bg-white overflow-x-auto ">
            {/* Table */}
            <table className="table-fixed min-w-full ">
              <thead className=" shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                <tr className=" py-[1.438rem] px-[1.375rem] flex items-center ">
                  <th className="px-2 first:pl-0 last:pr-5 py-3 whitespace-nowrap w-[140px]">
                    <div className="text-left font-medium">Date Changed</div>
                  </th>
                  <th className="px-2 pl-4 py-3 whitespace-nowrap w-[15%]">
                    <div className="text-left font-medium">User</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[25%]">
                    <div className="text-left font-medium">Event</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[25%]">
                    <div className="text-left  font-medium">Name</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[25%]">
                    <div className="text-left  font-medium">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {auditLogs?.auditLogs?.map((log) => (
                  <tr
                    className={`min-w-full px-[1.375rem] flex items-center  hover:bg-[#a0cd85] hover:bg-opacity-5`}
                    key={log?.id}
                  >
                    <td className="px-2 first:pl-0 last:pr-5 py-3 whitespace-nowrap w-[140px]">
                      <div className="text-left font-medium">
                        {moment(log?.updatedAt).format('MMM D, YYYY, HH:mm')}
                      </div>
                    </td>
                    <td className="px-2 pl-4 py-3 whitespace-nowrap w-[15%]">
                      <div className="text-left font-medium">
                        {log?.user
                          ? `${log?.user?.firstName} ${log?.user?.lastName}`
                          : log?.categoryMethod === 'Rule'
                            ? 'Automation'
                            : 'COUNT AI'}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[25%]">
                      <div className="text-left font-medium">
                        {log?.activity}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[25%]">
                      <div
                        className="text-left text-indigo-500 underline cursor-pointer font-medium"
                        onClick={() => {
                          setTransaction(log?.transaction);
                          setTransactionPanelOpen(true);
                        }}
                      >
                        {trimText(
                          log?.transaction?.description,
                          descriptionLength,
                        )}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[25%]">
                      <div className="text-left font-medium">
                        {createActionText(log)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
      {!isLoading && totalRecords > 0 && (
        <div className="mt-8">
          <PaginationClassic
            pagination={{ limit: localLimit, page, totalRecords }}
            setLimit={setLocalLimit}
            onPageChange={getAuditLogApi}
          />
        </div>
      )}
    </>
  );
};

export default AuditLog;
