import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { formatDateLocally, getFormattedDate } from 'utils/Utils';
import DatePickerDash from 'components/DatePickerDash';
import CategoriesDropdown from '../CategoriesDropdown';
import { createTransaction } from '../../../API/backend_helper';
import useClickOutside from '../../../utils/useClickOutside';
import Transition from '../../../utils/Transition';
import { useSelector } from 'react-redux';

const TransferTransaction = ({
  isOpen,
  setIsOpen,
  allCategories,
  accounts,
  allAccounts,
  getTransactionsApi,
  type,
  teamCurrency,
  currencies,
}) => {
  const formikRef = useRef(null);
  const dropdownRef = useRef(null);
  const { team } = useSelector((state) => state.Team);

  const [loading, setLoading] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [categoryGroup, setCategoryGroup] = useState(null);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(teamCurrency);

  useClickOutside(dropdownRef, () => {
    setTimeout(() => {
      setShowCurrencyDropdown(false);
    }, 100);
  });

  useEffect(() => {
    if (!isOpen) {
      formikRef.current?.resetForm();
      setSelectedCurrency(teamCurrency);
      setShowCurrencyDropdown(false);
    }
  }, [isOpen]);

  const options = useMemo(
    () => ({
      mode: 'single',
      enableTime: false,
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      maxDate: new Date(),
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      const modifiedData = { ...data };
      const res = await createTransaction({
        ...modifiedData,
        postedDate: getFormattedDate(
          modifiedData.postedDate,
          team?.defaults?.dateFormat,
        ),
        originalDescription: modifiedData.description,
      });
      toast.success('Transaction Created Successfully');
      formikRef.current?.resetForm();
      setIsOpen(false);
      await getTransactionsApi({});
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        accountId: null,
        description: '',
        amount: '',
        transferToAccountId: null,
        postedDate: formatDateLocally(new Date(), team?.defaults?.dateFormat),
        type: type,
        paymentChannel: 'online',
        currency: selectedCurrency?.isoCode,
      }}
      validationSchema={Yup.object({
        accountId: Yup.number().required('Please Select From Account'),
        description: Yup.string().required('Please Enter Description'),
        paymentChannel: Yup.string().optional('Please Select Payment Channel'),
        amount: Yup.string().required('Please Enter Amount'),
        transferToAccountId: Yup.string().required('Please Select Account'),
        postedDate: Yup.string().required('Please Enter Payment Date'),
        currency: Yup.string(),
      })}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(validation) => (
        <Form>
          <div className="px-10 flex flex-col gap-[1.875rem] items-start">
            <div className="flex flex-col sm:flex-row gap-4 items-start w-full">
              <div className="flex flex-col gap-2.5 items-start w-full sm:w-[19.563rem]">
                <label
                  className="block text-sm font-medium"
                  htmlFor="description"
                >
                  Description <span className="text-rose-500">*</span>
                </label>
                <input
                  id="description"
                  type="text"
                  placeholder="Write Description"
                  name="description"
                  className={`form-input w-full h-12 px-4 py-3.5 border-[#D0D5DD] text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80 ${
                    validation.touched.description &&
                    validation.errors.description &&
                    'border-rose-300'
                  }`}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ''}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.description}
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col gap-2.5 items-start w-full sm:w-[19.563rem]">
                <DatePickerDash
                  width="w-full"
                  label="Date"
                  required
                  placeholder="Select Date"
                  value={validation.values.postedDate}
                  onChange={(selectedDates) => {
                    validation.setFieldTouched('postedDate', true);
                    validation.setFieldValue('postedDate', selectedDates[0]);
                  }}
                  options={options}
                  inputClass={`px-4 py-2.5 h-12 rounded-[0.313rem] justify-between w-full text-base font-normal tracking-normal leading-6 text-slate-600 placeholder:text-base placeholder:font-normal placeholder:tracking-normal placeholder:text-slate-600 border-slate-600 hover:border-slate-600 cursor-pointer`}
                  name="postedDate"
                  id="postedDate"
                  onBlur={(e) => {
                    if (!e.target.classList?.value?.includes('active')) {
                      validation.setFieldTouched('postedDate', true);
                      validation.setFieldValue('postedDate', e.target.value);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex gap-4 items-start w-full">
              <div className="flex flex-col gap-2.5 items-start w-full sm:w-[19.563rem] relative">
                <label
                  className="block text-sm font-medium leading-5"
                  htmlFor="amount"
                >
                  Amount ({selectedCurrency?.isoCode}){' '}
                  <span className="text-rose-500">*</span>
                  <span
                    className="text-indigo-500 text-sm ml-1 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setShowCurrencyDropdown(!showCurrencyDropdown);
                    }}
                  >
                    {showCurrencyDropdown ? 'Cancel' : 'Change Currency'}
                  </span>
                </label>
                <input
                  id="amount"
                  type="number"
                  placeholder="0.00"
                  name="amount"
                  className={`form-input w-full rounded-[0.313rem] h-12 pl-11 border-[#D0D5DD] text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80 ${
                    validation.touched.amount &&
                    validation.errors.amount &&
                    'border-rose-300'
                  }`}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.amount || ''}
                  invalid={
                    validation.touched.amount && validation.errors.amount
                      ? true
                      : false
                  }
                />
                <span className="absolute top-[2.78rem] left-4 flex items-center gap-2">
                  <span className="text-slate-600">
                    {selectedCurrency?.symbol}
                  </span>
                  <div className="w-[0.038rem] h-[1.188rem] bg-slate-600"></div>
                </span>
                {validation.touched.amount && validation.errors.amount ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.amount}
                  </div>
                ) : null}
                <Transition
                  show={showCurrencyDropdown}
                  tag="div"
                  className="z-60 absolute top-20 left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                  enter="transition ease-out duration-100 transform"
                  enterStart="opacity-0 -translate-y-2"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-out duration-100"
                  leaveStart="opacity-100"
                  leaveEnd="opacity-0"
                >
                  {showCurrencyDropdown && (
                    <div
                      ref={dropdownRef}
                      className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
                      onFocus={() => setShowCurrencyDropdown(true)}
                      onBlur={() => setShowCurrencyDropdown(false)}
                    >
                      <div className="max-h-48 overflow-auto scrollbar">
                        {currencies?.map((currency, index) => (
                          <button
                            type="button"
                            key={index}
                            className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                              currency.id === selectedCurrency?.id &&
                              'text-indigo-500'
                            }`}
                            onClick={() => {
                              setSelectedCurrency(currency);
                              setShowCurrencyDropdown(false);
                            }}
                          >
                            {`${currency?.symbol} ${currency?.name}`}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </Transition>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 items-start w-full">
              <div className="flex flex-col gap-2.5 items-start w-full sm:w-[19.563rem]">
                <label
                  className="block text-sm font-medium"
                  htmlFor="accountId"
                >
                  Transfer From <span className="text-rose-500">*</span>
                </label>
                <CategoriesDropdown
                  allCategories={allAccounts.filter(
                    (account) =>
                      account.id !== validation.values.transferToAccountId,
                  )}
                  selectedCategoryId={validation.values.accountId}
                  setSelectedCategoryId={(value) => {
                    validation.setFieldValue('accountId', value);
                  }}
                  isSetCategoryStyle
                  id="accountId"
                  name="accountId"
                  height="h-12"
                  transferModal
                  type="account"
                />
                {validation.touched.accountId && validation.errors.accountId ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.accountId}
                  </div>
                ) : null}
              </div>
              <div
                className={`flex flex-col gap-2.5 items-start w-full sm:w-[19.563rem]`}
              >
                <label
                  className="block text-sm font-medium"
                  htmlFor="transferToAccountId"
                >
                  Transfer to <span className="text-rose-500">*</span>
                </label>
                <CategoriesDropdown
                  allCategories={allAccounts.filter(
                    (account) => account.id !== validation.values.accountId,
                  )}
                  selectedCategoryId={validation.values.transferToAccountId}
                  setSelectedCategoryId={(value) => {
                    validation.setFieldValue('transferToAccountId', value);
                  }}
                  isSetCategoryStyle
                  id="transferToAccountId"
                  name="transferToAccountId"
                  height="h-12"
                  transferModal
                  type="account"
                />
                {validation.touched.transferToAccountId &&
                validation.errors.transferToAccountId ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.transferToAccountId}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col items-start gap-2.5 w-full">
              <label
                className="block text-sm text-slate-600 font-medium leading-5"
                htmlFor="notes"
              >
                Note
              </label>
              <textarea
                name="notes"
                id="notes"
                placeholder="Write A Note"
                className={`form-textarea px-4 py-2.5 rounded-[0.313rem] justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80 ${
                  validation.touched.notes &&
                  validation.errors.notes &&
                  'border-rose-300'
                }`}
                type="notes"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.notes || ''}
              />
              {validation.touched.notes && validation.errors.notes ? (
                <div className="text-xs mt-1 text-rose-500">
                  {validation.errors.notes}
                </div>
              ) : null}
            </div>
          </div>
          {/* Modal footer */}
          <div className="w-full h-[6.125rem] mt-[1.875rem] border-t border-[#D0D5DD] px-10">
            <div className="flex justify-end gap-4 items-center w-full h-full">
              <button
                type="button"
                className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-indigo-500 text-white font-normal text-base leading-6 shadow-sm
                  disabled:bg-indigo-400    disabled:cursor-not-allowed"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Add
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TransferTransaction;
