import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import Picker from 'emoji-picker-react';
import { addComment, getCommentsApi } from '../../API/backend_helper';
import { ADMIN, OWNER, VIEWER } from '../../utils/Utils';
import '../../../src/css/custom.css';
import useClickOutside from '../../utils/useClickOutside';
import CountIcon from '../../images/AuthSlider/C-symbol.svg';
import Transition from '../../utils/Transition';
import { Loader } from '../../components/Svg';

const CommentsPanel = ({
  transaction,
  reloadTransaction,
  loadSingleTransaction,
}) => {
  const emojiPickerRef = useRef(null);
  const dropdown = useRef(null);
  const inputRef = useRef(null);
  const { accessType, localUser } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);

  const [members, setMembers] = useState([]);
  const [comments, setComments] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [mentionIds, setMentionIds] = useState([]);
  const [emojiPickerState, setEmojiPicker] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    inputRef.current.focus();
  }, [btnClicked]);

  useEffect(() => {
    if (!dropdownOpen) {
      setBtnClicked(false);
    }
  }, [dropdownOpen]);

  const getMembers = () => {
    const filteredMembers = team?.users?.filter(
      (user) => user.credentials.role !== 'viewer',
    );
    setMembers(filteredMembers);
  };

  const onEmojiClick = (event, emojiData) => {
    setNewComment((prevComment) => prevComment + event.emoji);
  };

  useClickOutside(emojiPickerRef, () => {
    setEmojiPicker(false);
  });

  useClickOutside(dropdown, () => {
    if (dropdownOpen) {
      setTimeout(() => {
        setDropdownOpen(false);
      }, 100);
    }
  });

  let emojiPicker;
  if (emojiPickerState) {
    emojiPicker = (
      <Picker
        onEmojiClick={onEmojiClick}
        searchDisabled={true}
        width={300}
        height={300}
        previewConfig={{
          showPreview: false,
        }}
        style={{ position: 'absolute', bottom: '5.2rem', right: '1.5rem' }}
      />
    );
  }

  const triggerEmojiPicker = (event) => {
    event.preventDefault();
    setEmojiPicker(!emojiPickerState);
  };

  const getComments = async (firstTime = false) => {
    try {
      const res = await getCommentsApi(transaction?.id);
      setComments(res.data.comments);
      const newCommentsCount =
        res.data.comments?.filter?.((c) => c?.senderId !== null)?.length || 0;
      const oldCommentsCount =
        comments?.filter?.((c) => c?.senderId !== null)?.length || 0;
      if (
        !firstTime &&
        ((newCommentsCount > 0 && oldCommentsCount === 0) ||
          (newCommentsCount === 0 && oldCommentsCount > 0))
      ) {
        await reloadTransaction({ scrollToTop: false });
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (transaction?.id) {
      loadData();
    }
  }, [transaction?.id]);

  const loadData = async () => {
    setIsLoading(true);
    getMembers();
    await getComments(true);
    setIsLoading(false);
  };

  const handleChange = (event, value, newPlainTextValue, mentions) => {
    setNewComment(value);
    setMentionIds(mentions.map((mention) => Number(mention.id)));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (isSubmitting) return;
    const cleanedComment = newComment.replace(/\[\/([^\]]+)\]/g, '/$1');

    try {
      const data = { message: cleanedComment, mentions: mentionIds };
      await addComment(transaction?.id, data);
      setNewComment('');
      setMentionIds([]);
      getComments();
      setIsSubmitting(false);
      await loadSingleTransaction(false);
    } catch (e) {
      console.log('error', e);
    }
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };

  const formatDate = (dateString) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const date = new Date(dateString);
    const dayOfWeek = days[date.getDay()];
    const day = date.getDate();
    const month = months[date.getMonth()];
    return `${dayOfWeek}, ${month} ${day}`;
  };

  const groupedComments = comments
    ? comments.reduce((groups, comment) => {
        const date = formatDate(comment.date);
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(comment);
        return groups;
      }, {})
    : {};

  const mentionsList = members
    .filter((member) => member.id !== localUser?.id)
    .map((member) => ({
      id: member.id,
      display: `${member.firstName} ${member.lastName}`,
      avatar: `member.avatarUrl`,
    }));

  const commandsList = [
    { display: '/categorize', id: 1 },
    { display: '/review', id: 2 },
  ];

  const transformAndStyleMentions = (text, id) => {
    const mentionRegex = /@\[([^\]]+)\]\(\d+\)/g;
    const parts = text.split(mentionRegex);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <span
            key={index}
            className={
              localUser?.id === id
                ? 'bg-white pl-2 pr-2 inline-flex justify-center items-center rounded-[1.25rem] text-indigo-500 leading-6 mr-1 ml-1'
                : 'bg-indigo-500 pl-2 pr-2 inline-flex justify-center items-center rounded-[1.25rem] text-white leading-6 mr-1 ml-1'
            }
          >
            {`@${part}`}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="flex w-full mt-10 items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col justify-between h-[90%] bg-white">
          <div className="ml-5 mt-8 pb-[1.375rem] h-full overflow-auto pr-5 scrollbar">
            <div className="flex flex-col gap-[1.375rem] self-stretch">
              {Object.entries(groupedComments).map(([date, comments]) => {
                const sortedComments = comments.sort(
                  (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
                );
                return (
                  <>
                    <div className="flex flex-row gap-4 items-center">
                      <div className="h-[0.063rem] bg-gray-500 bg-opacity-50 w-full"></div>
                      <p className="text-xs leading-5 text-slate-600 whitespace-nowrap">
                        {date}
                      </p>
                      <div className="h-[0.063rem] bg-gray-500 bg-opacity-50 w-full"></div>
                    </div>
                    {sortedComments &&
                      sortedComments.map((comment) => {
                        return comment.senderId === localUser?.id ? (
                          <div
                            key={comment?.id}
                            className={`relative bg-[#E3F1DA] rounded-xl rounded-br-none p-2.5 gap-1 inline-flex flex-col ml-auto items-end shadow-sm mt-2 min-w-10`}
                          >
                            <p className="text-sm leading-6 text-slate-600">
                              {transformAndStyleMentions(
                                comment.message,
                                comment.senderId,
                              )}
                            </p>
                            <div className="whitespace-nowrap flex items-center gap-3 absolute top-full right-0">
                              <p className="leading-4 text-[0.625rem] text-slate-600">
                                {formatTime(comment.createdAt)}
                              </p>
                              <svg
                                width="10"
                                height="7"
                                viewBox="0 0 10 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.52234 0.534267C9.48681 0.555979 8.53741 1.62776 7.23864 3.11601C6.01685 4.51741 5.01021 5.6642 5.00034 5.66815C4.99245 5.67012 4.50294 5.13719 3.91475 4.48386C3.17457 3.66276 2.81928 3.27984 2.76204 3.24826C2.55085 3.12983 2.29425 3.28378 2.29425 3.52854C2.29425 3.59565 2.30807 3.65091 2.33373 3.69434C2.35544 3.72986 2.65348 4.06936 2.99495 4.44833L3.61473 5.13522L3.3759 5.40958L3.13707 5.68591L2.21925 4.66742C0.975746 3.28773 0.95206 3.26207 0.879029 3.23247C0.738888 3.17325 0.55335 3.23049 0.476372 3.35681C0.421105 3.44761 0.417157 3.61144 0.47045 3.69434C0.543481 3.80882 2.94166 6.45372 2.99298 6.47741C3.06996 6.51096 3.22194 6.50701 3.29497 6.46556C3.3305 6.4478 3.51406 6.25239 3.70552 6.0333C3.89698 5.81223 4.05489 5.63262 4.05884 5.63262C4.06081 5.63262 4.22661 5.81421 4.42596 6.03725C4.63124 6.26424 4.81678 6.45372 4.85231 6.47149C4.92731 6.50701 5.04574 6.50899 5.13259 6.47938C5.17996 6.46359 5.78592 5.78065 7.5663 3.74171C8.86901 2.24556 9.95066 0.998113 9.96645 0.966532C10.0395 0.826391 9.98816 0.634932 9.85394 0.54611C9.77301 0.48887 9.61511 0.484922 9.52234 0.534267Z"
                                  fill="#2575F4"
                                />
                                <path
                                  d="M7.41484 0.815133C7.35365 0.852636 4.65151 3.93178 4.58242 4.04429C4.55676 4.08376 4.54492 4.141 4.54492 4.21403C4.54492 4.3443 4.59624 4.4351 4.70283 4.49037C4.78178 4.53379 4.93179 4.53971 5.00877 4.50418C5.06403 4.47852 7.80763 1.34806 7.86684 1.24542C7.93987 1.1112 7.8925 0.913824 7.7642 0.825002C7.67341 0.763814 7.50761 0.759867 7.41484 0.815133Z"
                                  fill="#2575F4"
                                />
                              </svg>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-start gap-4 mt-2 min-w-10">
                            {comment?.sender?.avatarUrl ||
                            comment?.senderId === null ? (
                              <img
                                src={
                                  comment?.senderId === null
                                    ? CountIcon
                                    : comment?.sender?.avatarUrl
                                }
                                alt="User avatar"
                                className="w-8 h-8 rounded-full"
                              />
                            ) : (
                              <div className="bg-indigo-500 text-white min-w-8 min-h-8 w-8 h-8 rounded-full flex items-center justify-center capitalize">
                                {comment?.sender?.firstName?.charAt(0)}
                              </div>
                            )}
                            <div
                              key={comment.id}
                              className={`bg-white rounded-xl rounded-tl-none p-2.5 gap-1 inline-flex flex-col mr-auto items-start border border-[#EFEFEF] relative`}
                            >
                              <div className="flex items-center">
                                <p className="font-medium text-[0.813rem] leading-6 text-slate-600 text-left">
                                  {comment?.senderId === null
                                    ? 'COUNT'
                                    : `${comment?.sender?.firstName} ${comment?.sender?.lastName}`}
                                </p>
                              </div>
                              <p className="text-sm leading-6 text-slate-600 text-opacity-80">
                                {transformAndStyleMentions(
                                  comment.message,
                                  comment.senderId,
                                )}
                              </p>
                              <div className="whitespace-nowrap flex items-center gap-3 absolute top-full left-0">
                                <p className="leading-4 text-[0.625rem] text-slate-600">
                                  {formatTime(comment.createdAt)}
                                </p>
                                <svg
                                  width="10"
                                  height="7"
                                  viewBox="0 0 10 7"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.52234 0.534267C9.48681 0.555979 8.53741 1.62776 7.23864 3.11601C6.01685 4.51741 5.01021 5.6642 5.00034 5.66815C4.99245 5.67012 4.50294 5.13719 3.91475 4.48386C3.17457 3.66276 2.81928 3.27984 2.76204 3.24826C2.55085 3.12983 2.29425 3.28378 2.29425 3.52854C2.29425 3.59565 2.30807 3.65091 2.33373 3.69434C2.35544 3.72986 2.65348 4.06936 2.99495 4.44833L3.61473 5.13522L3.3759 5.40958L3.13707 5.68591L2.21925 4.66742C0.975746 3.28773 0.95206 3.26207 0.879029 3.23247C0.738888 3.17325 0.55335 3.23049 0.476372 3.35681C0.421105 3.44761 0.417157 3.61144 0.47045 3.69434C0.543481 3.80882 2.94166 6.45372 2.99298 6.47741C3.06996 6.51096 3.22194 6.50701 3.29497 6.46556C3.3305 6.4478 3.51406 6.25239 3.70552 6.0333C3.89698 5.81223 4.05489 5.63262 4.05884 5.63262C4.06081 5.63262 4.22661 5.81421 4.42596 6.03725C4.63124 6.26424 4.81678 6.45372 4.85231 6.47149C4.92731 6.50701 5.04574 6.50899 5.13259 6.47938C5.17996 6.46359 5.78592 5.78065 7.5663 3.74171C8.86901 2.24556 9.95066 0.998113 9.96645 0.966532C10.0395 0.826391 9.98816 0.634932 9.85394 0.54611C9.77301 0.48887 9.61511 0.484922 9.52234 0.534267Z"
                                    fill="#2575F4"
                                  />
                                  <path
                                    d="M7.41484 0.815133C7.35365 0.852636 4.65151 3.93178 4.58242 4.04429C4.55676 4.08376 4.54492 4.141 4.54492 4.21403C4.54492 4.3443 4.59624 4.4351 4.70283 4.49037C4.78178 4.53379 4.93179 4.53971 5.00877 4.50418C5.06403 4.47852 7.80763 1.34806 7.86684 1.24542C7.93987 1.1112 7.8925 0.913824 7.7642 0.825002C7.67341 0.763814 7.50761 0.759867 7.41484 0.815133Z"
                                    fill="#2575F4"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </>
                );
              })}
            </div>
          </div>
          <div className="bg-white w-full p-[1.313rem] border-t border-gray-200 flex-grow-0 box-border relative">
            {/* Actions Menu */}
            <Transition
              show={dropdownOpen}
              tag="div"
              className="z-60 absolute bottom-full left-[1vw] w-fit bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 rounded shadow-lg mt-1"
              enter="transition ease-out duration-100 transform"
              enterStart="opacity-0 -translate-y-2"
              enterEnd="opacity-100 translate-y-0"
              leave="transition ease-out duration-100"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"
            >
              <div
                ref={dropdown}
                className="font-medium text-sm text-slate-600 dark:text-slate-300"
              >
                <p className="text-slate-600 text-base border-b border-[#D0D5DD] pl-3">
                  AI Prompts
                </p>
                <button
                  type="button"
                  className="flex items-center w-full hover:bg-slate-50 py-1 px-3 cursor-pointer"
                  onClick={() => {
                    setDropdownOpen(false);
                    setNewComment(
                      (prevComment) => prevComment + '/categorize ',
                    );
                    setBtnClicked(true);
                  }}
                >
                  /categorize:{' '}
                  <span className="text-slate-600 ml-1 pt-[2px] text-opacity-80 text-[11px] sm:text-xs">
                    Explain this transaction and let COUNT AI categorize it
                  </span>
                </button>
                <button
                  type="button"
                  className="flex items-center w-full hover:bg-slate-50 py-1 px-3 cursor-pointer"
                  onClick={() => {
                    setDropdownOpen(false);
                    setNewComment((prevComment) => prevComment + '/review ');
                    setBtnClicked(true);
                  }}
                >
                  /review:{' '}
                  <span className="text-slate-600 ml-1 pt-[2px] text-opacity-80 text-[11px] sm:text-xs">
                    Tag a workspace member to review this transaction
                  </span>
                </button>
              </div>
            </Transition>
            <form onSubmit={handleSubmit}>
              <div className="rounded-[36px] min-h-10 flex items-center gap-2.5 justify-between bg-[#F3F4F4]">
                {(accessType === OWNER || accessType === ADMIN) && (
                  <div
                    className="bg-indigo-500 text-white min-w-9 min-h-9 rounded-full flex items-center justify-center ml-2 text-[22px] cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdownOpen(!dropdownOpen);
                    }}
                  >
                    +
                  </div>
                )}
                <div className="w-[80%] max-w-[80%] min-h-10 bg-[#F3F4F4] pt-2 rounded-[36px] pl-2">
                  <MentionsInput
                    inputRef={(inputElement) => {
                      if (inputElement) {
                        inputRef.current = inputElement;
                      }
                    }}
                    forceSuggestionsAboveCursor={true}
                    value={newComment}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSubmit(e);
                      }
                    }}
                    style={{
                      input: {
                        border: 'none',
                        padding: '0 0 0 0',
                        width: '100%',
                        minHeight: '2.5rem',
                      },
                      suggestions: {
                        list: {
                          backgroundColor: 'white',
                          borderRadius: '0px 10px 10px 10px',
                          border: '1px solid #D0D5DD',
                          boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
                          display: 'inline-flex',
                          padding: '0.313rem',
                          flexGap: '1rem',
                          paddingTop: '0.25rem',
                          flexDirection: 'column',
                        },
                        highlighter: {},
                        item: {
                          padding: '0.188rem 0.625rem',
                          fontColor: '#000',
                          lineHeight: '1.5rem',
                          '&focused': {
                            backgroundColor: '#F1F1F1',
                            borderRadius: '0.313rem',
                          },
                        },
                      },
                    }}
                  >
                    <Mention
                      trigger="@"
                      data={mentionsList}
                      className="bg-indigo-500 bg-opacity-20 rounded-[1.25rem]"
                      renderSuggestion={(
                        suggestion,
                        search,
                        highlightedDisplay,
                        index,
                        focused,
                        highlighter,
                      ) => (
                        <div className="flex flex-row gap-2.5 items-center">
                          {suggestion.avatarUrl ? (
                            <img
                              src={suggestion.avatarUrl}
                              alt="User avatar"
                              className="w-6 h-6 rounded-full"
                            />
                          ) : (
                            <div className="bg-indigo-500 text-white w-6 h-6 rounded-full flex items-center justify-center">
                              {suggestion.display[0].toUpperCase()}
                            </div>
                          )}
                          <span>{highlightedDisplay}</span>
                        </div>
                      )}
                      displayTransform={(id, display) => `@${display}  `}
                    />
                    {accessType !== VIEWER && (
                      <Mention
                        trigger="/"
                        data={commandsList}
                        className=""
                        renderSuggestion={(
                          suggestion,
                          search,
                          highlightedDisplay,
                          index,
                          focused,
                          highlighter,
                        ) => (
                          <div className="flex flex-row gap-2.5 items-center">
                            <span>{highlightedDisplay}</span>
                          </div>
                        )}
                        displayTransform={(id, display) => `${display}  `}
                        markup="[__display__]"
                        appendSpaceOnAdd={true}
                      />
                    )}
                  </MentionsInput>
                </div>
                <div className="flex items-center">
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer mr-2"
                    onClick={(e) => triggerEmojiPicker(e)}
                  >
                    <path
                      d="M5.625 11.4727C6.49125 12.7184 7.94325 13.5352 9.57675 13.5352C11.2103 13.5352 12.654 12.7184 13.5285 11.4727"
                      stroke="#667085"
                      stroke-width="1.375"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.75 17.25C14.3063 17.25 18 13.5563 18 9C18 4.44365 14.3063 0.75 9.75 0.75C5.19365 0.75 1.5 4.44365 1.5 9C1.5 13.5563 5.19365 17.25 9.75 17.25Z"
                      stroke="#667085"
                      stroke-width="1.375"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <circle
                      cx="7.03984"
                      cy="6.52422"
                      r="0.825"
                      fill="#667085"
                    />
                    <circle
                      cx="12.2264"
                      cy="6.52422"
                      r="0.825"
                      fill="#667085"
                    />
                  </svg>
                  <button
                    type="submit"
                    className="bg-[#78BD4F] text-white leading-6 w-9 h-9 rounded-full font-sm flex items-center justify-center mr-2"
                  >
                    {isSubmitting ? (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin w-4 h-4 fill-current shrink-0"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      </div>
                    ) : (
                      <svg
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.54199 9H13.4587"
                          stroke="white"
                          stroke-width="0.885417"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.5 4.04102L13.4583 8.99935L8.5 13.9577"
                          stroke="white"
                          stroke-width="0.885417"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                  </button>
                  <div ref={emojiPickerRef}>{emojiPicker}</div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CommentsPanel;
