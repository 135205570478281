import React, { useEffect, useMemo, useState } from 'react';
import ModalBasic from '../../components/ModalBasic';
import emptyWalletImg from '../../images/custom/empty-wallet.svg';
import moment from 'moment';
import { amountColor, getCurrencySymbolFromIso } from '../../utils/Utils';
import Tooltip from '../../components/Tooltip';
import SearchForm from '../../partials/actions/SearchForm';
import PaginationClassic from '../../components/PaginationClassic';
import WarningBox from '../../components/WarningBox';
import SplitTransactionForm from './SplitTransactionForm';
import {
  getTransactions,
  splitTransactionsAPI,
} from '../../API/backend_helper';

const SPLIT_TRANSACTION_SCHEMA = {
  amount: {
    value: '',
    error: false,
  },
  categoryAccountId: {
    value: null,
    error: false,
  },
};
let searchTimeout = null;

const TransactionsModal = ({
  isOpen,
  setIsOpen,
  activeTransaction,
  setActiveTransaction,
  activeInstance,
  handleTransactionAssignment,
  updateInstanceTotal,
  type,
  allCategories,
  currencies,
}) => {
  const [splitTransactions, setSplitTransactions] = useState([
    SPLIT_TRANSACTION_SCHEMA,
  ]);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [isRefund, setIsRefund] = useState(false);

  //transactions
  const [transactions, setTransactions] = useState([]);
  const [transactionsSearch, setTransactionsSearch] = useState('');
  const [localLimit, setLocalLimit] = useState(7);
  const [transactionLoader, setTransactionLoader] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setActiveTransaction(null);
    }
  }, [isOpen]);

  const { page, totalRecords } = transactions;

  const onSave = async () => {
    setLoading(true);
    try {
      const res = await handleTransactionAssignment();
      if (res?.id) {
        setIsOpen(false);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const updateBillAmount = async () => {
    setLoading2(true);
    try {
      const res = await updateInstanceTotal(activeTransaction?.amount);
      if (res?.id) {
        const res2 = await handleTransactionAssignment();
        if (res2?.id) {
          setIsOpen(false);
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading2(false);
    }
  };

  // Split Transactions

  const handleTransactionValueChange = (value, field, index) => {
    if (field === 'amount' && value < 0) {
      return;
    }

    setSplitTransactions((prev) => {
      const tempTransactions = [...prev];
      const tempObj = { ...tempTransactions[index] };

      tempObj[field].value = value;
      if (value) {
        tempObj[field].error = false;
      } else {
        tempObj[field].error = true;
      }
      tempTransactions[index] = tempObj;
      return tempTransactions;
    });
  };

  const checkIsError = () => {
    let isError = false;
    splitTransactions.forEach((t, i) => {
      Object.keys(t).map((key) => {
        if (!t[key].value) {
          isError = true;
          handleTransactionValueChange(null, key, i);
        }
      });
    });
    return isError;
  };

  const createTransactionsSplits = async () => {
    try {
      if (checkIsError()) {
        return;
      }
      setLoading3(true);

      let splitsData = splitTransactions.map((s) => {
        return {
          amount: s.amount.value,
          categoryAccountId: s.categoryAccountId.value,
        };
      });
      const parentSplit = splitsData[0];
      splitsData = splitsData.slice(1);

      let data = {
        forAttachment: true,
        parent: {
          categoryAccountId: parentSplit.categoryAccountId,
          originalAmount: isRefund
            ? activeTransaction?.amount
            : activeTransaction?.amount * -1,
        },
        splits: splitsData,
      };

      if (splitTransactions.length <= 1) {
        data = {
          forAttachment: true,
          parent: {
            categoryAccountId: parentSplit?.categoryAccountId,
          },
          splits: [],
        };
      }

      const res = await splitTransactionsAPI(activeTransaction?.id, data);
      getTransactionsApi({});
      const res2 = await handleTransactionAssignment(res?.parent?.id);
      if (res2?.id) {
        setIsOpen(false);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading3(false);
    }
  };

  const calcSplitsSum = () => {
    let sum = 0;
    splitTransactions.forEach((s) => {
      sum += +s.amount.value;
    });
    return parseFloat(parseFloat(sum)?.toFixed(2));
  };

  const getTransactionsApi = async ({
    limit = localLimit,
    page = transactions?.page,
    search = transactionsSearch || '',
    loading = false,
    currency = isOpen?.products?.[0]?.currency,
    refund = isRefund,
  }) => {
    if (loading) {
      setTransactionLoader(true);
    }
    try {
      const transRes = await getTransactions({
        limit,
        page,
        search,
        transactionTypes: refund ? ['Expense'] : ['Income'],
        status: ['review'],
        currency,
      });
      setTransactions(transRes);
    } catch (e) {
      console.log('error', e);
    } finally {
      setTransactionLoader(false);
    }
  };

  useEffect(() => {
    if (isOpen?.id) {
      setIsRefund(false);
      getTransactionsApi({
        loading: true,
        currency: isOpen?.currency,
        page: 1,
        refund: false,
      });
    }
  }, [isOpen]);

  const onTransactionSearch = (val) => {
    setTransactionsSearch(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getTransactionsApi({
        search: encodeURIComponent(val || ''),
        page: 1,
      });
    }, 900);
  };

  const handleSplitTransaction = () => {
    createTransactionsSplits();
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title=""
      width="max-w-[1052px] flex flex-col"
      contentId="invoicesTransactionsTable"
    >
      <div className="px-10 flex justify-between items-center my-7 mb-1">
        <h3 className="text-[36px] leading-tight font-medium text-[#00000099]">
          Select a Transaction
        </h3>

        <SearchForm
          searchVal={transactionsSearch}
          setSearchVal={onTransactionSearch}
          placeholder="Search"
          transaction
        />
      </div>
      <p className="px-10 pl-11 mb-5 font-medium">
        Remaining balance :{' '}
        {getCurrencySymbolFromIso(isOpen?.currency, currencies)}
        {/* {isOpen?.paymentStatus === 'partial'
          ? parseFloat(isOpen?.total - isOpen?.paidAmount)?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
            )
          : */}
        {parseFloat(isOpen?.amountDue)?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </p>
      {transactionLoader ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8 min-h-[400px]">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              stroke-width="2.55882"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              stroke-width="2.55882"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto px-10  min-h-[200px]">
            <table className="table-fixed w-full">
              {/* Table header */}
              {/* <thead>
              <td colSpan={7}></td>
            </thead> */}
              <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
                <tr>
                  <th
                    className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-12`}
                  ></th>
                  <th
                    className={`px-2 first:pl-5 py-3 whitespace-nowrap w-[12rem]`}
                  >
                    <div
                      className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
                    >
                      <span className="text-slate-600 text-sm font-medium leading-[18px]">
                        Description
                      </span>
                    </div>
                  </th>
                  <th
                    className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[10rem]`}
                  >
                    <div
                      className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
                      // onClick={() => handleSortBy('date')}
                    >
                      <span className="text-slate-600 text-sm font-medium leading-[18px]">
                        Authorized On
                      </span>
                    </div>
                  </th>

                  <th
                    className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[7rem]`}
                  >
                    <div
                      className="text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
                      // onClick={() => handleSortBy('amount')}
                    >
                      <span className="text-slate-600 text-sm font-medium leading-[18px]">
                        Amount
                      </span>
                    </div>
                  </th>
                  <th
                    className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap sm:table-cell hidden w-[13rem]`}
                  >
                    <div className="text-slate-600 text-sm font-medium leading-[18px] text-left">
                      Category
                    </div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              {transactions?.transactions?.length > 0 && (
                <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                  {transactions?.transactions?.map((transaction, i) => (
                    <tr
                      key={transaction.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (activeTransaction?.id === transaction?.id) {
                          setActiveTransaction('');
                        } else {
                          setActiveTransaction(transaction);
                        }
                      }}
                      className={`cursor-pointer activeTransactionRow hover:bg-[#A0CD850F] ${
                        activeTransaction?.id === transaction?.id
                          ? 'bg-[#A0CD850F]'
                          : ''
                      } ${i === 0 ? '!border-none' : ''} `}
                    >
                      <td className="px-2 first:pl-5 last:pr-5 pt-2.5 pb-3 whitespace-nowrap w-12">
                        <div className="flex items-center">
                          <label className="inline-flex ">
                            <span className="sr-only">Select</span>
                            <input
                              id={transaction.id}
                              className="form-checkbox cursor-pointer h-5 w-5"
                              type="checkbox"
                              checked={activeTransaction?.id === transaction.id}
                            />
                          </label>
                        </div>
                      </td>
                      <td
                        className={`px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap w-[14.696rem]`}
                      >
                        <div className="flex items-center">
                          <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                            {transaction?.vendor?.logoUrl ||
                            transaction?.plaidCategoryIconUrl ? (
                              <img
                                className="rounded-full"
                                src={
                                  transaction?.vendor?.logoUrl ||
                                  transaction?.plaidCategoryIconUrl
                                }
                                alt={transaction?.description}
                              />
                            ) : (
                              <div
                                className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                                style={{
                                  backgroundColor: transaction?.category?.group
                                    ?.color
                                    ? transaction?.category?.group?.color
                                    : '',
                                }}
                              >
                                <div className="text-black text-xs font-normal uppercase">
                                  {transaction?.description
                                    ? transaction.description
                                        .split(' ')
                                        .slice(0, 2)
                                        .map((word) => word.charAt(0))
                                        .join('')
                                    : ''}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="font-normal text-slate-600 leading-6 mr-2">
                            {transaction?.description?.length > 19 ? (
                              <Tooltip
                                content={transaction?.description}
                                contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                              >
                                <span>
                                  {transaction?.description?.slice(0, 19) +
                                    '...'}
                                </span>
                              </Tooltip>
                            ) : (
                              <span>{transaction?.description}</span>
                            )}
                          </div>
                        </div>
                      </td>
                      <td
                        className={`px-2 first:pl-5 last:pr-5 py-2.5 leading-6 font-normal w-[9.156rem]`}
                      >
                        {moment
                          ?.utc(transaction?.authorizedDate)
                          ?.format('MMM DD, YYYY')}
                      </td>

                      <td
                        className={`px-2  py-2.5 whitespace-nowrap w-[8.659rem] `}
                      >
                        <div
                          className={`font-semibold leading-6 text-sm  ${amountColor(
                            transaction?.amount?.toString(),
                          )}`}
                        >
                          {transaction?.amount?.toString().charAt(0) === '-'
                            ? ''
                            : '-'}
                          {getCurrencySymbolFromIso(
                            isOpen?.currency,
                            currencies,
                          )}
                          {transaction?.amount?.toString().charAt(0) === '-'
                            ? parseFloat(
                                transaction?.amount
                                  ?.toString()
                                  .replace('-', ''),
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : parseFloat(transaction?.amount)?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )}
                        </div>
                      </td>
                      <td
                        className={`px-2 first:pl-5 last:pr-5 py-2.5 sm:table-cell hidden w-[13.75rem]`}
                      >
                        <Tooltip
                          content={`${'Cannot be edited once reviewed'}`}
                          contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                          size="sm"
                          tooltipShow={transaction?.reviewed}
                        >
                          <div className={`flex items-center gap-1`}>
                            <span className=" ps-1 inline-flex items-center gap-2 px-4">
                              {transaction?.pending || transaction?.excluded ? (
                                <span
                                  className={`text-xs inline-flex font-medium rounded-full text-center px-2
                                  bg-slate-100 dark:bg-slate-900 text-slate-500 dark:text-slate-400 }`}
                                >
                                  {transaction?.excluded
                                    ? 'Excluded'
                                    : 'Pending'}
                                </span>
                              ) : (
                                <span className="whitespace-nowrap">
                                  {' '}
                                  {transaction?.categoryAccount?.name
                                    ? transaction.categoryAccount.name.length >
                                      27
                                      ? `${transaction.categoryAccount.name.slice(
                                          0,
                                          27,
                                        )}...`
                                      : transaction.categoryAccount.name
                                    : 'Uncategorized'}
                                </span>
                              )}

                              {/* Transaction Split Icon  */}
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`${
                                  !transaction?.split ? 'hidden' : ''
                                }`}
                              >
                                <path
                                  d="M14.1667 9.91634C14.9491 9.91634 15.5833 9.28208 15.5833 8.49967C15.5833 7.71727 14.9491 7.08301 14.1667 7.08301C13.3843 7.08301 12.75 7.71727 12.75 8.49967C12.75 9.28208 13.3843 9.91634 14.1667 9.91634Z"
                                  stroke="#667085"
                                  stroke-width="1.1"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M14.1667 4.25033C14.9491 4.25033 15.5833 3.61606 15.5833 2.83366C15.5833 2.05126 14.9491 1.41699 14.1667 1.41699C13.3843 1.41699 12.75 2.05126 12.75 2.83366C12.75 3.61606 13.3843 4.25033 14.1667 4.25033Z"
                                  stroke="#667085"
                                  stroke-width="1.1"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M14.1667 15.5833C14.9491 15.5833 15.5833 14.9491 15.5833 14.1667C15.5833 13.3843 14.9491 12.75 14.1667 12.75C13.3843 12.75 12.75 13.3843 12.75 14.1667C12.75 14.9491 13.3843 15.5833 14.1667 15.5833Z"
                                  stroke="#667085"
                                  stroke-width="1.1"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.83366 9.91634C3.61606 9.91634 4.25033 9.28208 4.25033 8.49967C4.25033 7.71727 3.61606 7.08301 2.83366 7.08301C2.05126 7.08301 1.41699 7.71727 1.41699 8.49967C1.41699 9.28208 2.05126 9.91634 2.83366 9.91634Z"
                                  stroke="#667085"
                                  stroke-width="1.1"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M4.25 8.5H12.75"
                                  stroke="#667085"
                                  stroke-width="1.1"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12.7503 2.83301H9.91699C8.50033 2.83301 7.79199 3.54134 7.79199 4.95801V12.0413C7.79199 13.458 8.50033 14.1663 9.91699 14.1663H12.7503"
                                  stroke="#667085"
                                  stroke-width="1.1"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                            {/* Render in Case of Category   */}
                          </div>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {!transactions?.transactions?.length > 0 && (
              <div className="flex flex-col gap-5 items-center justify-center w-full">
                <h6 className="text-center mt-4">No Transactions Found</h6>
                <img
                  src={emptyWalletImg}
                  alt="No transactions found"
                  className="h-[12.5rem]"
                />
              </div>
            )}
          </div>
          <div className=" my-4 px-10">
            {page >= 0 && (
              <PaginationClassic
                pagination={{
                  limit: localLimit,
                  page,
                  totalRecords,
                }}
                onPageChange={getTransactionsApi}
                setLimit={setLocalLimit}
                scrollIntoView={false}
              />
            )}
          </div>

          <footer className="px-10 py-[27px] border-t border-[#D0D5DD]">
            {activeTransaction &&
              parseFloat(
                parseFloat(
                  isRefund
                    ? activeTransaction?.amount
                    : activeTransaction?.amount * -1,
                )?.toFixed(2),
              ) > parseFloat(parseFloat(isOpen?.amountDue)?.toFixed(2)) &&
              !activeTransaction?.split && (
                <SplitTransactionForm
                  splitTransactions={splitTransactions}
                  setSplitTransactions={setSplitTransactions}
                  handleTransactionValueChange={handleTransactionValueChange}
                  activeTransaction={activeTransaction}
                  allCategories={allCategories}
                  instanceTotal={isOpen?.amountDue}
                  calcSplitsSum={calcSplitsSum}
                  currencies={currencies}
                  isOpen={isOpen}
                  isRefund={isRefund}
                />
              )}
            {/* In case of Transaction Amount is lower than Bill Amount  */}
            {parseFloat(
              parseFloat(
                isRefund
                  ? activeTransaction?.amount
                  : activeTransaction?.amount * -1,
              )?.toFixed(2),
            ) < parseFloat(parseFloat(isOpen?.amountDue)?.toFixed(2)) && (
              <WarningBox
                className="mb-5"
                content={`The transaction amount is lower than the mentioned ${type} amount`}
              />
            )}
            {/* In case of Split Transaction and higher than bill amount */}
            {activeTransaction &&
              parseFloat(
                parseFloat(
                  isRefund
                    ? activeTransaction?.amount
                    : activeTransaction?.amount * -1,
                )?.toFixed(2),
              ) > parseFloat(parseFloat(isOpen?.amountDue)?.toFixed(2)) &&
              activeTransaction?.split && (
                <WarningBox
                  className="mb-5"
                  content={`Please select another transaction, as split transaction cannot be further divided`}
                />
              )}
            {/* <label className="whitespace-nowrap text-sm text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 flex items-center py-1 px-3 cursor-pointer ml-2 font-normal leading-6">
              <input
                type="checkbox"
                checked={isRefund}
                className="form-checkbox h-5 w-5 mr-2"
                onChange={() => {
                  setActiveTransaction(null);
                  getTransactionsApi({
                    refund: !isRefund,
                  });
                  setIsRefund(!isRefund);
                }}
              />
              Assign a Refund Transaction
            </label> */}
            <div className=" flex justify-end gap-4">
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                type="button"
                className="h-[42px] border border-[#667085] text-[#667085] rounded-[5px] font-medium w-[110px]"
              >
                Cancel
              </button>
              {/* {parseFloat(activeTransaction?.amount) <
            parseFloat(activeInstance?.total) && (
            <button
              onClick={updateBillAmount}
              type="button"
              disabled={
                !activeTransaction ||
                loading ||
                loading2 ||
                loading3 ||
                parseFloat(activeTransaction?.amount) >
                  parseFloat(activeInstance?.total)
              }
              className="inline-flex text-nowrap  w-auto px-3 justify-center items-center h-[42px]  disabled:text-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed rounded-[5px] font-medium  text-indigo-500 whitespace-nowrap bg-[#E486420D] border border-[#E48642]"
            >
              {loading2 && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Save and Change Bill Amount to $
              {parseFloat(activeTransaction?.amount)?.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}
            </button>
          )} */}
              {!(
                parseFloat(
                  parseFloat(
                    isRefund
                      ? activeTransaction?.amount
                      : activeTransaction?.amount * -1,
                  )?.toFixed(2),
                ) > parseFloat(parseFloat(isOpen?.amountDue)?.toFixed(2))
              ) && (
                <button
                  onClick={onSave}
                  type="button"
                  disabled={
                    !activeTransaction ||
                    loading ||
                    loading2 ||
                    loading3 ||
                    parseFloat(
                      parseFloat(
                        isRefund
                          ? activeTransaction?.amount
                          : activeTransaction?.amount * -1,
                      )?.toFixed(2),
                    ) > parseFloat(parseFloat(isOpen?.amountDue)?.toFixed(2))
                  }
                  className="inline-flex text-nowrap  w-auto px-3 justify-center items-center h-[42px] bg-indigo-500 disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed border border-indigo-500 text-white rounded-[5px] font-medium "
                >
                  {loading && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}
                  {parseFloat(
                    parseFloat(
                      isRefund
                        ? activeTransaction?.amount
                        : activeTransaction?.amount * -1,
                    )?.toFixed(2),
                  ) < parseFloat(parseFloat(isOpen?.amountDue)?.toFixed(2))
                    ? 'Save as Partial Payment'
                    : 'Save'}
                </button>
              )}

              {activeTransaction &&
                parseFloat(
                  parseFloat(
                    isRefund
                      ? activeTransaction?.amount
                      : activeTransaction?.amount * -1,
                  )?.toFixed(2),
                ) > parseFloat(parseFloat(isOpen?.amountDue)?.toFixed(2)) &&
                !activeTransaction?.split && (
                  <button
                    onClick={handleSplitTransaction}
                    type="button"
                    className="inline-flex text-nowrap  w-auto px-3 justify-center items-center h-[42px] bg-indigo-500 disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed border border-indigo-500 text-white rounded-[5px] font-medium "
                    disabled={
                      !activeTransaction ||
                      loading ||
                      loading2 ||
                      loading3 ||
                      calcSplitsSum() !==
                        (isRefund
                          ? parseFloat(
                              parseFloat(activeTransaction?.amount)?.toFixed(2),
                            )
                          : parseFloat(
                              parseFloat(
                                activeTransaction?.amount * -1,
                              )?.toFixed(2),
                            ))
                    }
                  >
                    {loading3 && (
                      <svg
                        className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg>
                    )}
                    Save and Split Transaction
                  </button>
                )}
            </div>
          </footer>
        </>
      )}
    </ModalBasic>
  );
};

export default TransactionsModal;
