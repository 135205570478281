import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { transformAccounts } from 'utils/Utils';
import { BackBtn } from '../../accounts/reconcileAccounts/Svg';
import {
  createTimeEntry,
  deleteTimeEntry,
  getAccountsByExpenseReporter,
  getAllProjects,
  getPeopleById,
  getPeopleWeeklyTime,
  getProducts,
  updatePeople,
  updateTimeEntry,
} from '../../../API/backend_helper';
import ModalBasic from '../../../components/ModalBasic';
import PeopleForm from '../PeopleForm';
import OverviewTab from './OverviewTab';
import TimesheetTab from './TimesheetTab';
import AppLayout from '../../../components/AppLayout';

const OVERVIEW_TAB = 'overview';
const TIME_SHEET_TAB = 'timesheet';

export const minutesToTimeString = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  let paddedHours = String(hours);
  let paddedMinutes = String(remainingMinutes);
  if (paddedHours.length < 2) {
    paddedHours = paddedHours.padStart(2, '0');
  }
  if (paddedMinutes.length < 2) {
    paddedMinutes = paddedMinutes.padStart(2, '0');
  }
  return `${paddedHours}:${paddedMinutes}`;
};

const PeopleDetails = () => {
  const { id } = useParams();
  const { team } = useSelector((state) => state.Team);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(OVERVIEW_TAB);
  const [isLoading, setIsLoading] = useState(true);
  const [people, setPeople] = useState({});
  const [peopleModalOpen, setPeopleModalOpen] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [peopleSelectedExpAcc, setPeopleSelectExpAcc] = useState([]);
  const [expenseReporterAccounts, setExpenseReporterAccounts] = useState([]);
  const [notAssignedAccounts, setNotAssignedAccounts] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(false);

  // Timesheet Tab Data
  const [tasks, setTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [timeSheetData, setTimeSheetData] = useState([]);

  const loadData = async () => {
    try {
      const res = await getPeopleById(id);
      setPeople(res?.data);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadWeeklyTime = async ({
    startDate = '',
    timeSlotsData = [],
  } = {}) => {
    try {
      const res = await getPeopleWeeklyTime(id, startDate);
      if (res?.length > 0) {
        const tempData = getWeekDates(startDate, false);

        res.forEach((entry, index) => {
          // Simply fill data for first entry
          if (index === 0) {
            tempData[0].projectId = entry?.projectId;
            tempData[0].productServiceId = entry?.productServiceId;
            tempData[0].isBillable = entry?.isBillable;
            const timeEntryIndex = tempData?.[0]?.timeEntries?.findIndex(
              (t) =>
                t?.date ===
                moment(entry?.date?.split('T')?.[0]).format('YYYY-MM-DD'),
            );
            tempData[0].timeEntries[timeEntryIndex] = {
              ...tempData[0].timeEntries[timeEntryIndex],
              id: entry?.id,
              time: minutesToTimeString(entry?.minutes),
            };
            // If entry is not first
          } else {
            const findObjIndex = tempData.findIndex(
              (d) =>
                d?.projectId === entry?.projectId &&
                d?.productServiceId === entry?.productServiceId &&
                d?.isBillable === entry?.isBillable,
            );
            const timeEntryIndex = tempData?.[0]?.timeEntries?.findIndex(
              (t) =>
                t?.date ===
                moment(entry?.date?.split('T')?.[0]).format('YYYY-MM-DD'),
            );

            const timeEntries = tempData[0]?.timeEntries?.map((t) => ({
              date: t?.date,
              time: '',
              error: '',
            }));
            // If Entry Index Found in data
            if (findObjIndex > -1) {
              let isFilledTimeSlot = false;

              // Check Data is not Filled in that time slot
              if (tempData?.[findObjIndex]?.timeEntries[timeEntryIndex]?.time) {
                isFilledTimeSlot = true;
              }
              // insert Data if slot is empty
              if (!isFilledTimeSlot) {
                tempData[findObjIndex].timeEntries[timeEntryIndex] = {
                  ...tempData[findObjIndex].timeEntries[timeEntryIndex],
                  id: entry?.id,
                  time: minutesToTimeString(entry?.minutes),
                };
              } else {
                // insert new row if slot is filled
                timeEntries[timeEntryIndex] = {
                  ...timeEntries[timeEntryIndex],
                  id: entry?.id,
                  time: minutesToTimeString(entry?.minutes),
                };
                const newRow = {
                  projectId: entry?.projectId,
                  productServiceId: entry?.productServiceId,
                  isBillable: entry?.isBillable,
                  timeEntries,
                };
                tempData.push(newRow);
              }
            } else {
              // insert new Row if no index found
              timeEntries[timeEntryIndex] = {
                ...timeEntries[timeEntryIndex],
                id: entry?.id,
                time: minutesToTimeString(entry?.minutes),
              };
              const newRow = {
                projectId: entry?.projectId,
                productServiceId: entry?.productServiceId,
                isBillable: entry?.isBillable,
                timeEntries,
              };
              tempData.push(newRow);
            }
          }
        });
        setTimeSheetData((prev) => {
          // set tempData if start date is still same on which api called
          if (
            prev?.[0]?.timeEntries?.[0]?.date ===
            tempData?.[0]?.timeEntries?.[0]?.date
          ) {
            return tempData;
          }
          return prev;
        });
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdatePeople = async (data, id) => {
    try {
      const temp = { ...data };
      if (!temp?.phone) {
        delete temp?.phone;
      }
      await updatePeople(temp, id);
      await loadData();
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadTasks = async () => {
    try {
      const res = await getProducts({ limit: 10000 });
      setTasks(res?.message?.products?.filter((p) => p?.timeEntryTask));
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadProjects = async ({ search = '' } = {}) => {
    try {
      const res = await getAllProjects({
        search,
      });
      setProjects(res);
    } catch (error) {
      console.error(error);
    }
  };

  const loadAccountsByExpenseReporter = async () => {
    setAccountsLoading(true);
    try {
      const accountsRes = await getAccountsByExpenseReporter(id);
      const accounts = [
        ...accountsRes?.data?.myAccounts,
        ...accountsRes?.data?.notAssignedAccounts,
      ];
      if (accountsRes?.data?.myAccounts?.length > 0) {
        setPeopleSelectExpAcc(
          accountsRes?.data?.myAccounts?.map((acc) => acc?.id),
        );
      }
      setAllAccounts(accounts);
      if (accountsRes) {
        setExpenseReporterAccounts(
          transformAccounts(accounts, 'PHYSICAL_ACCOUNT', 'expense'),
        );
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setAccountsLoading(false);
    }
  };

  useEffect(() => {
    if (team?.featureExpenseReport && id) {
      loadAccountsByExpenseReporter();
    }
  }, [team, id]);

  const getWeekDates = (specificDate, isSetData = true) => {
    const date = moment(specificDate);
    const startOfWeek = date.clone().startOf('week');
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      weekDates.push(startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD'));
    }

    const times = weekDates.map((d) => ({
      date: d,
      time: '',
      error: '',
    }));

    const tempData = {
      projectId: null,
      productServiceId: null,
      isBillable: true,
      timeEntries: times,
    };
    if (isSetData) {
      setTimeSheetData([tempData]);
    }
    return [tempData];
  };

  const loadAllData = async (timeSlotsData) => {
    setIsLoading(true);
    const startOfWeek = moment().clone().startOf('week').format('YYYY-MM-DD');
    try {
      await Promise.all([
        loadData(),
        loadTasks(),
        loadProjects(),
        loadWeeklyTime({ startDate: startOfWeek, timeSlotsData }),
      ]);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const startOfWeek = moment().clone().startOf('week').format('YYYY-MM-DD');
    const timeSlotsData = getWeekDates(startOfWeek);
    if (team?.id) {
      loadAllData(timeSlotsData);
    }
  }, [id, team]);

  const handleCreateTimeEntry = async (data) => {
    try {
      await createTimeEntry(data);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdateTimeEntry = async (id, data) => {
    try {
      await updateTimeEntry(id, data);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDeleteTimeEntry = async (id) => {
    try {
      await deleteTimeEntry(id);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <>
      <ModalBasic
        modalOpen={peopleModalOpen}
        setModalOpen={setPeopleModalOpen}
        title="Edit Person"
        width="!w-[1200px]"
      >
        <PeopleForm
          setActivePeople={setPeople}
          activePeople={people}
          handleUpdatePeople={handleUpdatePeople}
          setFormOpen={setPeopleModalOpen}
          isModalView
          team={team}
        />
      </ModalBasic>
      <AppLayout>
        <main className="relative grow ">
          {/* Content */}
          {isLoading || accountsLoading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              {/* Page header */}
              <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
                <div className="flex flex-row gap-2 sm:gap-0 justify-between">
                  <div className="flex items-center gap-5">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        navigate('/people');
                      }}
                      tabIndex="0"
                      role="button"
                    >
                      <BackBtn />
                    </div>
                    <div className="">
                      <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                        {people?.firstName} {people?.lastName}
                      </h1>
                      <h3 className="text-black text-opacity-60 text-[18px] leading-tight mt-1">
                        {people?.type}
                      </h3>
                    </div>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setPeopleModalOpen(true);
                    }}
                    className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                    type="button"
                  >
                    Edit
                  </button>
                </div>

                {/* Top Tabs  */}
                <div className="mt-10 flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
                  <div
                    onClick={() => setActiveTab(OVERVIEW_TAB)}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === OVERVIEW_TAB &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          }`}
                    tabIndex="0"
                    role="button"
                  >
                    Overview
                  </div>
                  {people?.role?.includes?.('time tracking') && (
                    <div
                      onClick={() => setActiveTab(TIME_SHEET_TAB)}
                      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === TIME_SHEET_TAB &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          }`}
                      tabIndex="0"
                      role="button"
                    >
                      Timesheets
                    </div>
                  )}
                </div>

                {activeTab === OVERVIEW_TAB && (
                  <OverviewTab
                    people={people}
                    allAccounts={allAccounts}
                    peopleSelectedExpAcc={peopleSelectedExpAcc}
                    setPeopleSelectExpAcc={setPeopleSelectExpAcc}
                    expenseReporterAccounts={expenseReporterAccounts}
                    setExpenseReporterAccounts={setExpenseReporterAccounts}
                    notAssignedAccounts={notAssignedAccounts}
                    setNotAssignedAccounts={setNotAssignedAccounts}
                    personId={id}
                    loadData={loadData}
                  />
                )}
                {activeTab === TIME_SHEET_TAB && (
                  <TimesheetTab
                    tasks={tasks}
                    projects={projects}
                    data={timeSheetData}
                    setData={setTimeSheetData}
                    getWeekDates={getWeekDates}
                    loadWeeklyTime={loadWeeklyTime}
                    handleCreateTimeEntry={handleCreateTimeEntry}
                    handleUpdateTimeEntry={handleUpdateTimeEntry}
                    handleDeleteTimeEntry={handleDeleteTimeEntry}
                    peopleId={+id}
                    minutesToTimeString={minutesToTimeString}
                  />
                )}
              </div>
            </>
          )}
        </main>
      </AppLayout>
    </>
  );
};

export default PeopleDetails;
