import React, { useEffect, useState, useContext, useRef } from 'react';
import moment from 'moment';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
import {
  getTeamById,
  getCurrenciesData,
  getJournalEntries,
  createJournalEntries,
  getMerchants,
  getCustomers,
  getAccounts,
  getAccountTypes,
  updateJournalEntry,
  deleteJournalEntry,
  getAllProjects,
  getTags,
  getProducts,
} from '../../API/backend_helper';
import SearchForm from '../../partials/actions/SearchForm';
import PaginationClassic from '../../components/PaginationClassic';
import Tooltip from '../../components/Tooltip';
import emptySearchImg from '../../images/custom/empty-search.svg';
import {
  VIEWER,
  TEAM_ID,
  transformAccounts,
  getContrast,
  ACCOUNT_TYPES,
  DevEnv,
  getIncDecAccountTypeStatus,
  getCurrencySymbolFromIso,
  trimText,
  getFormattedDate,
} from '../../utils/Utils';
import DeleteJournalEntryModal from './DeleteJournalEntryModal';
import JournalEntryForm from './JournalEntryForm';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import DateFilter from '../transactions/DateFilter';
import CategoriesFilter from '../transactions/Filters/CategoriesFilter';
import TransactionDetails from '../transactions/TransactionPanel';
import ReceiptModal from '../transactions/ReceiptModal';
import CreateRulePopup from '../../components/CreateRulePopup';
import CreateRuleModal from '../../components/CreateRuleModal';
import AppLayout from '../../components/AppLayout';

const DescriptionLength = 25;
let searchTimeout = null;

const JournalEntries = () => {
  const location = useLocation();

  const Env = process.env.REACT_APP_ENV;
  const descriptionTDRef = useRef(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId');
  const accountType = searchParams.get('accountType');
  const startId = searchParams.get('startId');
  const endId = searchParams.get('endId');
  // console.log("description Ref", descriptionTDRef.current?.clientWidth);

  const { accessType } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [isLoading, setIsLoading] = useState(false);
  const [flyoutIsLoading, setFlyoutIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [journalentries, setJournalEntries] = useState([]);
  const [activeEntry, setActiveEntry] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [localLimit, setLocalLimit] = useState(50);
  const [formOpen, setFormOpen] = useState(false);
  const [title, setTitle] = useState('Journal Entries');
  const [errMessage, setErrMessage] = useState('');
  const [vendors, setVendors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [teamCurrency, setTeamCurrency] = useState(null);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsRes, setAllAccountsRes] = useState([]);
  const [accountCategories, setAccountCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [accountLength, setAccountLength] = useState(20);
  const [addEditLoader, setAddEditLoader] = useState(false);
  const [deleteEntryLoader, setDeleteEntryLoader] = useState(false);
  const [projects, setProjects] = useState([]);
  const [tags, setTags] = useState([]);
  const [products, setProducts] = useState([]);

  // Flyout
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [currentTab, setCurrentTab] = useState('details');
  const [splitTransactionModalOpen, setSplitTransactionModalOpen] =
    useState(false);
  const [physicalAccounts, setPhysicalAccounts] = useState([]);
  const [fromDropdown, setFromDropdown] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [ruleModalOpen, setRuleModalOpen] = useState(false);
  const [popupCategory, setPopupCategory] = useState(null);
  const [popupTransaction, setPopupTransaction] = useState(null);

  // Filters
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [selectedAccountTypes, setSelectedAccountTypes] = useState([]);

  const getData = async ({
    limit = localLimit,
    page = journalentries?.page,
    search = searchValue || '',
    loading = false,
    accounts = selectedAccount || [],
    accountTypes = selectedAccountTypes || [],
    startDate = startDateFilter ? getFormattedDate(startDateFilter) : '',
    endDate = endDateFilter ? getFormattedDate(endDateFilter) : '',
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getJournalEntries({
        page,
        search,
        limit,
        accounts,
        startDate,
        endDate,
        accountType: accountTypes,
      });
      const temp = [];
      if (res?.data?.results?.length > 0) {
        const groupedObjects = res?.data?.results.reduce((acc, obj) => {
          const key = obj.journalLinkUuid;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});

        // Convert the object of arrays to an array of arrays
        const groupedArrays = Object.values(groupedObjects).map((group) => {
          const debits = group.filter((obj) => obj.amountDebit !== null);
          const credits = group.filter((obj) => obj.amountDebit === null);
          const groupObj = { data: debits.concat(credits) };
          groupObj.debitTotal =
            debits?.reduce?.(
              (acc, obj) => acc + parseFloat(obj?.amountDebit),
              0,
            ) || 0;
          groupObj.creditTotal =
            credits?.reduce?.(
              (acc, obj) => acc + parseFloat(obj?.amountCredit),
              0,
            ) || 0;
          return groupObj;
        });
        res.data.results = groupedArrays;
      }
      setJournalEntries(res?.data);
      setIsReset(false);
      if (loading) {
        setIsLoading(false);
      }
    } catch (e) {
      if (loading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const getTagsApi = async () => {
    try {
      const res = await getTags();
      setTags(res);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getProductsApi = async () => {
    try {
      const res = await getProducts({ limit: 10000 });
      setProducts(res?.message?.products);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadProjects = async () => {
    try {
      const res = await getAllProjects({});
      const filteredProjects = res?.filter(
        (project) =>
          project?.status === 'Not started' ||
          project?.status === 'In progress',
      );
      setProjects(filteredProjects);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handlePopupLogic = (category, transaction) => {
    setPopupCategory(category);
    setPopupTransaction(transaction);
    setPopupOpen(true);
  };

  useEffect(() => {
    if (formOpen || flyoutOpen) {
      shutdown();
    } else {
      boot();
    }
  }, [formOpen, flyoutOpen]);

  const handleDeleteEntry = async (id) => {
    setDeleteEntryLoader(true);
    try {
      await deleteJournalEntry(id);
      getData({});
      setActiveEntry(null);
      setModalOpen(false);
      setFormOpen(false);
      setDeleteEntryLoader(false);
    } catch (err) {
      console.log('error', err);
      if (err.message) {
        setErrMessage(JSON.parse(err.message));
        setModalOpen(true);
      } else {
        setModalOpen(false);
      }
      setDeleteEntryLoader(false);
    }
  };

  const onSearch = (val) => {
    setSearchValue(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getData({
        page: 1,
        search: encodeURIComponent(val || ''),
        loading: true,
      });
    }, 900);
  };

  const loadAllVendors = async () => {
    try {
      const res = await getMerchants({ limit: 10000 });
      setVendors(res?.vendors);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (location?.state?.journalEntry?.length > 0) {
      setFlyoutOpen(false);
      setTitle('Edit Journal Entry');
      setActiveEntry(location?.state?.journalEntry);
      setFormOpen(true);
    }
  }, [location?.state?.journalEntry]);

  const getCustomersApi = async () => {
    try {
      const res = await getCustomers({});
      setCustomers(res?.data?.records);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getAccountTypesApi = async () => {
    try {
      const typeResponse = await getAccountTypes();
      setTypes(typeResponse);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getAccountsApi = async () => {
    try {
      const accountsRes = await getAccounts();
      setAllAccountsRes(accountsRes);
      setAllAccounts(transformAccounts(accountsRes, 'allAccounts'));
      setAccountCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
      );
      setExpenseCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
      setPhysicalAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (accountId !== null) {
      setSelectedAccount([+accountId]);
    }
    if (accountType !== null) {
      setSelectedAccountTypes([accountType]);
    }
    if (startId !== null) {
      setStartDateFilter(startId);
    }
    if (endId !== null) {
      setEndDateFilter(endId);
    }
    const matchingCurrency = currencies?.find?.(
      (currency) => currency?.isoCode === team?.currency,
    );
    setTeamCurrency(matchingCurrency);
    getData({ loading: true });
    loadAllVendors();
    loadProjects();
    getTagsApi();
    getProductsApi();
    getCustomersApi();
    getAccountsApi();
    getAccountTypesApi();
  }, []);

  document.title = 'COUNT | Journal Entries';

  const { page, filters, totalRecords, results } = journalentries || {};

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setAccountLength(40);
      } else if (window.innerWidth > 1700) {
        setAccountLength(35);
      } else if (window.innerWidth > 992) {
        setAccountLength(20);
      } else {
        setAccountLength(20);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onDateChange = async (startDate, endDate) => {
    await getData({
      startDate: startDate ? getFormattedDate(startDate) : '',
      endDate: endDate ? getFormattedDate(endDate) : '',
      page: 1,
      loading: true,
    });
  };

  const handleResetFilters = async () => {
    setSelectedAccount([]);
    setStartDateFilter('');
    setEndDateFilter('');
    setSelectedAccountTypes([]);
    setSearchValue('');
    setIsReset(true);
    await getData({
      page: 1,
      accounts: [],
      accountTypes: [],
      startDate: '',
      endDate: '',
      search: '',
      loading: true,
    });
  };

  const onDelete = () => {
    setErrMessage('');
    setFlyoutOpen(false);
    setModalOpen(true);
  };

  return (
    <>
      {/* <JournalEntryModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        handleAddEntry={handleAddEntry}
        activeEntry={activeEntry}
        handleUpdateEntry={handleUpdateEntry}
        setActiveEntry={setActiveEntry}
        setFormOpen={setFormOpen}
        setTitle={setTitle}
      /> */}
      <DeleteJournalEntryModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        handleDeleteEntry={handleDeleteEntry}
        activeEntry={activeEntry}
        errMessage={errMessage}
        loading={deleteEntryLoader}
      />
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
      />
      <CreateRuleModal
        open={ruleModalOpen}
        setOpen={setRuleModalOpen}
        category={popupCategory}
        transaction={popupTransaction}
        reloadTransactions={getData}
      />
      {popupOpen && (
        <CreateRulePopup
          open={popupOpen}
          setOpen={setPopupOpen}
          category={popupCategory}
          transaction={popupTransaction}
          openModal={setRuleModalOpen}
        />
      )}

      <AppLayout pageId="journalEntriesContainer">
        <main className="relative grow ">
          {/* <JournalEntryFlyout
              flyoutOpen={flyoutOpen}
              setFlyoutOpen={setFlyoutOpen}
              activeEntry={activeEntry}
              setActiveEntry={setActiveEntry}
              activeEntryTransactions={activeEntryTransactions}
              activeEntryAnalytics={activeEntryAnalytics}
              goToTransactions={goToTransactions}
              handleUpdateEntry={handleUpdateEntry}
              flyoutIsLoading={flyoutIsLoading}
              setTitle={setTitle}
              setFormOpen={setFormOpen}
              currency={currencies?.find?.(c => c.isoCode === team?.currency)}
            /> */}
          {selectedRow && (
            <TransactionDetails
              transaction={selectedRow?.transaction}
              setTransaction={setSelectedRow}
              transactionPanelOpen={flyoutOpen}
              setTransactionPanelOpen={setFlyoutOpen}
              accounts={physicalAccounts}
              allCategories={accountCategories}
              vendors={vendors}
              setVendors={setVendors}
              customers={customers}
              allExpenseCategories={expenseCategories}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              reloadTransaction={getData}
              setSplitTransactionModalOpen={setSplitTransactionModalOpen}
              setFromDropdown={setFromDropdown}
              fromDropdown={fromDropdown}
              receiptModalOpen={receiptModalOpen}
              setReceiptModalOpen={setReceiptModalOpen}
              activeReceipt={activeReceipt}
              setActiveReceipt={setActiveReceipt}
              journalEntry
              ruleModalOpen={ruleModalOpen}
              setRuleModalOpen={setRuleModalOpen}
              setPopupOpen={setPopupOpen}
              setPopupCategory={setPopupCategory}
              setPopupTransaction={setPopupTransaction}
              handlePopupLogic={handlePopupLogic}
              projects={projects}
              team={team}
              currencies={currencies}
              tags={tags}
              setTags={setTags}
            />
          )}
          {/* Content */}
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {/* Page header */}
            <div className="flex justify-between items-center mb-10">
              <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                {title}
              </h1>
              {!formOpen ? (
                <div className="sm:flex gap-4 items-center">
                  <SearchForm
                    placeholder="Search"
                    searchVal={searchValue}
                    setSearchVal={onSearch}
                    transaction
                  />
                  {accessType !== VIEWER && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveEntry(null);
                        setTitle('Add Journal Entry');
                        setFormOpen(true);
                      }}
                      type="button"
                      className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => setFormOpen(true)}
                      >
                        <path
                          d="M10 4.16699V15.8337M4.16669 10.0003H15.8334"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Add Journal Entry
                    </button>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => {
                    setActiveEntry(null);
                    setTitle('Journal Entries');
                    setFormOpen(false);
                  }}
                  className=""
                  type="button"
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="6.17688"
                      y="7.82422"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(-45 6.17688 7.82422)"
                      fill="#667085"
                    />
                    <rect
                      x="20.1771"
                      y="6.17676"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(45 20.1771 6.17676)"
                      fill="#667085"
                    />
                  </svg>
                </button>
              )}
            </div>
            {!formOpen && (
              <div className="w-full mb-4 mt-6 flex sm:flex-row flex-col justify-between items-center gap-4 sm:gap-7">
                <div className="flex-1 flex gap-4 flex-wrap">
                  <div className="flex-1">
                    <CategoriesFilter
                      allCategories={allAccounts}
                      selectedCategoryId={selectedAccount}
                      setSelectedCategoryId={setSelectedAccount}
                      getTransactionsApi={getData}
                      apiFilter={filters?.accounts}
                      isReset={isReset}
                      type="account"
                      journalEntries
                    />
                  </div>
                  <BasicCheckboxDropdown
                    selectedOptions={selectedAccountTypes}
                    setSelectedOptions={setSelectedAccountTypes}
                    options={ACCOUNT_TYPES}
                    apiFunc={getData}
                    onApply={(values) => {
                      getData({
                        page: 1,
                        accountTypes: values,
                        loading: true,
                      });
                    }}
                    onClear={() => {
                      setSelectedAccountTypes([]);
                      getData({
                        page: 1,
                        accountTypes: [],
                        loading: true,
                      });
                    }}
                    label="Account Type"
                    type="accountTypes"
                    apiFilter={filters?.accountType}
                  />

                  <div className="flex-1">
                    <DateFilter
                      startDateFilter={startDateFilter}
                      endDateFilter={endDateFilter}
                      getTransactionsApi={getData}
                      setStartDateFilter={setStartDateFilter}
                      setEndDateFilter={setEndDateFilter}
                      onDateChange={onDateChange}
                      apiFilter={filters || {}}
                      align="left"
                    />
                  </div>

                  <div className="flex gap-4 ml-auto min-w-max ">
                    <button
                      onClick={handleResetFilters}
                      className="btn h-10 py-0 px-4 font-normal text-sm leading-6 border border-red-400 text-red-400 bg-[#FF4B4B05]"
                      type="button"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-1 f"
                      >
                        <path
                          d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                          fill="#FF4B4B"
                        />
                      </svg>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            )}
            {formOpen && (
              <JournalEntryForm
                setActiveEntry={setActiveEntry}
                activeEntry={activeEntry}
                onDeleteJournalEntry={onDelete}
                setFormOpen={setFormOpen}
                setTitle={setTitle}
                vendors={vendors}
                setVendors={setVendors}
                customers={customers}
                currencies={currencies}
                teamCurrency={teamCurrency}
                allAccounts={allAccounts}
                accountCategories={accountCategories}
                types={types}
                loading={addEditLoader}
                projects={projects}
                tags={tags}
                getData={getData}
                setAddEditLoader={setAddEditLoader}
                setCustomers={setCustomers}
                products={products}
                allAccountsRes={allAccountsRes}
              />
            )}
            {!formOpen && (
              <>
                {/* Table */}
                {isLoading ? (
                  <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                    <svg
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="animate-spin w-10 h-10 fill-current shrink-0"
                    >
                      <path
                        d="M14.5 2.41699V7.25033"
                        stroke="#E48642"
                        strokeWidth="2.55882"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.5 21.749V26.5824"
                        stroke="#E48642"
                        strokeWidth="2.55882"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.95703 5.95703L9.37662 9.37662"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.623 19.6211L23.0426 23.0407"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.41699 14.5H7.25033"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21.749 14.5H26.5824"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.95703 23.0407L9.37662 19.6211"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.623 9.37662L23.0426 5.95703"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                      Fetching data, please wait...
                    </p>
                  </div>
                ) : (
                  <div className="bg-white overflow-auto">
                    {/* Table */}
                    <table className="table-fixed min-w-full">
                      {/* Table header */}
                      <thead className=" shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                        <tr className=" py-[1.438rem] px-[1.375rem] h-16">
                          <th className="px-2 first:pl-0 last:pr-5 py-3 whitespace-nowrap w-[110px] min-w-[110px]">
                            <div className="text-left font-medium px-4">
                              Date
                            </div>
                          </th>
                          <th className="px-2 pl-4 py-3 whitespace-nowrap min-w-[200px] w-[200px]">
                            <div className="text-left font-medium">
                              Description
                            </div>
                          </th>

                          <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[15%] w-[15%]">
                            <div className="text-left font-medium">Account</div>
                          </th>
                          {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[8%]">
                                <div className="text-left font-medium">
                                  Customer
                                </div>
                              </th> */}
                          <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  min-w-[10%] w-[10%]">
                            <div className="text-left  font-medium">Type</div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  min-w-[10%] w-[10%]">
                            <div className="text-left  font-medium">Tags</div>
                          </th>
                          {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[9%]">
                                <div className="text-left  font-medium">
                                  Amount
                                </div>
                              </th> */}
                          <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[140px] w-[140px]">
                            <div className="text-left  font-medium">
                              Amount Debit
                            </div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[140px] w-[140px]">
                            <div className="text-left  font-medium">
                              Amount Credit
                            </div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[120px] w-[120px]">
                            <div className="text-left  font-medium">
                              Amount (+/-)
                            </div>
                          </th>
                          {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  min-w-[100px] w-[100px]">
                              <div className="text-left font-medium">
                                Actions
                              </div>
                            </th> */}
                        </tr>
                      </thead>
                      {/* Table body */}
                      {results?.length > 0 && (
                        <tbody className="text-sm">
                          {results?.map((journalEntries, i) => (
                            <>
                              {i !== 0 && <tr className="h-[25px]" />}
                              {journalEntries?.data?.map(
                                (journalEntry, index) => (
                                  <tr
                                    key={journalEntry.id}
                                    className={`min-w-full px-[1.375rem]  hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer
                                 ${
                                   journalEntries?.data?.length - 1 === index &&
                                   'border-b border-gray-300'
                                 }
                                  ${
                                    activeEntry?.id === journalEntry?.id
                                      ? 'bg-[#a0cd85] bg-opacity-5'
                                      : ''
                                  }`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        journalEntry?.transactionId ||
                                        journalEntry?.billInstanceId ||
                                        journalEntry?.invoiceId
                                      ) {
                                        setActiveEntry(journalEntry);
                                        setSelectedRow(journalEntry);

                                        if (journalEntry?.transactionId) {
                                          setFlyoutOpen(true);
                                        } else if (
                                          journalEntry?.billInstanceId
                                        ) {
                                          navigate('/bills', {
                                            state: {
                                              activeBillId:
                                                journalEntry?.billInstanceId,
                                            },
                                          });
                                        } else if (journalEntry?.invoiceId) {
                                          navigate(
                                            `/invoices?tab=invoices&id=${journalEntry?.invoiceId}&from=journalEntry`,
                                          );
                                        }
                                      } else if (!journalEntry?.systemCreated) {
                                        setFlyoutOpen(false);
                                        setTitle('Edit Journal Entry');
                                        setActiveEntry(journalEntries?.data);
                                        setFormOpen(true);
                                      }
                                    }}
                                  >
                                    <td className="pl-4 pr-4 text-sm py-1 whitespace-nowrap w-[110px] min-w-[110px]">
                                      <div className="">
                                        {moment
                                          ?.utc(journalEntry?.date)
                                          ?.format('MMM DD, YYYY')}
                                      </div>
                                    </td>
                                    <td className="px-2 pl-4 last:pr-5 text-sm py-1 whitespace-nowrap  min-w-[200px] w-[200px]">
                                      <div
                                        ref={descriptionTDRef}
                                        className="flex items-center descriptionTextTD"
                                      >
                                        <div
                                          className={`absolute top-0 descriptionText${index} invisible`}
                                        >
                                          {journalEntry?.descriptionLine
                                            ? journalEntry?.descriptionLine
                                            : journalEntry?.descriptionEntry}
                                        </div>
                                        <div className="font-normal max-w-full text-slate-600 leading-6">
                                          <Tooltip
                                            content={
                                              (journalEntry?.descriptionLine
                                                ? journalEntry?.descriptionLine
                                                    ?.length
                                                : journalEntry?.descriptionEntry
                                                    ?.length) >
                                              DescriptionLength
                                                ? (() => {
                                                    const segmentLength = 55;
                                                    const segments = [];
                                                    const length =
                                                      journalEntry?.descriptionLine
                                                        ? journalEntry
                                                            ?.descriptionLine
                                                            ?.length
                                                        : journalEntry
                                                            ?.descriptionEntry
                                                            ?.length;
                                                    for (
                                                      let i = 0;
                                                      i < length;
                                                      i += segmentLength
                                                    ) {
                                                      segments.push(
                                                        journalEntry?.descriptionLine
                                                          ? journalEntry?.descriptionLine?.substring(
                                                              i,
                                                              i + segmentLength,
                                                            )
                                                          : journalEntry?.descriptionEntry?.substring(
                                                              i,
                                                              i + segmentLength,
                                                            ),
                                                      );
                                                    }
                                                    return segments.map(
                                                      (segment, index) => (
                                                        <p key={index}>
                                                          {segment}
                                                        </p>
                                                      ),
                                                    );
                                                  })()
                                                : journalEntry?.descriptionLine
                                                  ? journalEntry?.descriptionLine
                                                  : journalEntry?.descriptionEntry
                                            }
                                            contentClassName={`border-none max-w-[500px] rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown ${
                                              (journalEntry?.descriptionLine
                                                ? journalEntry?.descriptionLine
                                                : journalEntry?.descriptionEntry
                                              )?.length > DescriptionLength
                                                ? ''
                                                : ''
                                            }`}
                                            tooltipShow={
                                              // document.querySelector(
                                              //   `.descriptionText${index}`
                                              // )?.clientWidth >
                                              // descriptionTDRef.current
                                              //   ?.clientWidth
                                              (journalEntry?.descriptionLine
                                                ? journalEntry?.descriptionLine
                                                : journalEntry?.descriptionEntry
                                              )?.length > DescriptionLength
                                            }
                                          >
                                            <div className="max-w-full">
                                              {(journalEntry?.descriptionLine
                                                ? journalEntry?.descriptionLine
                                                : journalEntry?.descriptionEntry
                                              )?.length > DescriptionLength
                                                ? `${(journalEntry?.descriptionLine
                                                    ? journalEntry?.descriptionLine
                                                    : journalEntry?.descriptionEntry
                                                  )?.slice(
                                                    0,
                                                    DescriptionLength,
                                                  )}...`
                                                : journalEntry?.descriptionLine
                                                  ? journalEntry?.descriptionLine
                                                  : journalEntry?.descriptionEntry}
                                              {/* {journalEntry?.descriptionEntry?.slice(
                                                0,
                                                30
                                              ) + "..."} */}
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </td>
                                    {(() => {
                                      const name =
                                        journalEntry?.account?.name || '';
                                      const mask =
                                        journalEntry?.account?.mask || '';
                                      const slicedName =
                                        name.length > accountLength
                                          ? `${name.slice(0, accountLength)}...`
                                          : name;
                                      const combinedAccount = mask
                                        ? `${slicedName} (...${mask})`
                                        : slicedName;
                                      return (
                                        <td className="px-2 first:pl-0 last:pr-5 py-2.5 whitespace-nowrap text-slate-600 font-sm font-normal leading-6 min-w-[15%] w-[15%]">
                                          <Tooltip
                                            tooltipShow={
                                              name.length > accountLength
                                            }
                                            content={`${
                                              journalEntry?.account?.name
                                            }${
                                              journalEntry?.account?.mask
                                                ? ` (...${journalEntry?.account?.mask})`
                                                : ''
                                            }`}
                                          >
                                            {combinedAccount}
                                          </Tooltip>
                                        </td>
                                      );
                                    })()}
                                    {/* <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap w-[8%]">
                                    <div className=" text-left">
                                      {journalEntry?.customer?.customer ||
                                        "---"}
                                    </div>
                                  </td> */}
                                    <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap min-w-[10%] w-[10%]">
                                      <div className="text-left">
                                        {journalEntry?.accountType}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap min-w-[10%] w-[10%]">
                                      <Tooltip
                                        tooltipShow={
                                          journalEntry?.tags?.length > 1
                                        }
                                        content={journalEntry?.tags?.map(
                                          (t, i) => (
                                            <p
                                              key={t?.id}
                                              className="text-sm text-center"
                                            >
                                              {t?.name}
                                              {journalEntry?.tags?.length -
                                                1 !==
                                                i && ','}
                                            </p>
                                          ),
                                        )}
                                        contentClassName="min-w-[80px] cursor-default"
                                        position="bottom"
                                        className=""
                                      >
                                        <div className="w-fit text-sm  text-nowrap cursor-default pb-px px-1  leading-tight rounded-sm">
                                          {trimText(
                                            journalEntry?.tags?.[0]?.name,
                                            8,
                                          )}{' '}
                                          {journalEntry?.tags?.length > 1 &&
                                            `+${journalEntry?.tags?.length - 1}`}
                                        </div>
                                      </Tooltip>
                                    </td>
                                    {/* <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap w-[9%]">
                                    <div className=" text-left">
                                      $
                                      {journalEntry?.amount
                                        ? parseFloat(
                                            journalEntry?.amount
                                          )?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                        : ""}
                                    </div>
                                  </td> */}
                                    <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap min-w-[140px] w-[140px]">
                                      <div className=" text-left flex items-center">
                                        {journalEntry?.amountDebit ? (
                                          getIncDecAccountTypeStatus(
                                            journalEntry?.accountType,
                                            'debit',
                                          ) === 'upArrow' ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="16"
                                              width="12"
                                              viewBox="0 0 384 512"
                                              className="w-4 h-3 ml-2  shrink-0 fill-current text-[#78BD4F]"
                                            >
                                              <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="16"
                                              width="12"
                                              viewBox="0 0 384 512"
                                              className={`w-4 h-3 ml-2 ${'rotate-180'} shrink-0 fill-current text-[#FF4B4B]`}
                                            >
                                              <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                                            </svg>
                                          )
                                        ) : (
                                          ''
                                        )}
                                        <span className="ml-2">
                                          {journalEntry?.amountDebit
                                            ? `${getCurrencySymbolFromIso(
                                                journalEntry?.currency,
                                                currencies,
                                              )}${parseFloat(
                                                journalEntry?.amountDebit,
                                              )?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}`
                                            : ''}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap min-w-[140px] w-[140px]">
                                      <div className=" text-left flex items-center">
                                        {journalEntry?.amountCredit ? (
                                          getIncDecAccountTypeStatus(
                                            journalEntry?.accountType,
                                            'credit',
                                          ) === 'upArrow' ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="16"
                                              width="12"
                                              viewBox="0 0 384 512"
                                              className="w-4 h-3 ml-2  shrink-0 fill-current text-[#78BD4F]"
                                            >
                                              <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="16"
                                              width="12"
                                              viewBox="0 0 384 512"
                                              className={`w-4 h-3 ml-2 ${'rotate-180'} shrink-0 fill-current text-[#FF4B4B]`}
                                            >
                                              <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                                            </svg>
                                          )
                                        ) : (
                                          ''
                                        )}
                                        <span className="ml-2">
                                          {journalEntry?.amountCredit
                                            ? `${getCurrencySymbolFromIso(
                                                journalEntry?.currency,
                                                currencies,
                                              )}${parseFloat(
                                                journalEntry?.amountCredit,
                                              )?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}`
                                            : ''}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap min-w-[120px] w-[120px]">
                                      <div className=" text-left">
                                        {journalEntry?.amount
                                          ?.toString()
                                          .charAt(0) === '-'
                                          ? '-'
                                          : ''}
                                        {getCurrencySymbolFromIso(
                                          journalEntry?.currency,
                                          currencies,
                                        )}
                                        {parseFloat(
                                          journalEntry?.amount
                                            ?.toString()
                                            .replace('-', ''),
                                        )?.toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </div>
                                    </td>
                                    {/* <td className="whitespace-nowrap min-w-[100px] w-[100px]">
                                    {index === 0 &&
                                      !journalEntry?.systemCreated && (
                                        <div className=" inline-flex items-center gap-3.5">
                                          {!(accessType === VIEWER) && (
                                            <Tooltip
                                              content="Edit Journal Entry Group"
                                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                            >
                                              <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                                <svg
                                                  width="28"
                                                  height="28"
                                                  viewBox="0 0 28 28"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setFlyoutOpen(false);
                                                    setTitle(
                                                      "Edit Journal Entry"
                                                    );
                                                    setActiveEntry(
                                                      journalEntries
                                                    );
                                                    setFormOpen(true);
                                                  }}
                                                >
                                                  <path
                                                    d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                                    fill="white"
                                                    stroke="#D0D5DD"
                                                    strokeOpacity="0.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                                    stroke="#858D9D"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                              </div>
                                            </Tooltip>
                                          )}
                                          {!(accessType === VIEWER) && (
                                            <Tooltip
                                              content="Delete Journal Entry"
                                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                            >
                                              <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                                <svg
                                                  width="28"
                                                  height="28"
                                                  viewBox="0 0 28 28"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setErrMessage("");
                                                    setFlyoutOpen(false);
                                                    setActiveEntry(
                                                      journalEntries
                                                    );
                                                    setModalOpen(true);
                                                  }}
                                                >
                                                  <path
                                                    d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                                    fill="white"
                                                    stroke="#D0D5DD"
                                                    strokeOpacity="0.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                                                    stroke="#858D9D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M12.693 16.3574H14.8505"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M12.1552 13.7656H15.3948"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                      )}
                                  </td> */}
                                  </tr>
                                ),
                              )}
                              <tr className="min-w-full px-[1.375rem]  hover:bg-[#a0cd85] hover:bg-opacity-5 border-b border-gray-300 cursor-default">
                                <td className="pl-4 pr-4 text-sm py-1 whitespace-nowrap w-[110px] min-w-[110px] font-bold">
                                  Total
                                </td>
                                <td
                                  className="px-2 pl-4 last:pr-5 text-sm py-1 whitespace-nowrap  min-w-[200px] w-[200px]"
                                  colSpan={4}
                                />
                                <td className="px-2 pl-4 last:pr-5 text-sm py-1 whitespace-nowrap  min-w-[200px] w-[200px] font-bold">
                                  {getCurrencySymbolFromIso(
                                    journalEntries?.data?.[0]?.currency,
                                    currencies,
                                  )}
                                  {parseFloat(
                                    journalEntries?.debitTotal,
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td className="px-2 pl-4 last:pr-5 text-sm py-1 whitespace-nowrap  min-w-[200px] w-[200px] font-bold">
                                  {getCurrencySymbolFromIso(
                                    journalEntries?.data?.[0]?.currency,
                                    currencies,
                                  )}
                                  {parseFloat(
                                    journalEntries?.creditTotal,
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      )}
                    </table>
                    {!results?.length > 0 && (
                      <div className="flex flex-col gap-5 items-center justify-center w-full">
                        <h6 className="text-center mt-6">
                          No Journal Entries Found
                        </h6>
                        <img
                          src={emptySearchImg}
                          alt="No journalentries found"
                          className="h-[12.5rem]"
                        />
                      </div>
                    )}
                  </div>
                )}
                {!isLoading && (
                  <div className="mt-10">
                    <PaginationClassic
                      setLimit={setLocalLimit}
                      onPageChange={(v) => getData({ ...v, loading: true })}
                      pagination={{ limit: localLimit, page, totalRecords }}
                      pageLoad
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default JournalEntries;
