import React, { useEffect, useRef, useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import useClickOutside from '../../utils/useClickOutside';
import ImageCropModal from '../../components/ImageCropModal';
import colorPickerImg from '../../images/custom/colorpicker.png';
import FontsDropdown from '../../components/FontsDropdown';
import InvoiceAttachments from './InvoiceAttachments';
import EditAttachmentModal from './EditAttachmentModal';
import { IS_PERSON } from '../../utils/Utils';
import { GoogleFonts } from '../../utils/Fonts';

const colorOptions = [
  '#667085',
  '#47ab6c',
  '#3d73de',
  '#8471d8',
  '#d85fb4',
  '#e99745',
  '#6bbe1d',
  '#39abbc',
  '#f75e5e',
];

const ColorPicker = ({ colorOptions, selectedColor, setSelectedColor }) => {
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const colorPicker = useRef();

  useClickOutside(colorPicker, () => {
    setColorPickerOpen(false);
  });

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  return (
    <div className="flex flex-row gap-[0.524rem] relative">
      <div className="flex flex-row gap-[0.524rem]">
        <img
          src={colorPickerImg}
          alt="color picker"
          className="cursor-pointer"
          onClick={() => {
            setColorPickerOpen(true);
          }}
        />
        <HexColorInput
          color={selectedColor}
          onChange={handleColorSelect}
          prefixed
          className="h-5 w-[4.1rem] border-[1.2px] border-[#D0D5DD] rounded-md text-slate-600 !text-xs px-1"
        />
      </div>
      {colorOptions.map((color, index) => (
        <button
          key={index}
          type="button"
          onClick={() => handleColorSelect(color)}
          className={`cursor-pointer w-[1.088rem] h-[1.088rem] rounded-full border-2 box-border ${
            color === selectedColor ? 'border-white' : 'border-transparent'
          }`}
          style={{
            background: color,
            boxShadow:
              color === selectedColor
                ? '0px 0px 10.482px rgba(0, 0, 0, 0.30)'
                : 'none',
          }}
        />
      ))}
      {colorPickerOpen && (
        <div ref={colorPicker} className="absolute z-50 top-8 left-0">
          <HexColorPicker color={selectedColor} onChange={handleColorSelect} />
        </div>
      )}
    </div>
  );
};

const InvoicePanel = ({
  invoicePanelOpen,
  setInvoicePanelOpen,
  selectedColor,
  setSelectedColor,
  setImgPreview,
  fontFamily,
  setFontFamily,
  setFiles,
  type,
  editInvoice,
  onColorFontSave,
  fontColorLoader,
  handleInvoiceAttach,
  attachFiles,
  setAttachFiles,
  attachLoader,
  setAttachLoader,
  getInvoiceById,
  setReceiptModalOpen,
  setActiveReceipt,
  accessType,
  receiptModalOpen,
  defaultAttachments,
}) => {
  const panelContent = useRef(null);

  // File Management

  const [cropModalOpen, setCropModalOpen] = useState(false);

  // Attachment Modal
  const [editAttachmentModalOpen, setEditAttachmentModalOpen] = useState(false);
  const [activeEditAttachment, setActiveEditAttachment] = useState(null);
  const [activeEditAttachmentIndex, setActiveEditAttachmentIndex] =
    useState(-1);
  const [localReceipts, setLocalReceipts] = useState([]);

  useClickOutside(panelContent, () => {
    if (!receiptModalOpen && !editAttachmentModalOpen) {
      setInvoicePanelOpen(false);
    }
  });

  const handleLocalFilenameUpdate = (newName) => {
    const tempLocalReceipts = [...localReceipts];
    const tempAttachFiles = [...attachFiles];

    const originalFile = tempLocalReceipts[activeEditAttachmentIndex];
    if (!originalFile?.id) {
      const blob = originalFile.slice(0, originalFile.size, originalFile.type);
      const renamedFile = new File([blob], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
      });
      renamedFile.preview = URL.createObjectURL(blob);
      tempLocalReceipts[activeEditAttachmentIndex] = renamedFile;
      tempAttachFiles[activeEditAttachmentIndex] = renamedFile;
    } else {
      tempLocalReceipts[activeEditAttachmentIndex] = {
        ...tempLocalReceipts[activeEditAttachmentIndex],
        name: newName,
      };
      tempAttachFiles[activeEditAttachmentIndex] = {
        ...tempAttachFiles[activeEditAttachmentIndex],
        name: newName,
      };
    }

    setLocalReceipts(tempLocalReceipts);
    setAttachFiles(tempAttachFiles);
  };

  useEffect(() => {
    if (!editAttachmentModalOpen) {
      setActiveEditAttachment(null);
    }
  }, [editAttachmentModalOpen]);

  return (
    <>
      <EditAttachmentModal
        isOpen={editAttachmentModalOpen}
        setIsOpen={setEditAttachmentModalOpen}
        activeEditAttachment={activeEditAttachment}
        getInvoiceById={getInvoiceById}
        editInvoice={editInvoice}
        handleLocalFilenameUpdate={handleLocalFilenameUpdate}
      />
      <div
        ref={panelContent}
        className={`absolute inset-0 bg-white sm:left-auto transition-transform duration-200 ease-in-out z-40 ${
          invoicePanelOpen ? 'translate-x-0  shadow-xl' : 'translate-x-full'
        }`}
      >
        <div
          className="sticky top-16 bg-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0  w-full sm:w-[510px] h-[calc(100dvh-64px)]"
          style={
            invoicePanelOpen
              ? { boxShadow: ' -10px 0px 15px 0px #D0D5DD80' }
              : {}
          }
        >
          <div className="relative flex flex-row gap-[1rem] sm:gap-[3.125rem] justify-start items-center mt-3 pb-[2px] w-full border-b-2 border-gray-300 pl-[1.625rem] bg-white whitespace-nowrap">
            {invoicePanelOpen === 'customize' && (
              <p
                className={`text-sm leading-6 font-normal cursor-pointer translate-y-1 ${
                  invoicePanelOpen === 'customize'
                    ? 'text-black border-b-2 border-indigo-500 '
                    : 'text-slate-600'
                }`}
                onClick={() => setInvoicePanelOpen('customize')}
              >
                Customize
              </p>
            )}
            {invoicePanelOpen === 'attachments' && (
              <p
                className={`text-sm leading-6 font-normal cursor-pointer translate-y-1 ${
                  invoicePanelOpen === 'attachments'
                    ? 'text-black border-b-2 border-indigo-500 '
                    : 'text-slate-600'
                }`}
                onClick={() => setInvoicePanelOpen('attachments')}
              >
                Attachments
              </p>
            )}
          </div>
          <div className=" flex justify-end pt-2.5 px-2.5 mb-4">
            <button
              className="group"
              onClick={() => setInvoicePanelOpen(false)}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="h-5 w-5 fill-[#667085] group-hover:fill-slate-700"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </button>
          </div>
          <div className={invoicePanelOpen === 'customize' ? '' : 'hidden'}>
            <ImageCropModal
              isOpen={cropModalOpen}
              setIsOpen={setCropModalOpen}
              image={null}
              setPreviewImage={setImgPreview}
              setFiles={setFiles}
              imgName=""
            />

            <div className="z-10">
              <div className="px-8">
                <div className="space-y-5">
                  <div className="flex flex-col gap-3 items-start">
                    <label
                      className="block text-sm text-slate-600 font-medium leading-5"
                      htmlFor="color"
                    >
                      {type} Color
                    </label>
                    <div className="h-[7.375rem] w-full py-3 flex flex-col justify-center items-center gap-2.5 self-stretch rounded-[10px] border border-dashed border-indigo-500 shadow-md">
                      <div className="flex flex-col justify-center items-center gap-3">
                        <div
                          className="w-[1.875rem] h-[1.875rem] rounded-full flex items-center justify-center text-black"
                          style={{
                            backgroundColor: selectedColor,
                            border: !selectedColor ? '1px solid black' : 'none',
                          }}
                        />

                        <p className="text-slate-600 text-sm">
                          Click to select the color
                        </p>
                        <ColorPicker
                          colorOptions={colorOptions}
                          selectedColor={selectedColor}
                          setSelectedColor={setSelectedColor}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      className="block mb-2 text-sm text-slate-600 font-medium leading-5"
                      htmlFor="color"
                    >
                      Select Font
                    </label>
                    {/* fontFamily => class define in custom.css */}
                    <FontsDropdown
                      options={GoogleFonts}
                      name="name"
                      setSelected={(id) => setFontFamily(id)}
                      selected={fontFamily}
                      scrollIntoView={false}
                      fontFamily
                      noSearch={false}
                    />
                  </div>
                </div>
                <div className="flex justify-end gap-4 mt-10">
                  {/* <button
                  onClick={() => {
                    setImgPreview("");
                    setFiles([]);
                    setSelectedColor("");
                    setFontFamily("");
                    setInvoicePanelOpen(false);
                  }}
                  className="h-11  px-7  text-[#667085] border border-[#667085] rounded-[5px]"
                >
                  Cancel
                </button> */}
                  {editInvoice?.id && editInvoice?.paymentStatus === 'paid' && (
                    <button
                      onClick={onColorFontSave}
                      disabled={fontColorLoader}
                      className="flex items-center justify-center  h-11  px-7 bg-indigo-500 text-white border border-indigo-500 rounded-[5px]"
                      type="button"
                    >
                      {fontColorLoader && (
                        <svg
                          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      )}
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={invoicePanelOpen === 'attachments' ? '' : 'hidden'}>
            <InvoiceAttachments
              handleInvoiceAttach={handleInvoiceAttach}
              files={attachFiles}
              setFiles={setAttachFiles}
              isLoading={attachLoader}
              setIsLoading={setAttachLoader}
              getInvoiceById={getInvoiceById}
              editInvoice={editInvoice}
              setReceiptModalOpen={setReceiptModalOpen}
              setActiveReceipt={setActiveReceipt}
              accessType={accessType}
              setActiveEditAttachment={setActiveEditAttachment}
              setEditAttachmentModalOpen={setEditAttachmentModalOpen}
              localReceipts={localReceipts}
              setLocalReceipts={setLocalReceipts}
              setActiveEditAttachmentIndex={setActiveEditAttachmentIndex}
              defaultAttachments={defaultAttachments}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicePanel;
