import React, { useState, useRef, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import AddressBar from 'components/AddressBar';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import TextInputDash from '../../components/TextInputDash';
import DropdownFull from '../../components/DropdownFull';

const CustomerForm = ({
  setActiveCustomer = () => {},
  activeCustomer,
  handleAddCustomer = null,
  handleUpdateCustomer,
  setFormOpen = () => {},
  setTitle = () => {},
  salesRep = [],
  modalView = false,
}) => {
  const { team } = useSelector((state) => state.Team);

  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let newValues = { ...values };
    if (newValues?.salesRepId === '') {
      delete newValues?.salesRepId;
    }
    if (activeCustomer) {
      if (!newValues?.contacts[0]?.email) {
        newValues.contacts[0].email = null;
      }
      if (activeCustomer?.contacts[0] || newValues.contacts[0]) {
        newValues = {
          ...values,
          contacts: [
            activeCustomer?.contacts[0]
              ? {
                  ...newValues.contacts[0],
                  id: activeCustomer?.contacts?.[0]?.id,
                }
              : {
                  ...newValues.contacts[0],
                },
          ],
        };
      } else {
        delete newValues.contacts;
      }
      if (newValues?.salesRepId === '') {
        delete newValues?.salesRepId;
      }
      await handleUpdateCustomer(newValues, activeCustomer?.id);
    } else {
      if (!newValues?.contacts[0]?.email) {
        delete newValues.contacts[0].email;
      }
      if (handleAddCustomer) {
        await handleAddCustomer(newValues);
      }
    }
    setIsLoading(false);
    setTitle('Customers');
    formikRef.current?.resetForm();
    setFormOpen(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        customer: activeCustomer?.customer || '',
        notes: activeCustomer?.notes || '',
        taxNumber: activeCustomer?.taxNumber || '',
        salesRepId: activeCustomer?.salesRepId || '',
        mainPhone: activeCustomer?.mainPhone || '',
        shippingAddress: {
          street: activeCustomer?.shippingAddress?.street || '',
          street2: activeCustomer?.shippingAddress?.street2 || '',
          country:
            activeCustomer?.shippingAddress?.country || team?.country || '',
          city: activeCustomer?.shippingAddress?.city || '',
          state: activeCustomer?.shippingAddress?.state || '',
          zipCode: activeCustomer?.shippingAddress?.zipCode || '',
        },
        billingAddress: {
          street: activeCustomer?.billingAddress?.street || '',
          street2: activeCustomer?.billingAddress?.street2 || '',
          country:
            activeCustomer?.billingAddress?.country || team?.country || '',
          city: activeCustomer?.billingAddress?.city || '',
          state: activeCustomer?.billingAddress?.state || '',
          zipCode: activeCustomer?.billingAddress?.zipCode || '',
        },
        contacts: [
          {
            firstName: activeCustomer?.contacts?.[0]?.firstName || '',
            lastName: activeCustomer?.contacts?.[0]?.lastName || '',
            email: activeCustomer?.contacts?.[0]?.email || '',
            phone: activeCustomer?.contacts?.[0]?.phone || '',
          },
        ],
      }}
      validationSchema={Yup.object({
        customer: Yup.string().required('Customer Name is required'),
        notes: Yup.string(),
        shippingAddress: Yup.object({
          street: Yup.string(),
          street2: Yup.string(),
          country: Yup.string(),
          city: Yup.string(),
          state: Yup.string(),
          zipCode: Yup.string(),
        }),
        billingAddress: Yup.object({
          street: Yup.string(),
          street2: Yup.string(),
          country: Yup.string(),
          city: Yup.string(),
          state: Yup.string(),
          zipCode: Yup.string(),
        }),
        contacts: Yup.array().of(
          Yup.object({
            firstName: Yup.string(),
            lastName: Yup.string(),
            email: Yup.string().email('Invalid email address'),
            phone: Yup.string(),
          }),
        ),
      })}
      validate={(values) => {
        const errors = {};
        if (
          values.contacts[0]?.phone &&
          !isValidPhoneNumber(values.contacts[0]?.phone)
        ) {
          errors.phone = 'Mobile number is not valid';
        }
        if (values.mainPhone && !isValidPhoneNumber(values.mainPhone)) {
          errors.mainPhone = 'Phone number is not valid';
        }

        return errors;
      }}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(validation) => (
        <Form>
          <div
            className={`flex flex-col items-start gap-2.5 w-[100%] ${!modalView ? 'mb-[1.875rem]' : ''} `}
          >
            <div className="flex items-start w-full sm:w-[24%]">
              <TextInputDash
                label="Customer"
                id="customer"
                name="customer"
                placeholder="Name of Business or Customer"
                value={validation.values.customer || ''}
                error={
                  validation.touched.customer && validation.errors.customer
                }
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                required
              />
            </div>
          </div>
          <div
            className={`flex flex-col items-start gap-2.5 w-[100%] ${!modalView ? 'mb-[1.875rem]' : ''} `}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 xxl:grid-cols-3 w-full gap-4">
              <div className="flex flex-col w-full">
                <TextInputDash
                  label="First Name"
                  id="firstName"
                  name="contacts.[0].firstName"
                  placeholder="Name of Primary Contact"
                  value={validation.values.contacts[0]?.firstName || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </div>
              <div className="flex flex-col w-full">
                <TextInputDash
                  label="Last Name"
                  id="lastName"
                  name="contacts.[0].lastName"
                  placeholder="Name of Primary Contact"
                  value={validation.values.contacts[0]?.lastName || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </div>
              <div className="flex flex-col w-full">
                <TextInputDash
                  label="Contact Email"
                  id="email"
                  name="contacts.[0].email"
                  placeholder="Email Address"
                  value={validation.values.contacts[0]?.email || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  error={
                    validation.touched?.contacts?.[0]?.email &&
                    validation.errors?.contacts?.[0]?.email
                  }
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  htmlFor="contacts.[0].phone"
                  className={`text-sm font-medium text-slate-600 block mb-2 ${
                    validation.errors.phone ? '!text-rose-400' : ''
                  }`}
                >
                  Mobile Number
                </label>
                <PhoneInput
                  placeholder="Enter mobile number"
                  value={validation.values.contacts[0]?.phone}
                  onChange={(value) =>
                    validation.setFieldValue('contacts[0].phone', value)
                  }
                  international
                  defaultCountry={team?.country || ''}
                  id="contacts.[0].phone"
                  name="contacts.[0].phone"
                  className={`phone_textInputDash ${
                    validation.errors.phone ? 'phone_textInputDashError' : ''
                  } `}
                />
                {validation.errors.phone && (
                  <span className=" text-xs text-rose-400">
                    {validation.errors.phone}
                  </span>
                )}
              </div>
              <div className="flex flex-col w-full">
                <label
                  htmlFor="mainPhone"
                  className={`text-sm font-medium text-slate-600 block mb-2 ${
                    validation.errors.phone ? '!text-rose-400' : ''
                  }`}
                >
                  Main Phone Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={validation.values.mainPhone}
                  onChange={(value) =>
                    validation.setFieldValue('mainPhone', value)
                  }
                  international
                  defaultCountry={team?.country || ''}
                  id="mainPhone"
                  name="mainPhone"
                  className={`phone_textInputDash ${
                    validation.errors.mainPhone
                      ? 'phone_textInputDashError'
                      : ''
                  } `}
                />
                {validation.errors.mainPhone && (
                  <span className=" text-xs text-rose-400">
                    {validation.errors.mainPhone}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start gap-2.5 w-[100%] mb-[1.875rem] ">
            <label className="text-sm font-medium text-slate-600 block">
              Billing Address
            </label>
            <AddressBar
              validation={validation}
              streetId="billingAddress.street"
              streetValue={validation.values.billingAddress.street}
              streetError={
                validation.touched?.billingAddress?.street &&
                validation.errors?.billingAddress?.street
              }
              street2Id="billingAddress.street2"
              street2Value={validation.values.billingAddress.street2}
              street2Error={
                validation.touched?.billingAddress?.street2 &&
                validation.errors?.billingAddress?.street2
              }
              cityId="billingAddress.city"
              cityValue={validation.values.billingAddress.city}
              cityError={
                validation.touched?.billingAddress?.city &&
                validation.errors?.billingAddress?.city
              }
              stateId="billingAddress.state"
              stateValue={validation.values.billingAddress.state}
              stateError={
                validation.touched?.billingAddress?.state &&
                validation.errors?.billingAddress?.state
              }
              zipCodeId="billingAddress.zipCode"
              zipCodeValue={validation.values.billingAddress.zipCode}
              zipCodeError={
                validation.touched?.billingAddress?.zipCode &&
                validation.errors?.billingAddress?.zipCode
              }
              countryId="billingAddress.country"
              countryValue={validation.values.billingAddress.country}
              countryError={
                validation.touched?.billingAddress?.country &&
                validation.errors?.billingAddress?.country
              }
            />
          </div>
          <div className="flex flex-col items-start gap-2.5 w-[100%] mb-[1.875rem] ">
            <label className="text-sm font-medium text-slate-600 block">
              Shipping Address
              <span
                className="text-indigo-500 ml-2 text-[12px] cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  validation.setFieldValue(
                    'shippingAddress.street',
                    validation.values.billingAddress.street,
                  );
                  validation.setFieldValue(
                    'shippingAddress.street2',
                    validation.values.billingAddress.street2,
                  );
                  validation.setFieldValue(
                    'shippingAddress.country',
                    validation.values.billingAddress.country,
                  );
                  validation.setFieldValue(
                    'shippingAddress.city',
                    validation.values.billingAddress.city,
                  );
                  validation.setFieldValue(
                    'shippingAddress.state',
                    validation.values.billingAddress.state,
                  );
                  validation.setFieldValue(
                    'shippingAddress.zipCode',
                    validation.values.billingAddress.zipCode,
                  );
                }}
                tabIndex="0"
                role="button"
              >
                Copy from Billing Address
              </span>
            </label>
            <AddressBar
              validation={validation}
              streetId="shippingAddress.street"
              streetValue={validation.values.shippingAddress.street}
              streetError={
                validation.touched?.shippingAddress?.street &&
                validation.errors?.shippingAddress?.street
              }
              street2Id="shippingAddress.street2"
              street2Value={validation.values.shippingAddress.street2}
              street2Error={
                validation.touched?.shippingAddress?.street2 &&
                validation.errors?.shippingAddress?.street2
              }
              cityId="shippingAddress.city"
              cityValue={validation.values.shippingAddress.city}
              cityError={
                validation.touched?.shippingAddress?.city &&
                validation.errors?.shippingAddress?.city
              }
              stateId="shippingAddress.state"
              stateValue={validation.values.shippingAddress.state}
              stateError={
                validation.touched?.shippingAddress?.state &&
                validation.errors?.shippingAddress?.state
              }
              zipCodeId="shippingAddress.zipCode"
              zipCodeValue={validation.values.shippingAddress.zipCode}
              zipCodeError={
                validation.touched?.shippingAddress?.zipCode &&
                validation.errors?.shippingAddress?.zipCode
              }
              countryId="shippingAddress.country"
              countryValue={validation.values.shippingAddress.country}
              countryError={
                validation.touched?.shippingAddress?.country &&
                validation.errors?.shippingAddress?.country
              }
            />
          </div>
          <div className="sm:flex items-start gap-4 w-[100%]">
            <div className="flex items-start w-full lg:w-1/2 xl:w-[24%]">
              <TextInputDash
                label="Tax Number"
                id="taxNumber"
                name="taxNumber"
                placeholder="Tax Number"
                value={validation.values.taxNumber || ''}
                error={
                  validation.touched.taxNumber && validation.errors.taxNumber
                }
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
            </div>
            <div className=" w-full  lg:w-1/2 xl:w-[24%] mb-5">
              <div className="mb-2 flex gap-2">
                <label className="text-sm font-medium text-slate-600 block ">
                  Assigned Sales Representative
                </label>
                {validation.values.salesRepId && (
                  <label
                    className="text-sm cursor-pointer font-medium text-indigo-500 block "
                    onClick={() => validation.setFieldValue('salesRepId', null)}
                  >
                    Clear
                  </label>
                )}
              </div>
              <DropdownFull
                options={salesRep?.map((d) => ({
                  ...d,
                  name: `${d?.firstName} ${d?.lastName}`,
                  id: d?.id,
                }))}
                selected={validation.values.salesRepId}
                setSelected={(value) => {
                  validation.setFieldValue('salesRepId', value);
                }}
                name="name"
                scrollIntoView={false}
                height="h-12 w-full"
                flyout
                placeholder="Sales Representative"
                isTableDropdown
              />
            </div>
          </div>
          <div className="flex flex-col items-start gap-2.5 w-[100%] mb-[1.875rem]">
            <label
              className="text-sm font-medium text-slate-600 block"
              htmlFor="note"
            >
              Notes
            </label>
            <textarea
              id="notes"
              name="notes"
              placeholder="Notes"
              value={validation.values.notes || ''}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              className="form-input w-full h-[4.875rem] px-4 text-[#667085] placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-[#D0D5DD] shadow-sm mb-[2px] rounded-[5px]"
            />
          </div>
          {/* Close/Add Buttons */}
          <div
            className={`w-full flex justify-end items-center gap-4 mt-[1.875rem] ${!modalView ? 'pb-20' : ''} `}
          >
            <button
              className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-slate-600 w-[6.875rem] h-11 text-slate-600 leading-6"
              onClick={(event) => {
                event.preventDefault();
                formikRef.current?.resetForm();
                setActiveCustomer(null);
                setTitle('Customers');
                setFormOpen(false);
              }}
              type="button"
            >
              {activeCustomer ? 'Cancel' : 'Close'}
            </button>
            <button
              className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6
                        disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
              type="submit"
              disabled={isLoading}
            >
              {isLoading && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              {activeCustomer ? 'Update' : 'Add'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerForm;
