import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
import { getFormattedDate } from 'utils/Utils';
import {
  getSpendingBySingleCategory,
  getIncomeBySingleCategory,
  getTransactions,
  getJournalEntries,
  getSingleMerchant,
} from '../../API/backend_helper';
import CountAI from './CountAI';
import SpendingBreakdown from './SpendingBreakdown';
import IncomeAnalysis from './IncomeAnalysis';
import VendorTrends from './VendorTrends';
import CategoryFlyout from './CategoryFlyout';
import Tooltip from '../../components/Tooltip';
import VendorFlyout from '../vendors/VendorFlyout';
import RecurringTransactions from './RecurringTransactions';
import RecurringFlyout from './RecurringFlyout';
import AppLayout from '../../components/AppLayout';

const Insights = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { localUser } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [activeTab, setActiveTab] = useState('spending');
  const [teamCurrency, setTeamCurrency] = useState(null);

  // Date Range
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRange, setSelectedRange] = useState('custom');
  const [defaultStart, setDefaultStart] = useState('');
  const [defaultEnd, setDefaultEnd] = useState('');

  // Flyout State
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [flyoutIsLoading, setFlyoutIsLoading] = useState(false);
  const [activeCategorySpendingData, setActiveCategorySpendingData] =
    useState(null);
  const [activeCategoryIncomeData, setActiveCategoryIncomeData] =
    useState(null);
  const [topSpendingCategories, setTopSpendingCategories] = useState([]);
  const [activeCategoryTransactions, setActiveCategoryTransactions] = useState(
    {},
  );

  // Vendor Flyout State
  const [vendorFlyoutOpen, setVendorFlyoutOpen] = useState(false);
  const [activeVendor, setActiveVendor] = useState(null);
  const [activeVendorId, setActiveVendorId] = useState(null);
  const [vendorFlyoutIsLoading, setVendorFlyoutIsLoading] = useState(false);
  const [activeVendorTransactions, setActiveVendorTransactions] = useState([]);
  const [activeVendorAnalytics, setActiveVendorAnalytics] = useState({});

  // Recurring Flyout State
  const [activeRecurring, setActiveRecurring] = useState([]);
  const [recurringFlyoutOpen, setRecurringFlyoutOpen] = useState(false);

  document.title = 'COUNT | Insights';

  const findTeamCurrency = () => {
    const matchingCurrency = currencies?.find?.(
      (currency) => currency?.isoCode === team?.currency,
    );
    setTeamCurrency(matchingCurrency);
  };

  const handleGetTransactions = async () => {
    try {
      const response = await getTransactions({
        limit: 2,
        vendors: activeVendorId,
      });
      setActiveVendorTransactions(response?.transactions);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleGetVendor = async () => {
    try {
      setVendorFlyoutIsLoading(true);
      const response = await getSingleMerchant(activeVendorId);
      setActiveVendor(response);
      setActiveVendorAnalytics(response?.transactionStats);
      setVendorFlyoutIsLoading(false);
    } catch (err) {
      console.log('err', err);
      setVendorFlyoutIsLoading(false);
    }
  };

  const goToTransactions = (vendorId) => {
    navigate(`/transactions?page=1&vendor=${vendorId}`);
  };

  const goToVendors = () => {
    navigate('/vendors', { state: { insights: true, vendor: activeVendor } });
  };

  const findActiveCategoryData = async (selectedCategory, start, end) => {
    const formatDates = {
      startDate: start ? getFormattedDate(start) : '',
      endDate: end ? getFormattedDate(end) : '',
    };
    if (selectedCategory) {
      const spendingData = await getSpendingBySingleCategory({
        ...formatDates,
        id: selectedCategory?.id,
      });
      setActiveCategorySpendingData(spendingData?.data);
      const incomeData = await getIncomeBySingleCategory({
        ...formatDates,
        id: selectedCategory?.id,
      });
      setActiveCategoryIncomeData(incomeData?.data);
      const expenseTransactions = await getJournalEntries({
        limit: 7,
        page: 1,
        ...formatDates,
        accountType: 'Expenses',
        accounts: selectedCategory?.id,
      });
      const incomeTransactions = await getJournalEntries({
        limit: 7,
        page: 1,
        ...formatDates,
        accountType: 'Income',
        accounts: selectedCategory?.id,
      });
      const recentTransactions = await getJournalEntries({
        limit: 7,
        page: 1,
        ...formatDates,
        accounts: selectedCategory?.id,
      });
      setActiveCategoryTransactions({
        spending: expenseTransactions?.data?.results,
        income: incomeTransactions?.data?.results,
        both: recentTransactions?.data?.results,
      });
    }
  };

  const handleFlyoutOpen = async (category, start, end) => {
    setFlyoutIsLoading(true);
    setFlyoutOpen(true);
    setActiveCategory(category);
    await findActiveCategoryData(category, start, end);
    setFlyoutIsLoading(false);
  };

  const generateMonthHeaders = (start, end) => {
    const headers = [];
    let startDate = moment(start);
    const endDate = moment(end);

    while (startDate <= endDate) {
      headers.push(startDate.format('MMM YYYY'));
      startDate = startDate.add(1, 'months');
    }

    return headers;
  };

  useEffect(() => {
    if (location?.state?.activeTab === 'income') {
      setActiveTab('income');
    } else if (location?.state?.activeTab === 'spending') {
      setActiveTab('spending');
    }
    if (location?.state?.date) {
      const dateParts = location.state.date
        .split(',')
        .map((part) => part.trim());
      let start;
      let end;

      if (dateParts.length === 2) {
        const monthName = dateParts[0];
        const year = dateParts[1];
        start = moment(`${monthName} 01, ${year}`, 'MMMM DD, YYYY')
          .startOf('month')
          .toDate();
        end = moment(`${monthName} 01, ${year}`, 'MMMM DD, YYYY')
          .endOf('month')
          .toDate();
      } else if (dateParts.length === 1) {
        const year = dateParts[0];
        start = moment(year, 'YYYY').startOf('year').toDate();
        end = moment(year, 'YYYY').endOf('year').toDate();
      }

      setStartDate(start);
      setEndDate(end);
    }
  }, [location]);

  useEffect(() => {
    setFlyoutOpen(false);
    setActiveCategory(null);
    setFlyoutIsLoading(false);
  }, [activeTab]);

  useEffect(() => {
    const today = new Date();
    const defaultStartDate = new Date(today.getFullYear(), 0, 1);
    if (!location?.state?.date) {
      setStartDate(defaultStartDate);
      setEndDate(today);
    }
    setDefaultStart(defaultStartDate);
    setDefaultEnd(today);
    setSelectedRange('24');
  }, []);

  useEffect(() => {
    if (flyoutOpen) {
      shutdown();
    } else {
      boot();
    }
  }, [flyoutOpen]);

  useEffect(() => {
    if (team && currencies) {
      findTeamCurrency();
    }
  }, [team, currencies]);

  useEffect(() => {
    if (activeVendorId) {
      handleGetTransactions();
      handleGetVendor();
    }
  }, [activeVendorId]);

  return (
    <AppLayout>
      <main className="relative grow ">
        <CategoryFlyout
          flyoutIsLoading={flyoutIsLoading}
          setFlyoutIsLoading={setFlyoutIsLoading}
          flyoutOpen={flyoutOpen}
          setFlyoutOpen={setFlyoutOpen}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          goToTransactions={null}
          startDate={startDate}
          endDate={endDate}
          type={activeTab}
          activeCategorySpendingData={activeCategorySpendingData}
          activeCategoryIncomeData={activeCategoryIncomeData}
          activeCategoryTransactions={activeCategoryTransactions}
          teamCurrency={teamCurrency}
        />
        <VendorFlyout
          flyoutOpen={vendorFlyoutOpen}
          setFlyoutOpen={setVendorFlyoutOpen}
          activeVendor={activeVendor}
          setActiveVendor={setActiveVendor}
          flyoutIsLoading={vendorFlyoutIsLoading}
          currency={teamCurrency}
          activeVendorTransactions={activeVendorTransactions}
          activeVendorAnalytics={activeVendorAnalytics}
          goToTransactions={goToTransactions}
          goToVendors={goToVendors}
          insights
        />
        <RecurringFlyout
          flyoutOpen={recurringFlyoutOpen}
          setFlyoutOpen={setRecurringFlyoutOpen}
          teamCurrency={teamCurrency}
          activeRecurring={activeRecurring}
          setActiveRecurring={setActiveRecurring}
        />
        {/* Content */}
        <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
          {/* Page header */}
          <div className="flex items-center gap-4 mb-8">
            <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
              Insights
            </h1>
            <Tooltip
              contentClassName="border-none overflow-visible text-sm text-[#667085] text-center relative left-[9rem]"
              position="bottom"
              size="lg"
              content={
                <>
                  <p>
                    These calculations are only for your default currency of{' '}
                    {teamCurrency ? teamCurrency?.isoCode : 'USD'}. Any
                    transaction in a different
                  </p>
                  <p>
                    currency is not included in these counts, sums and figures.
                    Transfers, manually
                  </p>
                  <p>
                    excluded, and pending transactions are also not included.
                  </p>
                </>
              }
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="8.60522" cy="8.99976" r="8.60522" fill="#BDBDBD" />
                <rect
                  x="7.17041"
                  y="7.08984"
                  width="2.8684"
                  height="7.64908"
                  rx="1.4342"
                  fill="white"
                />
                <rect
                  x="7.17041"
                  y="3.26172"
                  width="2.8684"
                  height="2.8684"
                  rx="1.4342"
                  fill="white"
                />
              </svg>
            </Tooltip>
          </div>
          {/* Tabs */}
          <div className="w-full h-[1.875rem] mb-10">
            <div className="flex items-center flex-wrap w-full gap-4 sm:gap-[4.375rem] gap-y-2 sm:gap-y-2 border-b border-[#D0D5DD] pb-2">
              <div
                onClick={() => setActiveTab('spending')}
                className={`text-slate-600 text-center leading-6  text-[1rem] border-b-[3px] translate-y-[10px] border-transparent  cursor-pointer pb-2
                        ${
                          activeTab === 'spending' &&
                          ' !border-indigo-500  font-medium !text-[#101828]'
                        } `}
                tabIndex="0"
                role="button"
              >
                Spending Breakdown
              </div>
              <div
                onClick={() => setActiveTab('recurring')}
                className={`text-slate-600 text-center leading-6  text-[1rem] border-b-[3px] translate-y-[10px] border-transparent  cursor-pointer pb-2
                        ${
                          activeTab === 'recurring' &&
                          ' !border-indigo-500  font-medium !text-[#101828]'
                        } `}
                tabIndex="0"
                role="button"
              >
                Recurring Transactions
              </div>
              <div
                onClick={() => setActiveTab('income')}
                className={`text-slate-600 text-center leading-6  text-[1rem] border-b-[3px] translate-y-[10px] border-transparent  cursor-pointer pb-2
                        ${
                          activeTab === 'income' &&
                          ' !border-indigo-500  font-medium !text-[#101828]'
                        } `}
                tabIndex="0"
                role="button"
              >
                Income Analysis
              </div>
              <div
                onClick={() => setActiveTab('vendor')}
                className={`text-slate-600 text-center leading-6  text-[1rem] border-b-[3px] translate-y-[10px] border-transparent  cursor-pointer pb-2
                        ${
                          activeTab === 'vendor' &&
                          ' !border-indigo-500  font-medium !text-[#101828]'
                        } `}
                tabIndex="0"
                role="button"
              >
                Vendor Trends
              </div>
              {/* <div
                onClick={() => setActiveTab('chat')}
                className={`text-slate-600 text-center leading-6  text-[1rem] border-b-[3px] translate-y-[10px] border-transparent  cursor-pointer pb-2
                        ${
                          activeTab === 'chat' &&
                          ' !border-indigo-500  font-medium !text-[#101828]'
                        } `}
                tabIndex="0"
                role="button"
              >
                Ask COUNT AI
              </div> */}
            </div>
          </div>
          {/* Components */}
          {activeTab === 'chat' && (
            <CountAI team={team} localUser={localUser} activeTab={activeTab} />
          )}
          {activeTab === 'spending' && (
            <SpendingBreakdown
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              topSpendingCategories={topSpendingCategories}
              setTopSpendingCategories={setTopSpendingCategories}
              handleFlyoutOpen={handleFlyoutOpen}
              setActiveTab={setActiveTab}
              generateMonthHeaders={generateMonthHeaders}
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              teamCurrency={teamCurrency}
              defaultEnd={defaultEnd}
              defaultStart={defaultStart}
              setVendorFlyoutOpen={setVendorFlyoutOpen}
              setActiveVendorId={setActiveVendorId}
            />
          )}
          {activeTab === 'recurring' && (
            <RecurringTransactions
              teamCurrency={teamCurrency}
              recurringFlyoutOpen={recurringFlyoutOpen}
              setRecurringFlyoutOpen={setRecurringFlyoutOpen}
              activeRecurring={activeRecurring}
              setActiveRecurring={setActiveRecurring}
            />
          )}
          {activeTab === 'income' && (
            <IncomeAnalysis
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              handleFlyoutOpen={handleFlyoutOpen}
              generateMonthHeaders={generateMonthHeaders}
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              teamCurrency={teamCurrency}
              defaultEnd={defaultEnd}
              defaultStart={defaultStart}
            />
          )}
          {activeTab === 'vendor' && (
            <VendorTrends
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              generateMonthHeaders={generateMonthHeaders}
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              teamCurrency={teamCurrency}
              defaultEnd={defaultEnd}
              defaultStart={defaultStart}
              vendorFlyoutOpen={vendorFlyoutOpen}
              setVendorFlyoutOpen={setVendorFlyoutOpen}
              activeVendor={activeVendor}
              setActiveVendorId={setActiveVendorId}
            />
          )}
        </div>
      </main>
    </AppLayout>
  );
};

export default Insights;
