import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/Svg';
import ModalBasic from '../../components/ModalBasic';
import PeopleDropdown from './PeopleDropdown';
import AccountsDropdown from './AccountsDropdown';
import {
  createSubPaymentMethod,
  fetchSubscriptions,
  getAccounts,
  getAccountsByExpenseReporter,
} from '../../API/backend_helper';
import {
  formatDateLocally,
  getCurrencySymbol,
  getFormattedDate,
  transformAccounts,
} from '../../utils/Utils';
import DatePickerDash from '../../components/DatePickerDash';
import AlertBox from '../../components/AlertBox';

const CreateExpenseReporterModal = ({
  open,
  setOpen,
  people,
  addExpenseReporter,
  activeReporter,
  setPeopleModalOpen,
  newlyAddedPeople,
  setNewlyAddedPeople,
}) => {
  const { subscription } = useSelector((state) => state.Subscription);
  const { team } = useSelector((state) => state.Team);

  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [localSelectedAccounts, setLocalSelectedAccounts] = useState([]);
  const [localPeople, setLocalPeople] = useState(null);
  const [notAssignedAccounts, setNotAssignedAccounts] = useState([]);
  const [expenseReporterAccounts, setExpenseReporterAccounts] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState(null);
  const [loadingSubsription, setLoadingSubscription] = useState(false);
  const [noClick, setNoClick] = useState(false);

  const [paymentMethodLoader, setPaymentMehtodLoader] = useState(false);

  const loadSubscriptions = async () => {
    try {
      setLoadingSubscription(true);
      const res = await fetchSubscriptions();
      setSubscriptions(res?.data?.perSeat);
      setLoadingSubscription(false);
    } catch (err) {
      setLoadingSubscription(false);
      console.log('err', err);
    }
  };

  useEffect(() => {
    loadSubscriptions();
  }, []);

  const handleSubmit = async (values, formik) => {
    setLoading(true);
    try {
      await addExpenseReporter(values.people, {
        accountIDs: values.accounts,
        expenseReporterSince: getFormattedDate(
          values?.expenseReporterSince,
          team?.defaults?.dateFormat,
        ),
      });
      setOpen(false);
      formik.resetForm();
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const onConnectPaymentMethod = async () => {
    try {
      setPaymentMehtodLoader(true);
      const redirectUrl = window?.location?.href;
      const res = await createSubPaymentMethod(redirectUrl);
      if (res?.data?.session?.url) {
        window.location = res?.data?.session?.url;
      } else {
        toast.error(
          "'Oops! Something unexpected occurred. Please contact support for assistance.",
        );
      }
      // setSubscriptions(res?.data?.perSeat);
      setPaymentMehtodLoader(false);
    } catch (err) {
      setPaymentMehtodLoader(false);
      console.log('err', err);
    }
  };

  const loadNotAssignedAccounts = async () => {
    try {
      const accountsRes = await getAccounts({ notAssignedToReporter: true });
      if (accountsRes) {
        setNotAssignedAccounts(
          transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT', 'expense'),
        );
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setAccountsLoading(false);
    }
  };

  const loadAccountsByExpenseReporter = async () => {
    try {
      const accountsRes = await getAccountsByExpenseReporter(
        activeReporter?.id,
      );
      const accounts = [
        ...accountsRes?.data?.myAccounts,
        ...accountsRes?.data?.notAssignedAccounts,
      ];
      if (accountsRes) {
        setExpenseReporterAccounts(
          transformAccounts(accounts, 'PHYSICAL_ACCOUNT', 'expense'),
        );
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setAccountsLoading(false);
    }
  };
  const options = useMemo(
    () => ({
      mode: 'single',
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  useEffect(() => {
    if (!open) {
      formikRef?.current?.resetForm();
      setLocalSelectedAccounts(false);
      setLocalPeople(null);
      setNewlyAddedPeople(null);
    }
    if (open) {
      setAccountsLoading(true);
      if (!activeReporter?.id) {
        loadNotAssignedAccounts();
      } else {
        loadAccountsByExpenseReporter();
      }
    }
  }, [open]);

  return (
    <ModalBasic
      title={`${activeReporter?.id ? 'Edit' : 'Add'} Expense Reporter`}
      smallModal
      modalOpen={open}
      setModalOpen={setOpen}
      contentId="expenseReporterModal"
      noClick={noClick}
    >
      {accountsLoading || loadingSubsription ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center min-h-[450px]">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            people: newlyAddedPeople || activeReporter?.id || null,
            accounts: activeReporter?.accounts?.map((acc) => acc?.id) || [],
            expenseReporterSince:
              activeReporter?.expenseReporterSince ||
              formatDateLocally(new Date(), team?.defaults?.dateFormat),
          }}
          validationSchema={Yup.object({
            people: Yup.string().required('Please Select People'),
            accounts: Yup.array()
              .required('Please Select Account')
              .min(1, 'Please Select Account'),
            expenseReporterSince: Yup.string().required(
              'Please Select Start Date for the Expense Reporter.',
            ),
          })}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {(validation) => (
            <Form>
              <div className="flex justify-between flex-col min-h-[450px]">
                <div className="px-5 pb-1">
                  <div className="pt-4 pb-2">
                    <label
                      className="block text-sm mb-1 text-slate-600 font-medium leading-5"
                      htmlFor="expenseReporterSince"
                    >
                      Expense Reporter Since{' '}
                      <span className="text-rose-500">*</span>
                    </label>
                    <DatePickerDash
                      onChange={(selectedDates) => {
                        validation.setFieldTouched(
                          'expenseReporterSince',
                          true,
                        );
                        validation.setFieldValue(
                          'expenseReporterSince',
                          selectedDates[0],
                        );
                      }}
                      width="w-full"
                      placeholder="Select Date"
                      value={
                        validation.values.expenseReporterSince
                          ? validation.values.expenseReporterSince
                          : null
                      }
                      options={options}
                      name="expenseReporterSince"
                      id="expenseReporterSince"
                      error={
                        validation.touched.expenseReporterSince &&
                        validation.errors.expenseReporterSince
                          ? validation.errors.expenseReporterSince
                          : ''
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onBlur={(e) => {
                        if (!e.target.classList?.value?.includes('active')) {
                          validation.setFieldTouched(
                            'expenseReporterSince',
                            true,
                          );
                          validation.setFieldValue(
                            'expenseReporterSince',
                            e.target.value,
                          );
                        }
                      }}
                    />
                  </div>
                  <label
                    className={`block text-sm mb-1 text-slate-600 font-medium leading-5 ${
                      validation.touched.people && validation.errors.people
                        ? '!text-rose-400'
                        : ''
                    }`}
                    htmlFor="people"
                  >
                    Person<span className="text-rose-500">*</span>
                  </label>
                  <PeopleDropdown
                    people={
                      activeReporter?.id ? [activeReporter] : people?.people
                    }
                    setSelectedPeople={(name) =>
                      validation.setFieldValue('people', name)
                    }
                    selectedPeople={validation.values.people}
                    isSetCategoryStyle
                    height="h-12"
                    error={
                      validation.touched.people && validation.errors.people
                    }
                    localPeople={localPeople}
                    setLocalPeople={setLocalPeople}
                    disabled={activeReporter?.id}
                    addNewOptionButton
                    handleNewOptionButton={() => setPeopleModalOpen(true)}
                  />

                  <div className="flex flex-col items-start w-full mt-4">
                    <label
                      className={`block text-sm mb-1 text-slate-600 font-medium leading-5 ${
                        validation.touched.accounts &&
                        validation.errors.accounts
                          ? '!text-rose-400'
                          : ''
                      }`}
                      htmlFor="accounts"
                    >
                      Account(s) <span className="text-rose-500">*</span>
                    </label>
                    <AccountsDropdown
                      allCategories={
                        !activeReporter?.id
                          ? notAssignedAccounts
                          : expenseReporterAccounts
                      }
                      selectedCategoryId={validation.values.accounts}
                      setSelectedCategoryId={(value) => {
                        validation.setFieldValue('accounts', value);
                      }}
                      apiFilter={validation.values.accounts}
                      type="account"
                      localSelected={localSelectedAccounts}
                      setLocalSelected={setLocalSelectedAccounts}
                      isSetCategoryStyle
                      isTableDropdown
                      bottomOfTable
                      ids={['expenseReporterModal']}
                      setNoClick={setNoClick}
                    />

                    {validation.touched.accounts &&
                    validation.errors.accounts ? (
                      <div className="text-xs mt-1 text-rose-400">
                        {validation.errors.accounts}
                      </div>
                    ) : null}
                  </div>
                </div>
                {!subscription?.default_payment_method &&
                  !team?.hasPaymentMethod && (
                    <div className="px-4">
                      <AlertBox
                        className="!items-center px-2 !gap-2"
                        type="warning"
                        heading="Connect Payment Method"
                        btnSm
                        text="Please connect a payment method to enable assigning roles to people"
                        hideActionButton={
                          subscription?.default_payment_method ||
                          team?.hasPaymentMethod
                        }
                        actionButtonText="Connect Payment Method"
                        onActionButton={onConnectPaymentMethod}
                        actionLoading={paymentMethodLoader}
                      />
                    </div>
                  )}
                {/* Modal footer */}
                <div className="flex flex-wrap justify-end space-x-2 px-5 py-4 mt-3 border-t border-[#D0D5DD]">
                  <button
                    type="button"
                    className="py-2.5 px-[1.875rem] h-11 w-[7rem] text-center flex justify-center items-center text-base leading-6 font-base rounded-[0.313rem] bg-white text-indigo-500 border border-indigo-500"
                    onClick={() => {
                      validation.resetForm();
                      setOpen(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="py-2.5 px-[1.875rem] h-11 bg-indigo-500 text-center text-white text-base leading-6 font-base rounded-[0.313rem] disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                    disabled={
                      loading ||
                      (!subscription?.default_payment_method &&
                        !team?.hasPaymentMethod)
                    }
                  >
                    {loading ? (
                      <div className="flex items-center justify-center">
                        <Loader width="w-6" height="h-6" color="#FFFFFF" />
                      </div>
                    ) : (
                      <span>{`${activeReporter?.id ? 'Update' : 'Add'} ${
                        (subscription?.default_payment_method ||
                          team?.hasPaymentMethod) &&
                        localPeople?.seatStatus !== 'confirmed' &&
                        localPeople?.seatStatus !== 'confiriming'
                          ? `& Pay ${getCurrencySymbol(subscriptions?.prices?.[0]?.currency)}${parseFloat(
                              subscriptions?.prices?.[0]?.unit_amount / 100,
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}/month`
                          : ''
                      }`}</span>
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalBasic>
  );
};

export default CreateExpenseReporterModal;
